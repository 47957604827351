import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import ConfirmModal from "../Utils/ConfirmModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddDutyPopover from "./AddDutyPopover";
import userStore from "../../Store";
import { BiEnvelope } from "react-icons/bi";
import { IoIosHelpCircle } from "react-icons/io";

const MySwal = withReactContent(Swal);

const ViewPlayerAvailibility = () => {
  const { id } = useParams();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { user } = userStore();
  const [reload, setReload] = React.useState({});
  const [confirm, setConfirm] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [message, setMessage] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const getTeamRole = (user) => {
    let names = {
      is_admin: "Admin",
      is_manager: "Manager",
      is_non_playing_member: "Non playing member",
    };
    for (let role in names) {
      if (parseInt(user[role])) {
        return names[role];
      }
      return "Player";
    }
  };

  React.useEffect(() => {
    let url = Constants.checkAvailablePlayers + id + "/" + eventId;
    (async () => {
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      //console.log(data);
      if (data.status === 1) {
        setEvents(data.response.events);
        setPlayers(data.response.available_players);
        setTeams(data.response.teams);
        setIsLoading(false);
        // console.log(data)
      }
    })();
  }, [id, eventId, reload]);
  // console.log(players)
  const handleInvitition = async (event, team, player) => {
    let url =
      Constants.resendEventInvititation +
      "?event=" +
      event +
      "&team=" +
      team +
      "&player=" +
      player;
    let response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    // console.log(data);
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Mail sent successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setMessage({ type: "danger", message: data?.message });
    }
  };
  async function handleConfirmation(player_id, status) {
    // console.log(player_id, status);
    setConfirm(false);

    let url =
      Constants.eventAvailibility +
      "?id=" +
      eventId +
      "&team=" +
      id +
      "&player=" +
      player_id +
      "&type=" +
      status;

    let response = await fetch(url);

    if (response.ok) {
      setReload({});
    }
  }
  const handleMarked = async (e, player) => {
    e.preventDefault();
    setConfirm({
      handleYes: () => handleConfirmation(player?.player_id, "yes"),
      handleMaybe: () => handleConfirmation(player?.player_id, "maybe"),
      handleNo: () => handleConfirmation(player?.player_id, "no"),
    });
    // let url = Constants.markPlayer + plrid;
    // let data = await fetch(url, {
    //     headers: {
    //         Authorization: 'Bearer '+
    //     }
    // });
    // let response = await data.response();
    // console.log(response);
  };

  const totalPlayers = players.length;
  const availablePlayers = players.filter(
    (player) => player.availability === "yes"
  ).length;
  const yetNotConfirmedPlayers = players.filter(
    (player) => player.availability === "maybe"
  ).length;
  const notAvailablePlayers = players.filter(
    (player) => player.availability === "no"
  ).length;

  const minHeightStyle = {
    minHeight: "100vh",
  };
  // console.log(players);
  // console.log(user);
  // console.log(teams);
  // console.log(events)
  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span onClick={() => navigate(-1)} className="text-black">
                  <i className="fas fa-arrow-left"></i>Event Name :{" "}
                  {events?.title}
                </span>
              </h2>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "3rem",
                height: "3rem",
                position: "fixed",
                top: "50%",
                left: "42%",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="summary-box mt-4">
              {/* <div className="summary-section">
            <div className="section-title">Total Player</div>
            <div className="section-value">{totalPlayers}</div>
          </div> */}
              <div className="summary-section">
                <div className="section-title">Availability</div>
                {/* <div className="section-value">{availablePlayers}</div> */}
              </div>
              <div className="summary-section">
                <div className="section-title">Yes : {availablePlayers}</div>
                {/* <div className="section-value">{availablePlayers}</div> */}
              </div>
              <div className="summary-section">
                <div className="section-title">No : {notAvailablePlayers}</div>
                {/* <div className="section-value">{notAvailablePlayers}</div> */}
              </div>
              <div className="summary-section">
                <div className="section-title">
                  Maybe : {yetNotConfirmedPlayers}
                </div>
                {/* <div className="section-value">{yetNotConfirmedPlayers}</div> */}
              </div>
            </div>

            <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
              {message.message ? (
                <div
                  className={`alert alert-${message?.type} alert-dismissible fade show`}
                  role="alert"
                >
                  {message?.message}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setMessage("")}
                  ></button>
                </div>
              ) : (
                <></>
              )}
              <table className="table playerlist-table text-center">
                <thead>
                  {/* <tr>
                <th width="10"></th>
                <th>Player Name</th>
             
                <th>Mark Available</th>
                <th>Available Status</th>
              </tr> */}
                </thead>
                <tbody>
                  {players?.map((player, index) => (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center ms-2">
                          {player?.first_name + " " + player?.last_name}
                        </div>
                      </td>
                      {/* <td>
                    <span className="badge bg-success">
                      {getTeamRole(player)}
                    </span>
                  </td> */}
                      <td>
                        <span
                          className={`fake-checkbox  ${
                            player?.player_id === teams.team_creator
                              ? "YES"
                              : player?.availability
                              ? player?.availability.toUpperCase()
                              : ""
                          }`}
                          onClick={(e) => {
                            if (
                              user?.id === teams?.team_creator ||
                              user?.member_email === player?.mail
                            ) {
                              handleMarked(e, player);
                            }
                          }}
                        />
                      </td>

                      <td>
                        {player?.player_id === teams?.team_creator ? (
                          "YES"
                        ) : (
                          <>
                            {player?.availability === "yes" ? (
                              <>
                                <AddDutyPopover className="badge bg-success mx-2" />
                              </>
                            ) : player?.availability === "no" ? (
                              <span className="badge bg-secondary mx-2 p-2">
                                Not Accepted
                              </span>
                            ) : player?.availability === "maybe" ? (
                              <span className="badge bg-secondary mx-2 p-2">
                               May Be
                              </span>
                            ) : (
                              <span className=" mx-2">
                                {player?.availability === "" &&
                                player?.is_admin !== "1" ? (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() =>
                                      handleInvitition(
                                        events.id,
                                        teams.id,
                                        player.player_id
                                      )
                                    }
                                  >
                                    Resend Request
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <ConfirmModal confirm={confirm} setConfirm={setConfirm} />
    </>
  );
};
export default ViewPlayerAvailibility;
