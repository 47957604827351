import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import Spinner from '../Spinner';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);


const ForgetPassword = () => {
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url = Constants.forgetPassword;
    let body = new FormData(e.target);
    let res = await fetch(url, {
      method: "POST",
      body,
    });
    let data = await res.json();
    setLoading(false);
    if (data.status !== 1) {
      e.target.reset();
      setError(data.message);
      return;
    }
    //setSuccess(data.message);
    MySwal.fire({
      icon: "success",
      title: "Password Reset Link is successfully sent your mail!",
      showConfirmButton: false,
      timer: 1500,
    });
    e.target.reset();
    setError({});

  }

  return (
    <section
      className="home-banner"
      style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
    >
      <div className="container">
        <div className="banner-content pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 mt-5 pt-4">
              <div className="logform shadow-lg">
                <h2 className="text-center fw-bold fs-3 text-uppercase mb-4">
                  Forget Password
                </h2>



                {success ? (
                  <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {success}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                ) : (
                  <></>
                )}
                <form onSubmit={handlePasswordReset}>
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email ID"
                    />
                    <span className="text-danger">{error.email}</span>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-marooon w-100 text-uppercase"
                    >
                      {loading ? <Spinner style={{ width: '20px', height: '20px', borderWidth: '2px' }} /> : 'Forget Password'}
                    </button>
                  </div>
                  <div className="mb-3">
                    <p>
                      Already have an account ?
                      <Link to="/login">
                        Login
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgetPassword;
