import React, { useMemo, useReducer, useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Constants, userToken } from "../../Constants";
import userStore from "../../Store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SocialComponent from "../SocialComponent";
import Achievement from "../Achievement";
import CreatableSelect from "react-select/creatable";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";

const MySwal = withReactContent(Swal);

const initState = 0;
const reducer = (state, action) => {
  switch (action) {
    case "increament":
      return state + 1;
    case "decreament":
      return state - 1;
    default:
      return state;
  }
};
const AccountSettings = () => {
  const [person, setPerson] = React.useState(null);
  const [games, setGames] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [aCount, dispacthACount] = useReducer(reducer, initState);
  const [sCount, dispacthSCount] = useReducer(reducer, initState);
  const [country, setCountry] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneCode, setPhoneCode] = useState("");
  const user = userStore((state) => state.user);
  const [achievements, setAchievements] = useState([]);

  const [message, setMessage] = React.useState({});
  const autoRef = React.useRef();
  const countryRef = React.useRef();
  const phoneCodeSelectRef = useRef();
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });

  useEffect(() => {
    let url = Constants.listCountry;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setCountry(data.response);
    })();
  }, [load]);

  useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        // Extract and set the country
        const country = place.address_components.find((component) =>
          component.types.includes("country")
        );

        if (country) {
          setSelectedCountry(country?.long_name);
          updatePhoneCode(country?.long_name);
          countryRef.current.value = country?.long_name;
        }
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  const updatePhoneCode = (selectedCountryName) => {
    const selectedCountryData = country?.find(
      (cnt) => cnt?.name === selectedCountryName
    );
    // console.log(`selected country ${selectedCountryData}`)

    if (selectedCountryData) {
      setPhoneCode(selectedCountryData?.phonecode);
      phoneCodeSelectRef.current.value = selectedCountryData?.phonecode;
    }
  };
  React.useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        setLatLon({ lat, lon });

        // Extract and set the country
        const country = place.address_components.find((component) =>
          component.types.includes("country")
        );

        if (country) {
          countryRef.current.value = country.long_name;
        }
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      let url = Constants.accountSettings;
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setPerson(data.response.person);
        setGames(data.response.sports);
      }
    })();
  }, [load]);

  const gameValue = useMemo(() => {
    return games?.map((item) => {
      return { label: item?.game, value: item?.id };
    });
  }, [games]);
  // console.log(games)
  const gameMapping = {
    7: "Basketball",
    8: "Soccer / Football",
    9: "Baseball",
    11: "Volleyball",
    12: "Ice Hockey",
    16: "Cricket",
    17: "Badminton",
    18: "Lacrosse",
    19: "Golf",
    20: "Tennis",
    30: "Handball",
    36: "Softball",
  };
  const sportsIds = JSON.parse(person?.type_of_sports || "[]");

  // Mapping sports IDs to game names
  const sportsNames = sportsIds?.map((sportsId) => gameMapping[sportsId]) || [];

  // console.log(sportsNames);

  const selectGames = useMemo(() => {
    let selected = JSON.parse(JSON.stringify(sportsIds));
    return gameValue?.filter((item) => selected?.includes(item?.value));
  }, [user, gameValue]);
  // console.log((JSON.stringify(sportsIds)))
  // console.log(selectGames)

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create formData from the form
    let formData = new FormData(e.target);

    // Validate the form
    if (!validateForm(formData)) {
      return;
    }

    // Continue with the form submission
    let url = Constants.updateAccountSettings;

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Member updated successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setMessage({ type: "danger", message: data.message });
    }
  };

  const validateForm = (formData) => {
    return true;
  };

  // console.log(country)

  return (
    <>
      <div className="col-lg-7" style={{ minHeight: "100vh" }}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span className="text-black"> Account Settings</span>
              </h2>
            </div>

            {message ? (
              <div
                className={`alert alert-${message.type} alert-dismissible fade show`}
                role="alert"
              >
                {message?.message}
                {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
          <div className="teamtab">
            <ul
              className="nav nav-pills mb-3 nav-justified"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-one-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-one"
                  type="button"
                  role="tab"
                  aria-controls="pills-one"
                  aria-selected="true"
                >
                  Player Info
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-two-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-two"
                  type="button"
                  role="tab"
                  aria-controls="pills-two"
                  aria-selected="false"
                >
                  Social
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-three-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-three"
                  type="button"
                  role="tab"
                  aria-controls="pills-three"
                  aria-selected="false"
                >
                  Communication
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-four-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-four"
                  type="button"
                  role="tab"
                  aria-controls="pills-four"
                  aria-selected="false"
                >
                  Privacy
                </button>
              </li>
            </ul>
          </div>
          <form
            onSubmit={handleSubmit}
            className="tab-content p-3"
            id="pills-tabContent"
          >
            <div
              className="tab-pane fade show active"
              id="pills-one"
              role="tabpanel"
              aria-labelledby="pills-one-tab"
            >
              <div>
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <label className="fw-ebold">
                      First Name:<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      defaultValue={person?.first_name}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="fw-ebold">
                      Last Name:<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      defaultValue={person?.last_name}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="fw-ebold">
                      Date of Birth:<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      format="yyyy-MM-dd"
                      name="dob"
                      defaultValue={person?.dob}
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">
                      Type of Sports:<span className="text-danger"> *</span>
                    </label>
                    {/* {sportsNames && sportsNames.length > 0 && (
                      <p>Selected Sports: {sportsNames.join(", ")}</p>
                    )} */}

                    {selectGames.length > 0 ? (
                      <Select
                        options={gameValue}
                        defaultValue={selectGames}
                        className="form-control"
                        name="type_of_sports[]"
                        isMulti={true}
                       
                      ></Select>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">Title:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      defaultValue={person?.title}
                      placeholder="Title"
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">About Me:</label>
                    <textarea
                      className="form-control"
                      name="about_me"
                      defaultValue={person?.about_me}
                    ></textarea>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">
                      Sportthon ID:<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={person?.unique_name}
                      name="unique_name"
                      readOnly
                    />
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <button type="submit" className="btn btn-marooon px-4">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-two"
              role="tabpanel"
              aria-labelledby="pills-two-tab"
            >
              <div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <label>
                      Achievement<span className="text-danger"> *</span>
                    </label>
                    <div className="d-flex">
                      <div className="flex-fill">
                        <input
                          type="text"
                          className="form-control"
                          name="achievement[0]"
                          defaultValue={(person?.achievement || "").replaceAll(
                            /["\\]/g,
                            ""
                          )}
                          placeholder="Achievement"
                        />
                      </div>
                      <div className="ms-2">
                        <button
                          type="button"
                          onClick={() => dispacthACount("increament")}
                          className="btn btn-primary border-0"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    {Array(aCount)
                      .fill(1)
                      .map((_item, index) => {
                        return (
                          <div className="d-flex my-2" key={index}>
                            <div className="flex-fill">
                              <input
                                type="text"
                                className="form-control"
                                name={`achievement[${index + 1}]`}
                                placeholder="Achievement"
                              />
                            </div>
                            <div className="ms-2">
                              <button
                                onClick={() => dispacthACount("decreament")}
                                className="btn btn-danger border-0 bg-maroon"
                              >
                                <i className="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label>
                      Social profiles<span className="text-danger"> *</span>
                    </label>
                    <div className="d-flex">
                      <div className="flex-fill">
                        <input
                          type="text"
                          className="form-control"
                          name="social[0][link]"
                          defaultValue={person?.facebook || ""}
                          placeholder="Social Profile"
                        />
                      </div>
                      <div className="ms-2">
                        <select className="form-control" name="social[0][type]">
                          <option>Facebook</option>
                          <option>Twitter</option>
                          <option>Instagram</option>
                          <option>Youtube</option>
                        </select>
                      </div>
                      <div className="ms-2">
                        <button
                          onClick={() => dispacthSCount("increament")}
                          type="button"
                          className="btn btn-primary border-0"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>

                    {Array(sCount)
                      .fill(1)
                      .map((_item, index) => {
                        return (
                          <div className="d-flex my-2" key={index}>
                            <div className="flex-fill">
                              <input
                                type="text"
                                className="form-control"
                                name={`social[${index + 1}][link]`}
                                placeholder="Social Profile"
                              />
                            </div>
                            <div className="ms-2">
                              <select
                                className="form-control"
                                name={`social[${index + 1}][type]`}
                              >
                                <option>Facebook</option>
                                <option>Twitter</option>
                                <option>Instagram</option>
                                <option>Youtube</option>
                              </select>
                            </div>
                            <div className="ms-2">
                              <button
                                type="button"
                                onClick={() => dispacthSCount("decreament")}
                                className="btn btn-danger border-0"
                              >
                                <i className="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <button type="submit" className="btn btn-marooon px-4">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-three"
              role="tabpanel"
              aria-labelledby="pills-three-tab"
            >
              <div>
                <div className="mb-3">
                  <label>
                    Account Email:<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="member_email"
                    defaultValue={person?.member_email}
                    placeholder="Account Email"
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label>
                    Address:<span className="text-danger"> *</span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className="form-control"
                      name="street_address"
                      defaultValue={person?.street_address}
                      placeholder="Address"
                      ref={autoRef}
                    />
                  </div>
                </div>
                <input
                  type="hidden"
                  value={latLon.lat}
                  name="lat"
                  onChange={(e) =>
                    setLatLon((prev) => {
                      return { ...prev, lat: e.target.value };
                    })
                  }
                />
                <input
                  type="hidden"
                  value={latLon.lon}
                  name="lon"
                  onChange={(e) =>
                    setLatLon((prev) => {
                      return { ...prev, lon: e.target.value };
                    })
                  }
                />
                <div className="mb-3">
                  <label>
                    Country:<span className="text-danger"> *</span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className="form-control"
                      name="member_country"
                      value={person?.member_country}
                      placeholder="Country"
                      ref={countryRef}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label>
                    Phone Code:<span className="text-danger"> *</span>
                  </label>
                  <div className="d-flex">
                    <div>
                      {/* <PhoneInput
                        country={"eg"}
                        enableSearch={true}
                        value={country?.id}
                        name="phone_code"
                      /> */}
                      <select
                        className="form-control"
                        name="phone_code"
                        ref={phoneCodeSelectRef}
                      >
                        <option value="000" hidden>
                          - Choose Country -
                        </option>
                        {country.map((cnt) => (
                          <option key={cnt?.id} value={cnt?.id}>
                            + {cnt?.phonecode} {cnt?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ms-2 flex-fill">
                      <input
                        type="text"
                        className="form-control"
                        name="member_phone"
                        defaultValue={person?.member_phone}
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label>Email Newsletter:</label>
                  <br />
                  <label className="mt-2">
                    <input type="checkbox" name="newsletter_flag" /> Yep, send
                    me Top Secret Updates from Sportthon
                  </label>
                </div>
                <div className="mb-3 text-center">
                  <button type="submit" className="btn btn-marooon px-4">
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-four"
              role="tabpanel"
              aria-labelledby="pills-four-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div>
                    <div className="mb-3">
                      <label>Current Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="current_password"
                        defaultValue=""
                        placeholder="Current Password"
                      />
                    </div>
                    <div className="mb-3">
                      <label>New Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="new_password"
                        defaultValue=""
                        placeholder="New Password"
                      />
                    </div>
                    <div className="mb-3">
                      <label>Confirm Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        defaultValue=""
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="mb-3 text-center">
                      <button type="submit" className="btn btn-marooon px-4">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
