import React from "react";
import { useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import userStore from "../../Store";
const MySwal = withReactContent(Swal);

const TeamSettings = () => {
  const { id } = useParams();

  const { user } = userStore();
  const [team, setTeam] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [games, setGames] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [players, setPlayers] = React.useState([]);
  const [message, setMessage] = React.useState({});

  const autoRef = React.useRef();
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchTeams = async () => {
      try {
        const url = Constants.listTeam;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        const data = await response.json();
        setTeams(data.response);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, [load]);
  // console.log(team);
  const handleDelete = async (id) => {
    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",

        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        const url = Constants.deleteTeams + id;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        const data = await response.json();

        if (response.ok && data.status === 1) {
          window.sessionStorage.setItem("type", "danger");
          window.sessionStorage.setItem("message", data.message);

          Swal.fire({
            icon: "success",
            title: "Team deleted!",
            showConfirmButton: false,
            timer: 1500,
          });

          setLoad((prevLoad) => !prevLoad);
          navigate("/dashboard");
        } else {
          throw new Error("Failed to delete team.");
        }
      }
    } catch (error) {
      console.error("Error deleting team:", error);

      window.sessionStorage.setItem("type", "danger");
      window.sessionStorage.setItem("message", "Failed to delete team.");
    }
  };

  React.useEffect(() => {
    let url = Constants.allGames;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setGames(data.response);
    })();
  }, []);

  React.useEffect(() => {
    let url = Constants.teamType;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setType(data.response);
    })();
  }, []);

  React.useEffect(() => {
    let url = Constants.editTeams + id;
    (async () => {
      let response = await fetch(url, {
        headers: {
          Authorization: "Beares " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data.response);
      setTeam(data.response);
    })();
  }, [id]);

  React.useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        setLatLon({ lat, lon });
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const payload = {};

    formData.forEach((value, key) => {
      payload[key] = value;
    });

    try {
      const response = await fetch(Constants.updateTeams + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken(),
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        Swal.fire({
          icon: "success",
          title: "Data saved successfully.",
          showConfirmButton: false,
          timer: 1500,
        });

        setMessage({ success: "Data saved successfully." });
      } else {
        setMessage({ error: "Failed to save data." });
      }
    } catch (error) {
      setMessage({ error: "An error occurred while saving data." });
    }
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span className="text-black"> Team Settings</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
          <div className="teamtab">
            <ul
              className="nav nav-pills mb-3 nav-justified"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-one-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-one"
                  type="button"
                  role="tab"
                  aria-controls="pills-one"
                  aria-selected="true"
                >
                  Team Info
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-two-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-two"
                  type="button"
                  role="tab"
                  aria-controls="pills-two"
                  aria-selected="false"
                >
                  Social
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-three-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-three"
                  type="button"
                  role="tab"
                  aria-controls="pills-three"
                  aria-selected="false"
                >
                  DeleteTeam
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-four-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-four"
                  type="button"
                  role="tab"
                  aria-controls="pills-four"
                  aria-selected="false"
                >
                  Communication
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-four-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-four"
                  type="button"
                  role="tab"
                  aria-controls="pills-four"
                  aria-selected="false"
                >
                  Privacy{" "}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-four-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-five"
                  type="button"
                  role="tab"
                  aria-controls="pills-four"
                  aria-selected="false"
                >
                  Payment{" "}
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content p-3" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-one"
              role="tabpanel"
              aria-labelledby="pills-one-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-6">
                    <label className="fw-ebold">Team Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="team_name"
                      defaultValue={team?.team_name}
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="fw-ebold">Select Sports:</label>
                    <select className="form-control" name="game_id" multiple="">
                      <option value="select" hidden>
                        --select--
                      </option>
                      {games?.map((game, index) => {
                        return (
                          <option
                            key={index}
                            value={game?.id}
                            selected={game.id === team?.game_id}
                          >
                            {game?.game}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-6">
                    <label className="fw-ebold">Team Type:</label>
                    <select
                      className="form-control"
                      name="team_type"
                      multiple=""
                    >
                      <option value="select" hidden>
                        --select--
                      </option>
                      {type.map((typ, index) => {
                        return (
                          <option
                            key={index}
                            value={typ.id}
                            selected={team?.team_type === typ?.id}
                          >
                            {typ?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="fw-ebold">Team Category:</label>
                    <select
                      className="form-control"
                      name="type_of_sports[]"
                      multiple=""
                    >
                      <option value="team_gender" hidden>
                        --select--
                      </option>
                      {["male", "female", "mixed"].map((gender, index) => {
                        return (
                          <option
                            key={index}
                            value={gender}
                            selected={team?.team_gender === gender}
                          >
                            {gender}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">Team Description:</label>
                    <textarea
                      className="form-control"
                      name="team_desc"
                      defaultValue={team?.team_desc}
                    >
                      {team?.team_desc}
                    </textarea>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="fw-ebold">Location:</label>
                    <input
                      type="text"
                      ref={autoRef}
                      className="form-control"
                      name="team_address"
                      defaultValue={team?.team_address}
                    />
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <input type="hidden" name="id" value={team?.id} />
                    <input
                      type="hidden"
                      value={latLon?.lat}
                      name="lat"
                      onChange={(e) =>
                        setLatLon((prev) => {
                          return { ...prev, lat: e.target.value };
                        })
                      }
                    />
                    <input
                      type="hidden"
                      value={latLon?.lon}
                      name="lon"
                      onChange={(e) =>
                        setLatLon((prev) => {
                          return { ...prev, lon: e.target.value };
                        })
                      }
                    />
                    <button type="submit" className="btn btn-marooon px-4">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-two"
              role="tabpanel"
              aria-labelledby="pills-two-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <label>Achievement</label>
                    <div className="d-flex">
                      <div className="flex-fill">
                        <input
                          type="text"
                          className="form-control"
                          name="achievement[]"
                        />
                      </div>
                      <div className="ms-2">
                        <button className="btn btn-primary border-0 bg-maroon">
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label>Social profiles</label>
                    <div className="d-flex">
                      <div className="flex-fill">
                        <input type="text" className="form-control" name="" />
                      </div>
                      <div className="ms-2">
                        <select className="form-control">
                          <option>Facebook</option>
                          <option>Twitter</option>
                          <option>Instagram</option>
                          <option>Youtube</option>
                        </select>
                      </div>
                      <div className="ms-2">
                        <button className="btn btn-primary border-0 bg-maroon">
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <button type="submit" className="btn btn-marooon px-4">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-three"
              role="tabpanel"
              aria-labelledby="pills-three-tab"
            >
              <div className="row">
                {user?.id === team?.team_creator ? (
                  <>
                    <div className="col-lg-12 mb-3">
                      <div className="d-flex">
                        <h5> Do you want to delete this team?</h5>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3 text-center">
                      <button
                        className="btn btn-marooon px-4"
                        onClick={() => handleDelete(id)}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-12 mb-3 text-center">
                    <h5>Only admins can delete the team.</h5>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">
                            <h4>Page under Construction</h4>
                        </div> */}
            <div
              className="tab-pane fade"
              id="pills-four"
              role="tabpanel"
              aria-labelledby="pills-four-tab"
            >
              <h4>Page under Construction</h4>
            </div>
            <div
              className="tab-pane fade"
              id="pills-four"
              role="tabpanel"
              aria-labelledby="pills-five-tab"
            >
              <h4>Page under Construction</h4>
            </div>
            <div
              className="tab-pane fade"
              id="pills-five"
              role="tabpanel"
              aria-labelledby="pills-six-tab"
            >
              <h4>Page under Construction</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamSettings;
