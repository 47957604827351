import React from "react";
import { Constants } from "../../Constants";
import { Link } from "react-router-dom";

const SubmitEventAvailibily = () => {

    const [message, setMessage] = React.useState({});
    const queryParameters = new URLSearchParams(window.location.search)


    let id = queryParameters.get("id");
    let team = queryParameters.get("team");
    let player = queryParameters.get("player");
    let type = queryParameters.get("type");

    React.useEffect(() => {
        let url = Constants.eventAvailibility + '?id=' + id + '&team=' + team + '&player=' + player + '&type=' + type;
        (async () => {
            let response = await fetch(url)
            let data = await response.json();
            if (data.status === 1) {
                setMessage({ type: 'success', message: data.message });
            } else {
                setMessage({ type: 'danger', message: data.message });
            }
        })()
    }, [id, player, team, type])

    return (
        <>

            <section
                className="home-banner"
                style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
            >
                <div className="container">
                    <div className="banner-content pt-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 mt-5 pt-4">
                                <div className="logform shadow-lg">

                                    <p>{message.message}</p>

                                    <Link to="/login">
                                        Login
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default SubmitEventAvailibily