import { Link } from "react-router-dom";
import  userStore  from "../../Store";
const DashboardHeader = ({settings}) => {
	const user = userStore((state) => state.user)
	const openNav = () => {
		document.dispatchEvent(new CustomEvent('openNav'));
	}
	return (
		<>

			<header className="header afterlog-heade">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-3 col-6">
							<div className="logo">
								<Link to="/"><img alt={settings?.small_logo} src={`../../static/logoicon.png`} /></Link>
								{/* <Link to="/"><img alt={settings?.small_logo}  src={`https://sportthon.com/get-res?url=https://api.sportthon.com/assets/images/logoicon.png`} /></Link> */}
							</div>
						</div>
						<div className="col-lg-9 col-6">
							<div className="d-flex justify-content-end align-items-center">
								<div className="toplogin ms-3">
									<Link className="text-white"><i className="fa fa-user-circle"></i> {((user?.first_name || ''))}</Link>
								</div>
								<div className="topsidemenu ms-1">
									<span onClick={openNav} className="btn"><i className="fa fa-bars" aria-hidden="true"></i></span>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	)
}
export default DashboardHeader;