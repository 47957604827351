import "./index.css";

export default function SuggestionList({ setCategory }) {
  return (
    <div className="sg-list z-3">
      <div tabIndex={-1} data-value="team" onClick={(e) => setCategory(e.target.dataset.value)}>
        <i className="fas fa-users me-2"></i> Team
      </div>
      <div tabIndex={-1} data-value="tournament" onClick={(e) => setCategory(e.target.dataset.value)}>
        <i className="fas fa-futbol me-2"></i> Tournament
      </div>
      {/* <div tabIndex={-1} data-value="event" onClick={(e) => setCategory(e.target.dataset.value)}>
        <i className="fas fa-calendar me-2"></i> Event
      </div> */}
      <div tabIndex={-1} data-value="player" onClick={(e) => setCategory(e.target.dataset.value)}>
        <i className="fas fa-user me-2"></i> Player
      </div>
    </div>
  );
}
