import LoginForm from "../components/Authentication/LoginForm";

const Login = () => {
    return(
        <>
        <LoginForm/>
        </>
    )
}

export default Login;