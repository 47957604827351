import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import userStore, { useMessageCounter } from "../../Store";

const TeamLeftBar = () => {
  const [team, setTeam] = React.useState({});
  const { id } = useParams();
  const { user } = userStore();
  const { messageCounter } = useMessageCounter();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      let url = Constants.editTeams + id;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      if (data.status === 1) {
        setTeam(data.response);
      }
      setIsLoading(false);
    })();
  }, [id]);

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `https://api.sportthon.com/api/api/update-teams/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Team Logo updated successfully!",
        }).then(() => {
          window.location.reload();
        });
      } else {
        console.error("Error updating team logo:", data.message);
      }
    } catch (error) {
      console.error("Error updating team logo:", error);
    }
  };

  return (
    <div className="col-lg-2 ps-lg-0" style={{ minHeight: "100vh" }}>
      <div className="left-sidebar bg-white shadow h-100">
        <div className="leftprofile-block">
          {isLoading ? (
            <div className="text-center">
              <div
                className="spinner-grow text-maroon spinner-grow-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : team?.team_logo ? (
            <div className="profiletoggle">
              <label htmlFor="fileInput">
                <span
                  className="position-relative"
                  role="button"
                  id="dropdownMenuLink"
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    title="Update Team Profile Image"
                    className="img-fluid border border-dark border-1"
                    src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${team?.team_logo}`}
                    alt={`${team?.team_logo}`}
                  />
                  <b
                    style={{ fontSize: "1rem", color: "#7d0000" }}
                    className="text-center mx-1"
                  >
                    {team?.team_name}
                  </b>
                </span>
              </label>
              {user?.id === team?.team_creator && (
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
              )}
            </div>
          ) : (
            <div className="profiletoggle">
              <label htmlFor="fileInput">
                <span className="position-relative">
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    className="img-fluid border border-dark border-1"
                    src={"../../static/iconc.jpg"}
                    alt={`${team?.team_logo}`}
                  />
                  <b
                    style={{ fontSize: "1rem", color: "#7d0000" }}
                    className="text-center mx-1"
                  >
                    {team?.team_name}
                  </b>
                </span>
              </label>
              {user?.id === team?.team_creator && (
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
              )}
            </div>
          )}
        </div>
        <ul className="sidemenulist">
          <li>
            <Link to={`/dashboard`}>
              <span>
                <img
                  src="/assets/frontend/images/icons/icon3.png"
                  alt="icon3.png"
                />
              </span>
              Dashboard
            </Link>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team-member/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon7.png"
                  alt="icon7.png"
                />
              </span>
              Team
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/social/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/social.png"
                  alt="social_icon"
                />
              </span>{" "}
              Social Updates
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team/schedules/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/schedule.png"
                  alt="schedule.png"
                />
              </span>{" "}
              Schedule
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team/communications/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon2.png"
                  alt="icon2.png"
                />
              </span>{" "}
              Announcement
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team/documents/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/document.png"
                  alt="document.png"
                />
              </span>{" "}
              Documents
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team/task/${team?.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/list.png"
                  alt="list.png"
                />
              </span>
              Task
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/team/chats/${team?.id}`}
            >
              <span className="position-relative">
                <img
                  src="/assets/frontend/images/icons/chat.png"
                  alt="chat.png"
                />
                {messageCounter[team.id] > 0 && (
                  <span
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      padding: '2px 6px',
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                      fontSize: '0.75rem',
                      lineHeight: '1',
                    }}
                  >
                    {messageCounter[team.id]}
                    {/* { console.log('messageCounter:', messageCounter)} */}
                  </span>
                )}
              </span>
              Chat
            </NavLink>
          </li>

          {user?.id === team?.team_creator && (
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/team/settings/${team?.id}`}
              >
                <span>
                  <img
                    src="/assets/frontend/images/icons/icon6.png"
                    alt="icon6.png"
                  />
                </span>
                Settings
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TeamLeftBar;
