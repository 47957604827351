import React from "react";
import Caraosel from "react-bootstrap/Carousel";
import { Constants } from "../Constants";
const Testimonial = () => {
  const [testimonials, setTestimonials] = React.useState([]);

  React.useEffect(() => {
    let url = Constants.homeTestimonials;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setTestimonials(data.response);
    })()
  }, [])
  return (
    <section className="testimonial-section">
      <div className="container">
        <h2 className="text-center text-white fs-3 mb-5">Testimonial</h2>

        <Caraosel
          className="text-center text-white"
          indicators={false}
        >

          {
            testimonials?.map((testimonial) => (

              <Caraosel.Item key={testimonial.id}>
                <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src={`https://sportthon.com/get-res?url=https://api.sportthon.com/asset/images/testimonial/${testimonial.image}`}
                  alt={testimonial.image}
                  style={{height : '100px', width: '100px'}}
                />
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-8">
                    <h5 className="mb-3">{testimonial.name}</h5>
                    <p className="text-light">
                      <i className="fas fa-quote-left pe-2"></i>
                      {testimonial.description}
                    
                    </p>
                  </div>
                </div>
              </Caraosel.Item>

            ))
          }

        </Caraosel>
      </div>
    </section>
  );
};
export default Testimonial;
