import Footer from "../components/Includes/Footer";
import DashboardHeader from "../components/Includes/DashboardHeader";
import Navbar from "../components/Includes/Navbar";
import LeftBar from "../components/Includes/LeftBar";
import WelcomeScreen from "../components/Includes/WelcomeScreen";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Constants, userToken } from "../Constants";
import { Condition } from "../Library";
import userStore from "../Store";
import Spinner from "../components/Spinner";
import TeamRightBar from "../components/Team/TeamRightBar";
import RightBar from "../components/Includes/RightBar";
import GlobalFooter from "../components/Includes/GlobalFooter";

const Dashboard = ({ children, leftBar, teamRightBar, noBar }) => {
  const { id } = useParams();
  const user = userStore((state) => state.user);
  const setUser = userStore((state) => state.setUser);
  const [settings, setSettings] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    let cookie = userToken();
    if (!cookie) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  React.useEffect(() => {
    let url = Constants.generalSettings;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setSettings(data.response);
      }
    })();
  }, []);

  React.useEffect(() => {
    // console.log(userToken());
    if (userToken()) {
      let url = Constants.currentUser;

      (async () => {
        let response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userToken(),
          },
        });
        let data = await response.json();
        setUser(data.response);
        // console.log(data.response);
      })();
    }
  }, [setUser]);
  if (user && Object.keys(user).length === 0) {
    return (
      <div
        style={{
          maxWidth: "100vw",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner
          style={{
            width: "60px",
            height: "60px",
            borderWidth: "2px",
            color: "#000000",
          }}
        />
      </div>
    );
  }
  return (
    <>
      <DashboardHeader settings={settings} />

      <Navbar />
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>
      <div className="bg-gray">
        {noBar ? (
          <div className="container-fluid">
            <div className="row">{children}</div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              {leftBar ?? <LeftBar />}

              {children}
              {/* <RightBar /> */}
              {teamRightBar ? <TeamRightBar id={id} /> : <RightBar />}
            </div>
          </div>
        )}
      </div>
      {Condition(user) ? <WelcomeScreen user={user} /> : <></>}

      {/* <Footer settings={settings} /> */}
      <GlobalFooter settings={settings} />
    </>
  );
};
export default Dashboard;
