import React, { useEffect, useState } from "react";
import { Constants, userToken } from "../Constants";
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import userStore from "../Store";
import Swal from "sweetalert2";
const Player = () => {
  //const slug = "about-us";
  const slug = "about-us";
  const [data, setData] = React.useState([]);
  const [following, setFollowing] = useState([]);
  const user = userStore((state) => state.user);

  const fetchFollowing = async () => {
    try {
      const response = await fetch(`${Constants.following}`, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        const validFollowers = data.payload.filter((user) => user !== null);

        setFollowing(validFollowers);
      }
    } catch (error) {
      console.error("Error fetching following:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchFollowing();
  }, []);
  const followUser = async (e, id) => {
    e.preventDefault();
    e.target.innerHTML = "Following";

    try {
      const url = Constants.followUsers + id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.status === 1) {
          setIsLoading(false);
          fetchFollowing();
          Swal.fire({
            icon: 'success',
            title: 'Followed!',
            text: 'You have successfully followed this user.',
        });
        }
      }
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const unfollowUser = async (e, id) => {
    e.preventDefault();
    e.target.innerHTML = "Unfollow";

    try {
      const url = Constants.unFollowUsers + id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.status === 1) {
          setIsLoading(false);
          fetchFollowing();
          Swal.fire({
            icon: 'success',
            title: 'Unfollowed!',
            text: 'You have successfully unfollowed this user.',
        });
        }
      }
    } catch (error) {
      console.error("Error unfollowing user:", error);
    }
  };

  const pathSegments = window.location.pathname.split("/");
  const id = pathSegments[3];
  const unique_name = pathSegments[4];

  // console.log(id, unique_name);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      let url = Constants.playerProfile + id;
      (async () => {
        try {
          let response = await fetch(url);
          let data = await response.json();
          if (data.status === 1) {
            setData(data.response);
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      })();
    }
  }, [id, slug]);
  // console.log(data);

  // Function to extract country from the street address
  const extractCountryFromAddress = (address) => {
    const addressArray = address?.split(", ");
    return addressArray[addressArray?.length - 1];
  };

  // Mapping of game IDs to game names
  const gameMapping = {
    7: "Basketball",
    8: "Soccer / Football",
    9: "Baseball",
    11: "Volleyball",
    12: "Ice Hockey",
    16: "Cricket",
    17: "Badminton",
    18: "Lacrosse",
    19: "Golf",
    20: "Tennis",
    30: "Handball",
    36: "Softball",
  };

  // Extracting sports IDs from type_of_sports
  const sportsIds = JSON.parse(data?.type_of_sports || "[]");

  // Mapping sports IDs to game names
  const sportsNames = sportsIds?.map((sportsId) => gameMapping[sportsId]) || [];

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5">
        <div className="container">
          <div className="box-profile p-3 border rounded shadow">
            <h2 className="m-0">
              <Link onClick={() => navigate(-1)} className="text-maroon ">
                <BiArrowBack />
              </Link>
            </h2>
            {isLoading ? (
              <div className="text-center" style={{ minHeight: "80vh" }}>
                <div
                  className="spinner-border text-secondary"
                  style={{
                    width: "4rem",
                    height: "4rem",
                    position: "fixed",
                    top: "50%",
                    left: "48%",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-4 h-100">
                  <div className="profileleft-panel mb-4">
                    <div className="player-boxinfo bg-maroon">
                      <div className="player-img position-relative">
                        {data?.photo ? (
                          <img
                            src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${data?.photo}`}
                            alt={data?.photo}
                          />
                        ) : (
                          <img
                            src={` /static/icond.jpg`}
                            alt="Profile Avatar"
                          />
                        )}
                      </div>
                    </div>
                    {userToken() && (
                      <>
                       {user.id === data.id ? null : (
                     
                     <div style={{ marginLeft: '155px' }} className="mt-2">
                       {following.some((user) => user.id === id) ? (
                         <button
                           onClick={(e) => unfollowUser(e, id)}
                           className="btn btn-marooon followplayer mx-2"
                         >
                           Unfollow
                         </button>
                       ) : (
                         <button
                           onClick={(e) => followUser(e, id)}
                           className="btn btn-marooon followplayer mx-2"
                         >
                           Follow
                         </button>
                       )}
                     </div>
                   )}
                      </>
                    )}
                   
                    <div className="d-flex justify-content-center followertext">
                      {/* <h4>Followers (0)</h4> */}

                     

                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        ></button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <span className="dropdown-item">Report</span>
                          </li>
                          <li>
                            <span className="dropdown-item">Message</span>
                          </li>
                          <li>
                            <span className="dropdown-item">Block</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="profilesocial pb-3">
                      <li>
                        <span>
                          <i className="fab fa-facebook-f"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fab fa-twitter"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fab fa-instagram"></i>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  <h1 className="h1 fw-bold text-maroon mb-2 mt-2">
                    {data?.first_name + " " + data?.last_name}
                  </h1>
                  <div className="d-md-flex mb-2 mt-2"></div>
                  <div className="mb-3">
                    <div className="pe-md-4">
                      <p className=" mb-1 fw-semibold mb-0">
                        <i className="fas fa-portrait text-maroon me-1"></i>
                        Sporthon ID:
                        <span className="ps-2 fw-semibold">
                          {data?.unique_name}
                        </span>
                      </p>
                    </div>
                    <div className="pe-md-4">
                      <p className=" mb-1 fw-semibold mb-0">
                        <i className="fas fa-futbol text-maroon me-1"></i>
                        Sports Interest:
                        <span className="ps-2 fw-semibold">
                          {sportsNames.length > 0
                            ? sportsNames.join(", ")
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="pe-md-4">
                      <p className=" mb-1 fw-semibold mb-0">
                        <i className="fas fa-map-marker-alt text-maroon me-1"></i>
                        Location:
                        <span className="ps-2 fw-semibold">
                          {extractCountryFromAddress(data?.street_address)}
                        </span>
                      </p>
                    </div>
                  </div>

                  <h3 className="profileheading text-uppercase">ABOUT</h3>
                  <p>{data?.about_me}</p>

                  <h3 className="profileheading text-uppercase mt-4">
                    ACHIVEMENTS
                  </h3>
                  <div className="mb-3 exprienceinfo">{data?.achievement}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default Player;
