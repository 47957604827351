import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Spinner from "../Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Documents = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [documentName, setDocumentName] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const resetForm = () => {
    setDocumentName("");
    const fileInput = document.getElementById("file-1");
    if (fileInput) {
      fileInput.value = "";
    }
    setMessage({});
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("file-1");
    if (fileInput && fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size;
      const maxSize = 2 * 1024 * 1024;

      if (fileSize > maxSize) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File size exceeds the maximum limit of 2 MB.",
        });
        return;
      }
      setSelectedFiles([]);
    }

    setLoading(true);
    let formData = new FormData(e.target);
    let url = Constants.documentUpload + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    setLoading(false);
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Document Uploaded!",
        showConfirmButton: true,
        timer: 5000,
      });
      resetForm();
      fetchDocuments();
    } else {
      setMessage({ type: "danger", message: data.message });
    }
  };

  const fetchDocuments = async () => {
    setLoading(true);
    let url = Constants.documentList + id;
    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setUploadedDocuments(data.data);
        setIsLoading(false);
        // console.log(url)
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching documents:", error);
      });
  };

  useEffect(() => {
    fetchDocuments();
    return () => {
      setLoading(false);
    };
  }, [id]);

  const handleDeleteDocument = async (documentId) => {
    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you really want to delete this document?",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        let url = Constants.deleteDocument + documentId;
        const response = await fetch(url);

        if (response.ok) {
          const updatedDocuments = uploadedDocuments.filter(
            (doc) => doc.id !== documentId
          );
          setUploadedDocuments(updatedDocuments);

          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Document deleted successfully.",
          });
          fetchDocuments();
        } else {
          throw new Error("Failed to delete document.");
        }
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete document.",
      });
    }
  };
  const myStyle = {
    width: "12rem",
  };
  const imgStyle = {
    width: "200px",
    height: "120px",
    objectFit: "cover",
  };
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = "/assets/frontend/images/icons/pdf.png";
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span onClick={() => navigate(-1)} className="text-black">
                <i className="fas fa-arrow-left"></i> Upload Documents
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        {message.message ? (
          <div
            className={`alert alert-${message.type} alert-dismissible fade show`}
            role="alert"
          >
            {message.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        ) : (
          <></>
        )}

        <div className="p-3">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="clearfix"></div>
            <p className="coverbg">Maximum File Size: 2 MB</p>
            <div className="clearfix"></div>
            <div className="form-group">
              <label className="mb-2">Document Name:</label>
              <div className="d-flex flex-fill">
                <div className="flex-fill">
                  <input
                    className="form-control rounded"
                    id="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    type="text"
                    required
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                  />
                </div>
                <div className="ps-3">
                  <input
                    type="file"
                    name="documents"
                    id="file-1"
                    className="inputfile inputfile-1 visually-hidden"
                    multiple
                    required
                    onChange={handleFileChange}
                  />

                  <label htmlFor="file-1">
                    <span className="btn btn-marooon py-2">Choose File</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-4"></div>
              <div className="m-2"></div>
              {selectedFiles.map((file, index) => (
                <p key={index}>File Name: {file.name}</p>
              ))}
            </div>

            <div className="form-group mt-3 text-center">
              <button
                type="submit"
                className="btn btn-danger my-2"
                style={{ minWidth: "120px" }}
              >
                {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      borderWidth: "2px",
                      color: "#ffffff",
                    }}
                  />
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </form>
        </div>
        <div>
          <h5 className="mb-0">
            <span className="uploaded-document">Uploaded Documents</span>
          </h5>
          {isLoading ? (
            <div className="text-center">
              <div
                className="spinner-border text-secondary m-4"
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  // position: "fixed",
                  // top: "60%",
                  // left: "44%",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {uploadedDocuments?.length === 0 ? (
                <p className="m-2">There are no documents.</p>
              ) : (
                <div className="d-flex flex-wrap mt-4">
                  {uploadedDocuments?.map((doc) => (
                    <div
                      className="card m-2"
                      key={doc?.id}
                      style={{ ...myStyle, margin: "8px" }}
                    >
                      <a
                        href={doc?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="card-img-top"
                          crossOrigin="anonymous"
                          src={doc?.url}
                          onError={handleImageError}
                          alt="Image"
                          style={imgStyle}
                        />
                      </a>
                      <div className="card-body card-items">
                        <a
                          href={doc?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="card-text">{doc?.document_name}</p>
                        </a>
                        <span
                          className="fa fa-trash-alt text-danger ms-3 cursor-pointer"
                          onClick={() => handleDeleteDocument(doc.id)}
                        ></span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Documents;
