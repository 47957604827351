import React from "react";
import { Link, useParams } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddPlayers = () => {
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState({});
  const [player, setPlayers] = React.useState([]);
  const [showGuardianFields, setShowGuardianFields] = React.useState(false);

  const { id } = useParams();

  const handleAddTeam = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let url = Constants.addTeamPlayer + id;

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    if (data.status !== 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:    data.message ,
     
      });
      return;
    }
  
    setSuccess(data.message);
    e.target.reset();
    setError({});

   
    MySwal.fire({
        icon: "success",
        title: "Player added successfully!",
        showConfirmButton: true,
        timer: 5000, 
        onAfterClose: () => {
          setSuccess(""); 
        },
      });
      
  };

  const handleUserInfo = async (email) => {
    let url = Constants.getPlayerInformation;
    let response = await fetch(url + `?email=${email}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    // console.log(data);
    if (data.status === 1) {
      console.log(data.response);
      setPlayers(data.response);
    }
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };
  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <Link to={`/team-member/${id}`} className="text-black">
                <i className="fas fa-arrow-left"></i> Add Player
              </Link>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          {success ? <span className="text-success"></span> : <></>}
          <form onSubmit={handleAddTeam} encType="multipart/form-data">
            <h4 className="h5 text-maroon">Player Information:</h4>
            <div className="row">
              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player Email Id<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={(e) => handleUserInfo(e.target.value)}
                    placeholder="Please enter the email id"
                    required
                  />
                  <span className="text-danger">{error.email}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player First Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    defaultValue={player ? player.first_name : ""}
                    placeholder="Please enter the first name"
                    required
                  />
                  <span className="text-danger">{error.first_name}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player Last Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    defaultValue={player ? player.last_name : ""}
                    placeholder="Please enter the last name"
                    required
                  />
                  <span className="text-danger">{error.last_name}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setShowGuardianFields(!showGuardianFields)}
                  >
                    {showGuardianFields
                      ? "Hide Guardian Fields"
                      : "Show Guardian Fields"}
                  </button>
                </div>
              </div>

              {showGuardianFields && (
                <div className="col-lg-12 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">Guardian Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="guardian_name"
                      placeholder="Please enter guardian name"
                    />
                  </div>
                </div>
              )}

              {showGuardianFields && (
                <div className="col-lg-12 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">Guardian Email Id</label>
                    <input
                      type="text"
                      className="form-control"
                      name="guardian_email"
                      placeholder="Please enter guardian email id"
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-12 order-lg-1">
                <p>Access :</p>
                <div className="form-group">
                  {/* <label className="col-md-3"> Access:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_admin"
                          defaultValue="1"
                        />{" "}
                       Admin
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/* <label className="col-md-3">Manager Access:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_manager"
                          defaultValue="1"
                        />{" "}
                     Manager
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/* <label className="col-md-3">Access:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_non_playing_member"
                          defaultValue="1"
                        />{" "}
                       Non-playing Member 
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn btn-marooon">
                Invite
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPlayers;
