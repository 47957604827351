

export default function ConfirmModal({ confirm, setConfirm}) {
    if(!confirm) return <></>
    return(
        <div className="modal-custom" onClick={() => setConfirm(false)}>
            <div className="modal-body text-center">
                <p className="my-3">Mark availability status</p>
                <button className="btn btn-danger mx-2" onClick={confirm?.handleNo}>No</button>
                <button className="btn btn-success mx-2" onClick={confirm?.handleYes}>Yes</button>
                <button className="btn btn-warning mx-2" onClick={confirm?.handleMaybe}>May Be</button>
            </div>
        </div>
    )
}