import { Document, Paragraph, TextRun, Packer } from "docx";
import { saveAs } from "file-saver";


export const generateDOCX = (players) => {
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Player Information",
                bold: true,
                size: 40,
              }),
            ],
          }),
       
          ...players.map((player) => (
            new Paragraph({
              children: [
                new TextRun({
                  text: `${player.first_name}`,
                  size:30,
                }),
                new TextRun({
                  text:` ${" "} ${ player.last_name} ${"-"}`,
                  size:30
                }),
                new TextRun({
                  text: "\t" + player.mail,
                  size:30
                }),
              ],
            })
          )),
        ],
      },
    ],
  });
  
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "player_info.docx");
  });
};
