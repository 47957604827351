import React from "react";
import { Constants, userToken } from "../Constants";
import Spinner from "../components/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Contact = () => {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData(e.target);
    let url = Constants.submitContact;

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    setLoading(false);
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Enquiry Posted Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setMessage({ type: "danger", message: data.message });
    }
    e.target.reset();
    // console.log(data)
  };
  const minHeightStyle = {
    minHeight: "70vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5" style={minHeightStyle}>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
                }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container">
            <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
              Contact Us
            </h2>

            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="card shadow">
                  <div className="card-body">
                    {message?.message ? (
                      <div
                        className={`alert alert-${message?.type} alert-dismissible fade show`}
                        role="alert"
                      >
                        {message?.message}
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label>
                            Email ID <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label>
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="phone"
                            required
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label>
                            Country <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            required
                          />
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label>Business Reason </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            name="business_reason"
                          ></textarea>
                        </div>
                        <div className="col-lg-12  text-center">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ pointerEvents: "auto" }}
                          >
                            {loading ? (
                              <Spinner
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderWidth: "2px",
                                  color: "#ffffff",
                                }}
                              />
                            ) : (
                              "Send"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default Contact;
