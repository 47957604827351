import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import userStore from "../../Store";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const TeamSchedules = () => {
  const { id } = useParams();
  const { user } = userStore();
  const [load, setLoad] = React.useState({});
  const [team, setTeam] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let url = Constants.teamEvents + id;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      if (data.status === 1) {
        setEvents(data.response.events);
        setTeam(data.response.team);
        setLoad({});
        setIsLoading(false);
      }
    })();
  }, [id]);
  // console.log(team)
  React.useEffect(() => {
    let url = Constants.listTeamPlayer + id;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data.response);
      if (data.status === 1) {
        setPlayers(data.response.players);
      }
    })();
    // console.log(players);
  }, [id]);

  const handleNewEvents = async () => {
    let url = Constants.checkTeamPlayerExists + id;
    let response = await fetch(url);
    let data = await response.json();
    // console.log(data);
    if (data.status === 1) {
      navigate(`/team/add-events/${id}`);
    } else {
      setError(data.message);
      setLoad({});
    }
  };

  const handleDelete = async (e, eventId) => {
    e.preventDefault();

    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you really want to delete this event?",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        let url = Constants.deleteEvent;
        const formData = new FormData();
        formData.append("teamId", id);
        formData.append("eventId", eventId);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
          body: formData,
        });

        let data = await response.json();

        if (data.status === 1) {
          MySwal.fire({
            icon: "success",
            title: "Event deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoad({});
        }
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };
  // console.log(events);
  const minHeightStyle = {
    minHeight: "100vh",
  };
  const formatEventDateTime = (eventDate, eventTime) => {
    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/New_York",
    };
    const formattedDate = new Date(eventDate).toDateString(); // Convert date to string format
    const formattedTime = new Date(
      `${eventDate}T${eventTime}`
    ).toLocaleTimeString("en-US", options);
    return { formattedDate, formattedTime };
  };
  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              {/* <h2 className="mb-0"><span onClick={() => navigate(-1)} className="text-black"><i className="fas fa-arrow-left"></i>{`${team.team_name}- Events`}</span></h2> */}
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="calender-btn"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i className="fas fa-list"></i> List
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="calender-btn active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="fas fa-calendar-alt"></i> Schedule
                  </button>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center">
              {user.id === team?.team_creator ||
              players.some((player) => player?.is_manager === "1") ? (
                <div>
                  <span
                    onClick={handleNewEvents}
                    className="btn btn-marooon  mb-3"
                  >
                    New Events<i className="fas fa-plus ms-2"></i>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {error ? (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Hello User!</strong> {error}.
            <button
              type="button"
              className="btn-close"
              onClick={() => setError("")}
            ></button>
          </div>
        ) : (
          <></>
        )}
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            {isLoading ? (
              <div className="text-center">
                <div
                  className="spinner-border text-secondary"
                  style={{
                    width: "3.5rem",
                    height: "3.5rem",
                    position: "fixed",
                    top: "50%",
                    left: "42%",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events.map((event, index) => {
                  return {
                    title: event.title,
                    date: event.event_date,
                    color: "green",
                    id: event.id,
                  };
                })}
                eventClick={(info) => {
                  navigate(`/team/player-availability/${id}/${info?.event.id}`);
                }}
              />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="card rounded shadow-sm teamcard h-100">
              <div className="row m-3">
                {events.map((ent, index) => (
                  <div className="col-lg-4 col-md-6 mb-3" key={ent.id}>
                    <div className="card rounded shadow-sm teamcard h-100 d-flex flex-column">
                      <div className="card-body border-top flex-grow-1">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h4 className="tname fs-6 mb-0">
                            <Link
                              to={`/team/player-availability/${id}/${ent.id}`}
                              className="text-maroon"
                            >
                              {ent.title}
                            </Link>
                          </h4>
                          <div className="ticon">
                            <img
                              src="/assets/frontend/images/icons/icon-play.png"
                              alt="icon-play.png"
                            />
                          </div>
                        </div>
                        <span>
                          Date:{" "}
                          {new Date(ent.event_date).toLocaleDateString(
                            navigator.language,
                            {
                              timeZone:
                                Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone,
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </span>
                        {/* <span>
                          Date:{" "}
                          {new Date(ent.event_date).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: "America/New_York",
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </span> */}
                        <br />
                        <span>
                          Time:{" "}
                          {new Date(
                            `${ent.event_date}T${ent.event_time}`
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>
                        <br />
                        <span>Location: {ent.location}</span>
                        <br />
                      </div>

                      <div className="mt-auto p-2 edit-section">
                        <div className="row">
                          <div className="col-6">
                            <Link
                              to={`/team/schedules/edit/${id}/${ent.id}`}
                              className="btn btn-primary btn-sm"
                            >
                              Edit
                            </Link>
                          </div>
                          <div className="col-6 text-end">
                            <button
                              onClick={(e) => handleDelete(e, ent.id)}
                              className="btn btn-danger btn-sm"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TeamSchedules;
