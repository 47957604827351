import React from 'react';
import Header from '../components/Includes/Header';
import MobileSearch from '../components/MobileSearch';
import Footer from '../components/Includes/Footer';
import Navbar from '../components/Includes/Navbar';
import { Constants } from '../Constants';
import GlobalFooter from '../components/Includes/GlobalFooter';
const Template = ({children}) => {

    const [settings, setSettings] = React.useState([]);

	React.useEffect(() => {
		let url = Constants.generalSettings;
		(async () => {
			let response = await fetch(url);
			let data = await response.json();
			if (data.status === 1) {
				setSettings(data.response);
			}
		})();
	}, [])

    return (
        <>
            <Header settings={settings}/>
            <MobileSearch />
            <Navbar />
            {children}
            <Footer settings={settings}/>
            <GlobalFooter settings={settings}/>
        </>
    )
}

export default Template;