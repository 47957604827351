import { create } from "zustand";
import { persist } from "zustand/middleware";
import { userToken ,Constants} from "./Constants"; // Ensure this path is correct

// User store
const userStore = create(
  persist(
    (set) => ({
      user: null,
      setUser: (data) => set(() => ({ user: data })),
      unsetUser: (data) => set(() => ({ user: data })),
    }),
    {
      name: "user-storage",
    }
  )
);

export default userStore;

export const useReload = create((set) => ({
  isReload: false,
  setReload: (data) => set({ isReload: data }),
}));

// Message counter store
export const useMessageCounter = create(
  persist(
    (set, get) => ({
      messageCounter: {},
      setMessageCounter: (data) => set({ messageCounter: data }),
      fetchUnreadMessages: async () => {
        try {
          const url = Constants.temChatCount;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + userToken(),
            },
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (data.status === "1") {
            const messageCounts = data.teamList.reduce((acc, team) => {
              acc[team.team_id] = team.unread_msg;
              return acc;
            }, {});
            set({ messageCounter: messageCounts });
          } else {
            throw new Error(`API error! status: ${data.status}`);
          }
        } catch (error) {
          console.error("Error fetching unread messages:", error.message);
        }
      },
    }),
    {
      name: "message-counter-storage",
    }
  )
);

// Initialize interval for fetching unread messages
const initializeMessageCounterInterval = () => {
  const { fetchUnreadMessages } = useMessageCounter.getState();
  fetchUnreadMessages();
  setInterval(fetchUnreadMessages, 5000); // Refetch every 5 seconds
};

initializeMessageCounterInterval();
