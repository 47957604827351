import { NavLink } from "react-router-dom";
import userStore from "../../Store";
import Swal from "sweetalert2";
import { Constants, userToken } from "../../Constants";
import { useState } from "react";

const LeftBar = () => {
  const user = userStore((state) => state.user);

  const [newPhoto, setNewPhoto] = useState(null);

  const handlePhotoChange = async (event) => {
    const formData = new FormData();
    formData.append("profile_image", event.target.files[0]);

    try {
      const response = await fetch(Constants.profileImage, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken()}`,
        },
        body: formData,
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Profile image updated successfully!",
        }).then(() => {
          window.location.reload();
        });
      } else {
        console.error("Failed to update photo");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to update profile image!",
        });
      }
    } catch (error) {
      console.error("Error updating photo:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error updating profile image!",
      });
    }
  };

  return (
    <div className="col-lg-2 ps-lg-0">
      <div className="left-sidebar bg-white shadow h-100">
        <div className="leftprofile-block">
          {Object.keys(user).length ? (
            <div className="profiletoggle">
              <label htmlFor="fileInput">
                <span
                  className="position-relative"
                  role="button"
                  id="dropdownMenuLink"
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    title="Update Profile Image"
                    src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${user?.photo}`}
                    alt={`${user?.photo}`}
                  />
                  <b
                    style={{ fontSize: "1rem", color: "#7d0000" }}
                    className="mx-1"
                  >
                    {user?.first_name || (
                      <span className="skeleton__name"></span>
                    )}
                  </b>
                </span>
              </label>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
              />
            </div>
          ) : (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
        <ul className="sidemenulist">
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/dashboard"
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon7.png"
                  alt="icon7.png"
                />
              </span>
              My Teams
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/moments/${user.id}`}
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/moments.png"
                  alt="icon3.png"
                />
              </span>{" "}
              Moments
            </NavLink>
          </li>
          {/* <li><NavLink className={({ isActive }) => isActive ? "active" : ""} to={`/myNetwork`}><span><img src="/assets/frontend/images/icons/network.png" alt="icon3.png"/></span> My Network</NavLink></li> */}
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/blog"
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon2.png"
                  alt="icon2.png"
                />
              </span>
              Whats New?
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/tournaments"
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/tournament.png"
                  alt="tournament.png"
                />
              </span>{" "}
              Tournaments
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/invite"
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon4.png"
                  alt="icon4.png"
                />
              </span>
              Invite Friends
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/account-settings"
            >
              <span>
                <img
                  src="/assets/frontend/images/icons/icon6.png"
                  alt="icon6.png"
                />
              </span>{" "}
              Account Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LeftBar;
