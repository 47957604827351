import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const EditPlayers = () => {
  const navigate = useNavigate()
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState({});
  const [player, setPlayer] = React.useState([]);
  const [team, setTeam] = React.useState([]);
  
  const [showGuardianFields, setShowGuardianFields] = React.useState(false);

  const { id, playerid, teamId } = useParams();
  useEffect(() => {
    const fetchPlayerInfo = async () => {
      try {
        const url = Constants.listTeamPlayer + id;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        const data = await response.json();
        if (data.status === 1 && data.response.players.length > 0) {
          setTeam(data.response.team);
          const currentPlayer = data.response.players.find((item) => item.id === playerid);
          setPlayer(currentPlayer);

          // Set initial values for checkboxes
          setShowGuardianFields(currentPlayer.guardian_name !== null);
          document.getElementsByName("is_admin")[0].checked = currentPlayer.is_admin === "1";
          document.getElementsByName("is_manager")[0].checked = currentPlayer.is_manager === "1";
          document.getElementsByName("is_non_playing_member")[0].checked = currentPlayer.is_non_playing_member === "1";
        }
      } catch (error) {
        console.error("Error fetching player information:", error);
      }
    };

    fetchPlayerInfo();
  }, [id, playerid]);

// console.log(team)
  const handleEditPlayer = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    let url = Constants.updateTeamPlayer + team.id + "/" +  playerid;

    try {
      const response = await fetch(url, {
        method:"POST",
        
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      const data = await response.json();
      if (data.status !== 1) {
        setError(data.message);
        return;
      }

      setSuccess(data.message);
      setError({});

      MySwal.fire({
        icon: "success",
        title: "Player updated successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/team-member/${team.id}`)
    } catch (error) {
      console.error("Error updating player:", error);
    }
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <Link to={`/team-member/${id}`} className="text-black">
                <i className="fas fa-arrow-left"></i> Edit Player
              </Link>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          {success ? <span className="text-success"></span> : <></>}
          <form onSubmit={handleEditPlayer} encType="multipart/form-data">
            <h4 className="h5 text-maroon">Player Information:</h4>
            <div className="row">
              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player Email Id<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    defaultValue={player.mail || ""}
                    placeholder="Please enter the email id"
                    required
                  />
                  <span className="text-danger">{error.email}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player First Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    defaultValue={player.first_name || ""}
                    placeholder="Please enter the first name"
                    required
                  />
                  <span className="text-danger">{error.first_name}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Player Last Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    defaultValue={player.last_name || ""}
                    placeholder="Please enter the last name"
                    required
                  />
                  <span className="text-danger">{error.last_name}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setShowGuardianFields(!showGuardianFields)}
                  >
                    {showGuardianFields
                      ? "Hide Guardian Fields"
                      : "Show Guardian Fields"}
                  </button>
                </div>
              </div>

              {showGuardianFields && (
                <div className="col-lg-12 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">Guardian Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="guardian_name"
                      defaultValue={player.guardian_name|| ""}
                      placeholder="Please enter guardian name"
                    />
                  </div>
                </div>
              )}

              {showGuardianFields && (
                <div className="col-lg-12 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">Guardian Email Id</label>
                    <input
                      type="text"
                      className="form-control"
                      name="guardian_email"
                      defaultValue={player.guardian_email|| ""}
                      placeholder="Please enter guardian email id"
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-12 order-lg-1">
                <p>Access :</p>
                <div className="form-group">
                  {/* <label className="col-md-3">Admin Access:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_admin"
                          defaultChecked={player.is_admin === "1" }
                        />{" "}
                        Admin
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/* <label className="col-md-3">Manager Access:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_manager"
                          defaultChecked={player.is_manager === "1"}
                        />{" "}
                         Manager
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/* <label className="col-md-3">Non Playing Member:</label> */}
                  <div className="col-md-8">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="is_non_playing_member"
                          defaultChecked={player.is_non_playing_member === "1"}
                        />{" "}
                      Non-playing Member 
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn btn-marooon">
                Edit Inviation
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditPlayers;
