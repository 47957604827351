import React from "react";

import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
const NoPlayer = () => {
  const navigate = useNavigate();
  const minHeightStyle = {
    minHeight: "70vh",
  };
  const minHeightStylee = {
    minHeight: "50vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-2" style={minHeightStyle}>
        <div className="container">
          <div className="box-profile p-2 border rounded shadow">
            <h2 className="m-0">
              <Link onClick={() => navigate(-1)} className="text-maroon ">
                <BiArrowBack />
              </Link>
            </h2>

            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70vh",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h3>Player has not registered yet. Invite him/her to create an account.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default NoPlayer;
