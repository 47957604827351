import React from "react";
import { Link } from "react-router-dom";
import { Constants, set_cookie } from "../../Constants";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import SocialLogin from "../SocialLogin/SocialLogin";
import userStore from "../../Store";

const REDIRECT_URI =
  "https://plenty-planets-beam-42-118-51-2.loca.lt/account/login";

const LoginForm = () => {
  const navigation = useNavigate();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const setUser = userStore((state) => state.setUser);

  React.useEffect(() => {
    const { cookie } = document;
    if (cookie.includes("_token=")) {
      navigation("/dashboard");
    }
  }, [navigation]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url = Constants.login;
    // console.log(url);
    let body = new FormData(e.target);
    // console.log(body);
    let res = await fetch(url, {
      method: "POST",
      body,
    });
    let data = await res.json();
    setLoading(false);
    if (data.status !== 1) {
      setError(data.message);
      return;
    }
    // localStorage.setItem('_token', JSON.stringify(data._token));
    setUser(data.user);
    set_cookie("_token", data._token);
    e.target.reset();
    setError("");
    // console.log(data);
    navigation("/dashboard");
  };

  return (
    <section
      className="home-banner"
      style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
    >
      <div className="container">
        <div className="banner-content pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 mt-5 pt-4">
              <div className="logform shadow-lg">
                <h2 className="text-center fw-bold fs-3 text-uppercase mb-4">
                  Login
                </h2>

                {error ? (
                  <h5 className="text-danger text-center" role="alert">
                    {error}
                  </h5>
                ) : (
                  <></>
                )}
                <form onSubmit={handleLogin}>
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email ID"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-marooon w-100 text-uppercase"
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            borderWidth: "2px",
                            color: "#ffffff",
                          }}
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                  <div className="mb-3">
                    <p>
                      <Link to="/forget-password">Forgot Password?</Link>
                    </p>
                    <p>
                      Create an new account? <Link to="/register">Sign Up</Link>
                    </p>
                  </div>
                  <div className="ordivider">
                    <span>or</span>
                  </div>

                  <SocialLogin />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LoginForm;
