import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Constants } from "../../Constants";
import Spinner from "../Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ResetPassword = () => {
  const navigate = useNavigate()
  const [message, setMessage] = React.useState({});
  const [error, setError] = React.useState({});
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);

  // const handleResetPassword = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   let url = Constants.resetPassword;
  //   let token = searchParams.get('token');
  //   let body = new FormData(e.target);
  //   let res = await fetch(url + '?token=' + token, {
  //     method: "POST",
  //     body,
  //   });
  //   let data = await res.json();
  //   // console.log(data);
  //   setLoading(false);
  //   if (data.status !== 1) {
  //     e.target.reset();
  //     setError(data.message);
  //     return;
  //   }
  //   setSuccess(data.message);
  //   e.target.reset();
  //   setError({});

  // }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    let url = Constants.resetPassword;
    let token = searchParams.get("token");
    let fromData = new FormData(e.target);
    let response = await fetch(url + "?token=" + token, {
      method: "POST",
      body: fromData,
    });
    let data = await response.json();
    console.log(data);
    setLoading(false);
    if (data.status !== 1) {
      setError(data.errors);
      setMessage({ type: "danger", message: data.message });
      e.target.reset();
      return;
    } else {
      MySwal.fire({
        icon: "success",
        title: "Password Updated!",
        showConfirmButton: false,
        timer: 1500,
      });
      setError(data.errors);
      e.target.reset();
      //change
      navigate('/login')
    }
  };

  return (
    <section
      className="home-banner"
      style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
    >
      <div className="container">
        <div className="banner-content pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 mt-5 pt-4">
              <div className="logform shadow-lg">
                <h2 className="text-center fw-bold fs-3 text-uppercase mb-4">
                  Reset Password
                </h2>

                {message ? (
                  <div
                    className={`alert alert-${message?.type} alert-dismissible fade show`}
                    role="alert"
                  >
                    {message?.message}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                ) : (
                  <></>
                )}
                <form onSubmit={handleResetPassword}>
                  <div className="form-group mb-3">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                    />
                    <span className="text-danger">{error.password}</span>
                  </div>

                  <div className="form-group mb-3">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                    <span className="text-danger">
                      {error.confirm_password}
                    </span>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-marooon w-100 text-uppercase"
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            borderWidth: "2px",
                            color: "#ffffff",
                          }}
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ResetPassword;
