import { Link } from "react-router-dom";
const Footer = ({ settings }) => {
  return (
    <footer>
      <div className="footertop">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="footerlogo mb-3">
                <Link to="/">
                  <img
                    alt={settings?.logo}
                    src={`https://sportthon.com/get-res?url=https://api.sportthon.com/assets/images/${settings?.logo}`}
                  />
                </Link>
              </div>
              <p>
                <i className="fas fa-map-marker-alt"></i> {settings?.address}
              </p>
              <p>
                <i className="far fa-envelope"></i> {settings?.email}
              </p>
              <ul className="footersocial mt-4">
                <li>
                  <a href={settings?.facebook} rel="noreferrer" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href={settings?.twitter} rel="noreferrer" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={settings?.gplus} rel="noreferrer" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                {/* <li><a href={settings?.gplus} rel="noreferrer" target="_blank"><i className="fab fa-angellist"></i></a></li> */}
                <li>
                  <a href={settings?.linkdein} rel="noreferrer" target="_blank">
                    <i className="fab fa-linkedin"> </i>
                  </a>{" "}
                </li>
                <li>
                  <a href={settings?.youtube} rel="noreferrer" target="_blank">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 mb-3">
              <h3 className="text-white fs-5 mb-3">Company</h3>
              <ul className="footernav">
                <li>
                  <Link to="/about" style={{ display: 'inline-block' }}> About Us</Link>
                </li>

                <li>
                  <Link to="/company-news" style={{ display: 'inline-block' }}> Company News</Link>
                </li>
                {/* <li><Link to="/feature"> Product Features</Link></li> */}
                <li>
                  <Link to="/featuresNew" style={{ display: 'inline-block' }}> Product Features</Link>
                </li>
                <li>
                  <Link to="/partners" style={{ display: 'inline-block' }}> Partners</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 mb-3">
              <h3 className="text-white fs-5 mb-3">Support</h3>
              <ul className="footernav">
                <li>
                  <Link to="/help" style={{ display: 'inline-block' }}> Help Center</Link>
                </li>
                <li>
                  <Link to="/faq" style={{ display: 'inline-block' }}> FAQ</Link>
                </li>
                <li>
                  <Link to="/contact" style={{ display: 'inline-block' }}> Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 mb-3">
              <h3 className="text-white fs-5 mb-3">MORE</h3>
              <ul className="footernav">
                <li>
                  <Link to="/agreement" style={{ display: 'inline-block' }}>User Agreement</Link>
                </li>
                {/* <li><Link to="/sports-photography"> Sports Photography</Link></li> */}
                <li>
                  <Link to="/privacy" style={{ display: 'inline-block' }}> Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/responsible-disclosure-policy" style={{ display: 'inline-block' }}>
                    {" "}
                    Responsible Discloser Policy
                  </Link>
                </li>
                {/* <li>
                  <Link to="/sports-science"> Sports Science</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="footer-bottom">
				<div className="container">
					{settings?.reserved_text}
				</div>
			</div> */}
    </footer>
  );
};
export default Footer;
