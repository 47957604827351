import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Constants } from "../Constants";


const EmailVerify = () => {
  const [data, setData] = useState('');
  const [searchParams] = useSearchParams();
  // console.log(searchParams.get('token'));

  React.useEffect(() => {
    let token = searchParams.get('token');
    let url = Constants.verifyUser;
    (async () => {
      let response = await fetch(url + '?token=' + token);
      let data = await response.json();
      setData(data.message);
    })()
  }, [searchParams]);

  return (
    <section
      className="home-banner"
      style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
    >
      <div className="container">
        <div className="banner-content pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 mt-5 pt-4">
              <div className="logform shadow-lg">
                <h2>{data}</h2>
                <h2><Link to="/login">Click Here</Link> To Go to the Login Page</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EmailVerify;
