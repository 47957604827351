import { Link } from "react-router-dom";
const GlobalFooter = ({settings}) => {

	return (
		<footer>
			
			<div className="footer-bottom">
				<div className="container">
					{settings?.reserved_text}
				</div>
			</div>
		</footer>
	)
}
export default GlobalFooter;