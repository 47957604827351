import { useEffect, useState } from "react";
import { userToken } from "../../Constants";

import { Link, useNavigate } from "react-router-dom";
import { removeToken } from "../../Constants";
import userStore  from "../../Store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Navbar = () => {
  const [width, setWidth] = useState("0px");
  const navigation = useNavigate();
  const setUser = userStore((state) => state.setUser);
  const closeNav = () => {
    setWidth("0px");
  };
  useEffect(() => {
    document.addEventListener("openNav", () => {
      setWidth("250px");
    });
  }, []);
  const handleLogout = (e) => {
    e.preventDefault();

    MySwal.fire({
      title: "Are you sure?",
      text: "You will be logged out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "You have been logged out.",
          icon: "success",
          timer: 1500,
        });
        setUser({});
        removeToken("_token");
        navigation("/login");
      }
    });
  };

  return (
    <div id="mySidenav" className="sidenav cursor-pointer" style={{ width }}>
      <span className="closebtn" onClick={closeNav}>
        &times; 
      </span>
      <div className="sidemenu">
        

        <Link to="/myNetwork">My Networks</Link>
        <Link to={`/team/upcomingEvents`}>Upcoming Events</Link>
        {userToken() ? (
          <span onClick={handleLogout} className="colorspan">
            <i  className="fas fa-sign-out-alt">&nbsp;</i>Logout
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default Navbar;
