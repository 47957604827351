import React from "react";
import { Constants } from "../Constants";
import { useNavigate } from "react-router-dom";
import NoImg from "../../src/static/noimage.png";
import GlobalSidebar from "../components/GlobalSidebar";
import GlobalRightBar from "../components/GlobalRightBar";
import SuggestionList from "../components/Suggestions";
import { Link } from "react-router-dom";

const Search = () => {
  const [search, setSearch] = React.useState([]);
  const [searchParamsData, setSearchParamsData] = React.useState({});
  const [loading, setLoading] = React.useState({});
  const autoRef = React.useRef();
  const [category, setCategory] = React.useState("");
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [distance, setDistance] = React.useState(null);

  const formRef = React.useRef();
  const navigate = useNavigate();
  const { href } = window.location;
  const searchParams = React.useMemo(() => {
    let searchStr = window.location.search;

    let searchObj = new URLSearchParams(searchStr);
    let search = {};
    for (let value of searchObj) {
      search[value[0]] = parseFloat(value[1]) ? parseFloat(value[1]) : value[1];
    }
    setLatLon({ lat: search.lat, lon: search.lon });
    setSearchParamsData(search);
    return search;
  }, [href]);

  React.useEffect(() => {
    // console.log(searchParams);
    handleSearch();
  }, [loading]);

  React.useEffect(() => {
    if (window.google?.maps?.places?.Autocomplete) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        autoRef.current
      );
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();

          setLatLon({ lat, lon });
        }
      );
    }
  }, [window.google?.maps?.places?.Autocomplete]);

  const handleSearch = async () => {
    let url = Constants.globalSearch;
    let requestBody = { ...searchParams, distance };
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    // console.log(data);
    if (data.status === 1) {
      setSearch(data.response);
      setIsLoading(false);
    }
  };
  // console.log("search", search)
  React.useEffect(() => {
    if (category !== "") {
      formRef.current.submit();
    }
  }, [category]);

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-2">
        <div className="bg-gray">
          <div className="container-fluid">
            <div className="row">
              <GlobalSidebar />

              <div className="col-lg-7">
                <div className="middle-top-head my-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="tophead-title">
                      <h6 className="mb-0 fs-13">
                        <span className="text-black">
                          Searching For: {search?.search_text}
                          {search.location_text
                            ? `, ${search.location_text}`
                            : ""}
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="me-4">
                        <span className="color-black fs-13"></span>
                      </div>
                    </div>
                    <form action="/search" method="GET" ref={formRef}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="typetextpnl position-relative">
                          <input
                            type="text"
                            name="keyword"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Find Players, Teams, Tournaments, Leagues, Groups, Companies.."
                            required
                            minLength={3}
                            defaultValue={searchParamsData.keyword}
                          />
                          <SuggestionList setCategory={setCategory} />
                          <input
                            type="hidden"
                            name="category"
                            value={category}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="typelocation position-relative">
                          <input
                            type="text"
                            name="location"
                            className="form-control"
                            placeholder="Location.."
                            ref={autoRef}
                            defaultValue={searchParamsData.location}
                          />
                          <span
                            onClick={() => {
                              autoRef.current.value = "";
                              setLatLon({ lat: "", lon: "" });
                            }}
                            className="removelocation"
                          >
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="typeicon">
                            <i className="fas fa-map-marker-alt"></i>
                          </span>
                        </div>
                        <div className="bannersrchbtn">
                          <input
                            type="hidden"
                            value={latLon.lat}
                            name="lat"
                            onChange={(e) =>
                              setLatLon((prev) => {
                                return { ...prev, lat: e.target.value };
                              })
                            }
                          />
                          <input
                            type="hidden"
                            value={latLon.lon}
                            name="lon"
                            onChange={(e) =>
                              setLatLon((prev) => {
                                return { ...prev, lon: e.target.value };
                              })
                            }
                          />
                          <button className="btn">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {isLoading ? (
                  <div className="text-center">
                    <div
                      className="spinner-border text-secondary"
                      style={{
                        width: "4rem",
                        height: "4rem",
                        position: "fixed",
                        top: "35%",
                        // left: "42%",
                      }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
                    <div className="tab-content p-3">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div
                          className={
                            search.team_reference !== "" &&
                            search.team_reference === "team"
                              ? "row g-2"
                              : "row g-2 d-none"
                          }
                        >
                          <h5 className="hintsheading">Teams</h5>
                          {search?.team?.length !== 0
                            ? search?.team?.map((items, index) => (
                                <div
                                  className="col-lg-4 col-md-6 mb-3 team-item"
                                  data-game={items.game_id}
                                  data-gender={items.team_gender}
                                  data-sports={items.team_type}
                                  data-lat={items.lat}
                                  data-lon={items.lon}
                                  key={items.id}
                                >
                                  <div className="card rounded shadow-sm teamcard h-100">
                                    <div className="card-image">
                                      <span>
                                        {items.team_logo ? (
                                          <img
                                            src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${items.team_logo}`}
                                            alt={items.team_logo}
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <img
                                            src={"../../static/icona.jpg"}
                                            alt={index}
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="card-body border-top">
                                      <div className="d-flex justify-content-between align-items-center mb-2">
                                        <Link to={`/team/profile/${items.id}/${items?.team_name}`}>
                                          <h4 className="tname fs-6 mb-0">
                                            <span className="text-maroon">
                                              {items.team_name}
                                            </span>
                                          </h4>
                                        </Link>
                                      </div>
                                      <p className="fs-13 text-secondary">
                                        <i className="fas fa-map-marker-alt"></i>
                                        {items.team_address
                                          ? items.team_address
                                          : "No Address"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : "No Data Found"}
                        </div>
                        <div
                          className={
                            search.tournament_reference !== "" &&
                            search.tournament_reference === "tournament"
                              ? "row g-2"
                              : "row g-2 d-none"
                          }
                        >
                          <h5 className="hintsheading">Tournaments</h5>
                          {search?.tournament?.length !== 0
                            ? search?.tournament?.map((items, index) => (
                                <div
                                  className="col-lg-4 col-md-6 mb-3"
                                  key={items.id}
                                >
                                  <div className="card rounded shadow-sm teamcard h-100">
                                    <div className="card-image">
                                      <span>
                                        {" "}
                                        {items.team_logo ? (
                                          <img
                                            src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${items.s_logo1}`}
                                            alt={items.s_logo1}
                                          />
                                        ) : (
                                          <img src={"../../static/icona.jpg"} alt={index} />
                                        )}
                                      </span>
                                    </div>
                                    <div className="card-body border-top">
                                      <div className="d-flex justify-content-between align-items-center mb-2">
                                        <h4 className="tname fs-6 mb-0">
                                          <span className="text-maroon">
                                            {items.tournamentname}
                                          </span>
                                        </h4>
                                      </div>
                                      <p className="fs-13 text-secondary">
                                        <i className="fas fa-map-marker-alt"></i>
                                        {items.location
                                          ? items.location
                                          : "No Address"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : "No Data Found"}
                        </div>
                        <div
                          className={
                            search.player_reference !== "" &&
                            search.player_reference === "player"
                              ? "row g-2"
                              : "row g-2 d-none"
                          }
                        >
                          <h5 className="hintsheading">Players</h5>
                          {search?.players?.length !== 0
                            ? search?.players?.map((items, index) => (
                                <div
                                  className="col-lg-4 col-md-6 mb-3"
                                  key={items.id}
                                >
                                  <div className="card rounded shadow-sm teamcard h-100">
                                    <div className="card-image">
                                      <span>
                                        {" "}
                                        {items.photo ? (
                                          <img
                                            src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${items.photo}`}
                                            alt={items.team_logo}
                                          />
                                        ) : (
                                          <img src={"../../static/iconb.jpg"} alt={index} />
                                        )}{" "}
                                      </span>
                                    </div>
                                    <div className="card-body border-top">
                                      <div className="d-flex justify-content-between align-items-center mb-2">
                                        <Link
                                          to={`/player/profile/${items?.id}/${items?.first_name+items?.last_name}`}
                                        >
                                          <h4 className="tname fs-6 mb-0">
                                            <span className="text-maroon">
                                              {items.first_name +
                                                " " +
                                                items.last_name}
                                            </span>
                                          </h4>
                                        </Link>
                                      </div>
                                      <p className="fs-13 text-secondary">
                                        <i className="fas fa-map-marker-alt"></i>
                                        {items.street_address
                                          ? items.street_address
                                          : "No Address"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : "No Data Found"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <GlobalRightBar
                searchParams={searchParams}
                search={search}
                setDistance={setDistance}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Search;
