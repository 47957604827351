import React, { useEffect, useState } from "react";
// import Header from "../components/Includes/Header";
// import Footer from "../components/Includes/Footer";
// import Navbar from "../components/Includes/Navbar";
import { Constants } from "../Constants";

const Features = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Constants.featuresList);
        const result = await response.json();
        if (result.status === 1) {
          setData(result.response);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    window.scrollTo(0, 0);
  }, []);

  const minHeightStyle = {
    minHeight: "70vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5" style={minHeightStyle}>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container">
            <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
              What Features We Give
            </h2>
            <div className="card-box p-2 ">
              {data?.map((feature, index) => (
                <div className="m-2" key={index}>
                  <div className="card-features">
                    <img
                      src="/assets/frontend/images/icons/background.jpg"
                      alt="Background Image"
                    />
                    <div className="card-content-features p-1">
                      <div className="logo-features">
                        <img
                          src={`https://sportthon.com/get-res?url=${feature.image}`}
                          alt="Logo"
                        />
                      </div>
                      <div
                        className="header-features text-info"
                        style={{ textTransform: "uppercase" }}
                      >
                        {feature.name}
                      </div>
                      <div
                        className="text-features"
                        dangerouslySetInnerHTML={{
                          __html: feature?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Features;
