import React from "react";
import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";
import Navbar from "../components/Includes/Navbar";
import { Constants } from "../Constants";
const Help = () => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let url = Constants.homeCms + "?page=help";
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setData(data.response);
        setIsLoading(false);
      }
    })();
  }, []);
  const minHeightStyle = {
    minHeight: "60vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5" style={minHeightStyle}>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
                }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container">
            <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
              {data.title}
            </h2>
            <h4 dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
        )}
      </section>
    </>
  );
};
export default Help;
