import React from "react";
import { Link, useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
const TeamRightBar = ({ id }) => {
  const [person, setPerson] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [team, setTeam] = React.useState(null);

  React.useEffect(() => {
    let url = Constants.listTeamPlayer + id;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data.response);
      if (data.status === 1) {
        setTeam(data.response.team);
      }
    })();

    // console.log("Teams details: ", team);
  }, [id,load]);

  React.useEffect(() => {
    (async () => {
      let url = Constants.getOtherUsers;
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data)

      if (data.status === 1) {
        setPerson(data.response);

        setLoad(true);
      }
    })();
  }, [load]);

 
  const followTeam = async (e, teamId) => {
    e.preventDefault();
    e.target.innerHTML = "Following"; 
    let url = Constants.followTeams + teamId; 
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    if (data.status === 1) {
      // Team followed successfully, you can update the UI as needed
    //   setLoad(false)
    }
  };

  const followUser = async (e, id) => {
    e.preventDefault();
   // e.target.innerHTML = "Following";
    let url = Constants.followUsers + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    if (data.status === 1) {
      setLoad(false);
    }
    console.log("Data ",data)
  };

  // if (!load) return <div className="loader"></div>;
  if (!load)
  return (
    <div className="spinner-border text-secondary loader" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  return (
    <div className="col-lg-3 pe-lg-0 ">
      <div className="right-sidebar bg-white shadow h-100 py-3 px-2">
        <form>
          <div className="px-1">
            <h5 className="fs-6 text-secondary mb-3">Want To Follow Players?</h5>
            <div id="showOne">
              {person?.map((per, index) => (
                <div className="palylistall" key={per?.id}>
                  <div className="player-follwlist d-flex align-items-center mb-4">
                    <div className="listplayerimg me-2">
                      {per.photo ? (
                        <img
                          src={`/uploads/profile_pictures/${per?.photo}`}
                          alt="Profile Avatar"
                        />
                      ) : (
                        <span className="img-gravatar">
                          {per?.first_name?.[0]}
                          {per?.last_name?.[0]}
                        </span>
                      )}
                    </div>
                    <div className="listplayername flex-fill">
                      <Link
                        className=""
                        to={`/player/profile/${per?.id}/${per?.first_name+per?.first_name}`}
                      >
                        {per?.first_name + " " + per?.last_name}
                      </Link>
                    </div>
                    <div>
                      <button
                        className="btn followplayer"
                        onClick={(e) => followUser(e, per.id)}
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-3">
              
            </div>
          </div>
          <div className="px-1">
            <h5 className="fs-6 text-secondary mb-3">Want To Follow The Team?</h5>
            <div id="showOne">
              {team && (
                <div className="palylistall" key={team?.id}>
                  <div className="player-follwlist d-flex align-items-center mb-4">
                    <div className="listplayerimg me-2">
                      {/* {team.team_logo ? (
                        <img  src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${team.team_logo}`} alt="Profile Avatar"  />
                      ) : (
                        <span className="img-gravatar">{team.team_name?.[0]}</span>
                      )} */}
                    </div>
                    {/* <div className="listplayername flex-fill">
                     {team.team_name}
                    </div>
                    <div>
                      <button className="btn followplayer"   onClick={(e) => followTeam(e, team.id)}>Follow</button>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
            <div className="text-center mt-3">
              <Link className="text-maroon fw-bold">View More</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default TeamRightBar;
