import React from 'react';
import Header from '../components/Includes/Header';
import Footer from '../components/Includes/Footer';
import Navbar from '../components/Includes/Navbar';
import { Constants } from '../Constants';
const Features = () => {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        let url = Constants.homeCms + '?page=features';
        (async () => {

            let response = await fetch(url);
            let data = await response.json();
            if (data.status === 1) {
                setData(data.response);
            }
        })()
    }, [])

    return (
        <>

            <section className="innerbanner" style={{ backgroundImage: 'url(/assets/frontend/images/home-bg.jpg)' }}></section>

            <section className="py-5">
                <div className="container">
                    <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
                         Product {data.title} 
                    </h2>
                    <div
                        dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                </div>
            </section>

        </>
    );
};
export default Features;
