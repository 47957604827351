import React, { useRef, useState } from "react";
import Logo from "../../static/logo.png";
import BG from "../../static/home-bg.jpg";
import { Constants, userToken } from "../../Constants";
import Spinner from "../Spinner";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddTournaments = () => {
  const [active, setActive] = React.useState(1);
  const [games, setGames] = React.useState([]);
  const autoRef = React.useRef();

  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const [verification, setVarification] = React.useState(false);
  const [verificationMessage, setVerificationMessage] = React.useState({});
  const [visible, setVisible] = React.useState(false);
  const [otp, setOtp] = useState(null);

  const [loading, setLoading] = React.useState(false);

  const emailRef = useRef();
  const otpRef = useRef();

  const navigate = useNavigate();

  const mapDisplay = (num) => {
    return num === active ? "block" : "none";
  };

  React.useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        setLatLon({ lat, lon });
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, [latLon]);

  React.useEffect(() => {
    let url = Constants.allGames;
    // console.log(url);
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setGames(data.response);
      }
    })();
  }, []);

  const generateOtp = async () => {
    // setVisible(true);
    let email = emailRef.current.value;
    if (email === "") {
      alert("Email is blank");
      return;
    }
    // console.log(email);
    let url = Constants.tournamentsOTPSend + "?email=" + email;
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    if (data.status === 1) {
      setOtp(data.response.otp);
      setVisible(true);
    }
  };

  const submitOtp = async () => {
    let inputOtp = otpRef.current.value;
    if (inputOtp === "") {
      alert("OTP blank");
      return;
    }

    if (String(inputOtp) !== String(otp)) {
      alert("Invalid Otp");
      return;
    }
    setVarification(true);
    setVerificationMessage({ type: "success", message: "OTP Verified" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!verification) {
      alert("Verification failed");
      return;
    }
    // alert('done');
    setLoading(false);
    let formData = new FormData(e.target);
    let url = Constants.addTournaments;

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    if (data.status !== 1) {
      alert("There is a error to submit the form");
      return;
    }

    e.target.reset();
    window.sessionStorage.setItem("type", "success");
    // window.sessionStorage.setItem("message", data.message);
    navigate("/tournaments");
    Swal.fire({
      icon: "success",
      title: "Tournament Created Successfully",
      text: data.message,
    });
  };

  return (
    <>
      {/* <section
        className="innerbanner"
        style={{ backgroundImage: `url("${BG}")` }}
      ></section> */}
      <section className="createtourbg py-5">
        <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-lg-11">
            <div  className="mb-3" >
              <Link to={'/dashboard'} className="calender-btn active p-2" id="pills-profile-tab" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              Dashboard
              </Link>
            </div>
              <div className="shadow">
                <div className="tournametlogo text-center p-3">
                  <img
                    src="/assets/frontend/images/icons/logo.png"
                    alt="logo"
                  />
                </div>
                <div className="text-center bg-maroon p-3">
                  <h3 className="text-white h4 mb-0">TOURNAMENT ENTRY FORM</h3>
                </div>
                <div className="card-body">
                  <form
                    id="tournament"
                    method="POST"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <ul id="tournamentprogress">
                      <li className={`${active === 1 ? "active" : ""}`}>
                        1. Tournament Info
                      </li>
                      <li className={`${active === 2 ? "active" : ""}`}>
                        2. Location & Timelines
                      </li>
                      <li className={`${active === 3 ? "active" : ""}`}>
                        3. Organizers & Sponsers
                      </li>
                      <li className={`${active === 4 ? "active" : ""}`}>
                        4. Rulebook & other Details
                      </li>
                      <li className={`${active === 5 ? "active" : ""}`}>
                        5. Verification
                      </li>
                    </ul>
                    <div className="rounded-0">
                      <fieldset style={{ display: mapDisplay(1) }}>
                        <div className="form-card p-4">
                          <div className="mb-3">
                            <label>
                              Sport Name <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="game_id"
                              required
                            >
                              <option value="" hidden>
                                Choose Your Sport
                              </option>
                              {games.map((game, index) => (
                                <option key={index} value={game.id}>
                                  {game.game}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label>
                              Tournament Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="tournamentname"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label>Tournament Reach</label>
                            <select
                              className="form-control"
                              name="tournamentReach"
                              required
                            >
                              <option hidden>Select</option>
                              <option value="world">World</option>
                              <option value="country">Country</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label>
                              Location <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                ref={autoRef}
                                placeholder="Enter the Location"
                                name="location"
                                required
                              />

                              <input
                                type="hidden"
                                value={latLon.lat}
                                name="lattitude"
                                onChange={(e) =>
                                  setLatLon((prev) => {
                                    return { ...prev, lat: e.target.value };
                                  })
                                }
                              />
                              <input
                                type="hidden"
                                value={latLon.lon}
                                name="longitude"
                                onChange={(e) =>
                                  setLatLon((prev) => {
                                    return { ...prev, lon: e.target.value };
                                  })
                                }
                              />
                              <div className="input-group-text  rounded-0">
                                <i className="fas fa-map-marker-alt text-maroon"></i>
                              </div>
                            </div>
                          </div>
                          <h6 className="text-maroon mb-1">
                            Team Type / Age Group
                          </h6>
                          <div className="row">
                            <div className="col-lg-4 mb-3">
                              <label>Gender</label>
                              <select
                                className="form-control"
                                name="gender"
                                required
                              >
                                <option hidden>Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="co-ed">Mixed</option>
                              </select>
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label>Age From</label>
                              <input
                                type="text"
                                className="form-control"
                                name="age_from"
                              />
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label>Age To</label>
                              <input
                                type="text"
                                className="form-control"
                                name="age_to"
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label>
                              Competition Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="competition_type"
                              required
                            >
                              <option value="recreational">Recreational</option>
                              <option value="school">School</option>
                              <option value="college">College</option>
                              <option value="anyone">Anyone</option>
                            </select>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-6 mb-3">
                              <label>
                                Entry Fee <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="$"
                                name="entry_fee"
                              />
                            </div>

                            <div className="col-lg-3 col-md-6 mb-3">
                              <label>
                                Entry closure date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="entry_date"
                                required
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                              <label>Entry Type</label>
                              <select
                                className="form-control"
                                name="entry_type"
                                required
                              >
                                <option value="single">Single</option>
                                <option value="group">Group</option>
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                              <label>Maximum number of entries</label>
                              <select
                                className="form-control"
                                name="no_of_entries"
                                required
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <input
                          type="button"
                          name="next"
                          className="next next-action action-button"
                          value="Next"
                          onClick={() => setActive(2)}
                        />
                      </fieldset>
                      <fieldset style={{ display: mapDisplay(2) }}>
                        <div className="form-card p-4">
                          <div className="mb-3">
                            <label>
                              Location Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="location_type"
                              required
                            >
                              <option value="indoor">Indoor</option>
                              <option value="outdoor">Outdoor</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label>
                              Ground/Courts{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="courts_ground"
                              required
                            />
                          </div>
                          <h6 className="mb-1 text-maroon">Date Range</h6>
                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <label>
                                From <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="to_date"
                                required
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>
                                To <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="from_date"
                                required
                              />
                            </div>
                          </div>
                          <h6 className="mb-1 text-maroon">Timing</h6>
                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <label>
                                From <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                name="from_time"
                                required
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>
                                To <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                name="to_time"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous"
                          value="Previous"
                          onClick={() => setActive(1)}
                        />
                        <input
                          type="button"
                          name="next"
                          className="next action-button"
                          value="Next"
                          onClick={() => setActive(3)}
                        />
                      </fieldset>
                      <fieldset style={{ display: mapDisplay(3) }}>
                        <div className="form-card p-4">
                          <div className="row">
                            <div className="col-lg-12">
                              <h6 className="mb-1 text-maroon">
                                Organizers Contact Info
                              </h6>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="s_email2"
                                required
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="s_email2"
                                required
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Email </label>
                              <input
                                type="email"
                                className="form-control"
                                name="s_email3"
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Phone </label>
                              <input
                                type="number"
                                className="form-control"
                                name="s_phone1"
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="s_phone2"
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Phone </label>
                              <input
                                type="number"
                                className="form-control"
                                name="s_phone3"
                              />
                            </div>
                            <div className="col-lg-12">
                              <h6 className="mb-1 text-maroon">
                                Sponsers Info
                              </h6>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Description </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Sponser's Description"
                                  name="s_description1"
                                />
                                <div className="input-group-text  rounded-0">
                                  <i className="fas fa-comment-dots text-maroon"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>logo </label>
                              <input
                                type="file"
                                className="form-control"
                                name="s_logo1"
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Description </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Sponser's Description"
                                  name="s_description2"
                                />
                                <div className="input-group-text  rounded-0">
                                  <i className="fas fa-comment-dots text-maroon"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>logo</label>
                              <input
                                type="file"
                                className="form-control"
                                name="s_logo2"
                              />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>Description</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Sponser's Description"
                                  name="s_description3"
                                />
                                <div className="input-group-text  rounded-0">
                                  <i className="fas fa-comment-dots text-maroon"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>logo </label>
                              <input
                                type="file"
                                className="form-control"
                                name="s_logo3"
                              />
                            </div>
                          </div>
                        </div>

                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous"
                          value="Previous"
                          onClick={() => setActive(2)}
                        />
                        <input
                          type="button"
                          name="next"
                          className="next action-button"
                          value="Next"
                          onClick={() => setActive(4)}
                        />
                      </fieldset>
                      <fieldset style={{ display: mapDisplay(4) }}>
                        <div className="form-card p-4">
                          <div className="mb-3">
                            <label>Rule book</label>
                            <input
                              type="file"
                              name="rule_book"
                              className="form-control"
                              required
                            />
                            <small className="text-muted">
                              Note: PDF or Doc upload
                            </small>
                          </div>
                          <div className="mb-3">
                            <label>Flyer</label>
                            <input
                              type="file"
                              name="flyer"
                              className="form-control"
                              required
                            />
                            <small className="text-muted">
                              Note: Upload Tournament poster Image only
                            </small>
                          </div>
                          <div className="mb-3">
                            <label>logo</label>
                            <input
                              type="file"
                              name="logo_main"
                              className="form-control"
                            />
                            <small className="text-muted">
                              Note: Upload Tournament logo only
                            </small>
                          </div>
                          <div className="mb-3">
                            <label>Extra Comments</label>
                            <textarea
                              className="form-control"
                              rows="4"
                              name="extra_comments"
                            ></textarea>
                            <small className="text-muted">
                              Note: Need to say some extra words.
                            </small>
                          </div>
                        </div>
                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous"
                          value="Previous"
                          onClick={() => setActive(3)}
                        />
                        <input
                          type="button"
                          name="next"
                          className="next action-button"
                          value="Next"
                          onClick={() => setActive(5)}
                        />
                      </fieldset>
                      <fieldset style={{ display: mapDisplay(5) }}>
                        <div className="form-card p-4">
                          <div className="mb-3">
                            <label>Email Code Verification</label>
                            <div className="input-group">
                              <div className="input-group-text  rounded-0">
                                <i className="fas fa-envelope"></i>
                              </div>
                              <input
                                type="email"
                                className="form-control text-maroon"
                                placeholder="Enter Email"
                                required
                                ref={emailRef}
                              />
                            </div>
                            <div className="text-end">
                              <span
                                onClick={generateOtp}
                                className="text-maroon"
                                role="button"
                              >
                                Generate OTP
                              </span>
                            </div>
                            <div className={visible ? `d-block` : `d-none`}>
                              <div className={`input-group`}>
                                <div className="input-group-text  rounded-0">
                                  <i className="fas fa-key"></i>
                                </div>
                                <input
                                  type="number"
                                  className="form-control text-maroon"
                                  placeholder="Enter Code"
                                  required
                                  ref={otpRef}
                                />
                              </div>
                              <div className="text-end">
                                <span
                                  onClick={submitOtp}
                                  className="text-maroon"
                                  role="button"
                                >
                                  Submit
                                </span>
                              </div>
                            </div>
                            {<p>{verificationMessage.message}</p>}
                          </div>
                          <div className="mb-3">
                            <h2 className="mb-0 text-center">
                              <span className="or-divider">Or</span>
                            </h2>
                          </div>
                          <div className="mb-3">
                            <label>PID(Personal Identification DOC)</label>
                            <input
                              type="file"
                              className="form-control"
                              name=""
                            />
                          </div>
                        </div>
                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous"
                          value="Previous"
                          onClick={() => setActive(4)}
                        />
                        <button
                          type="submit"
                          name="next"
                          className="next action-button"
                          disabled={!verification}
                        >
                          {loading ? (
                            <Spinner
                              style={{
                                width: "20px",
                                height: "20px",
                                borderWidth: "2px",
                                color: "#ffffff",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </fieldset>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddTournaments;
