import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddTask = () => {
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [team, setTeam] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [events, setEvents] = React.useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      let url = Constants.teamTaskDetails + id;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      console.log(data);
      if (data.status === 1) {
        setTeam(data.response.team);
        setPlayers(data.response.players);
        setEvents(data.response.events);
      }
    })();
  }, [id, load]);
  //   console.log(players.length)

  const handleAddTask = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let url = Constants.addTeamTask + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    //   console.log(data);
    if (data.status !== 1) {
      setError(data.message);
      return;
    }
    e.target.reset();
    window.sessionStorage.setItem("type", "success");
    //   window.sessionStorage.setItem('message', data.message)
    MySwal.fire({
      icon: "success",
      title: "Task added!",
      showConfirmButton: false,
      timer: 1500,
    });
    navigate("/team/task/" + id);
  };
  // console.log(events)
  const minHeightStyle = {
    minHeight: "100vh",
  };
  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span onClick={() => navigate(-1)} className="text-black">
                <i className="fas fa-arrow-left"></i> {team.team_name}
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          <form onSubmit={handleAddTask} encType="multipart/form-data">
            {success ? <span className="text-success">{success}</span> : <></>}

            <h4 className="h5 text-maroon">Add Task:</h4>
            <div className="row my-2">
              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Assignment Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="assignment"
                    placeholder="Please enter the assignment name"
                    required
                  />
                  <span className="text-danger">{error.assignment}</span>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <label className="mb-1">
                  Event<span className="text-danger"> *</span>
                </label>
                <select className="form-control" name="event_id" required>
                  <option label="-Select-" hidden></option>
                  {events?.map((event, index) => {
                    return (
                      <option value={event.id} key={index}>
                        {event.title}
                      </option>
                    );
                  })}
                </select>
                <span className="text-danger">{error.event_id}</span>
              </div>

              <div className="col-lg-12 order-lg-1">
                <label className="mb-1">
                  Player<span className="text-danger"> *</span>
                </label>
                <select className="form-control" name="player_id" required>
                  <option label="-Select-" hidden></option>

                  {players
                    ?.filter((player) => player !== null)
                    .map((player, index) => (
                      <option value={player?.id} key={index}>
                        {player?.first_name + " " + player?.last_name}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{error.player_id}</span>
              </div>
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn btn-marooon">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddTask;
