import React from "react";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { useCallback, useState } from "react";
import { Constants } from "../../Constants";
import { useNavigate } from "react-router-dom";
import userStore from "../../Store";

const SocialLogin = () => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const setUser = userStore((state) => state.setUser);

  const navigation = useNavigate();
  const [error, setError] = React.useState("");

  const REDIRECT_URI = "https://sportthon.com";


  const onLoginStart = useCallback(() => {
    window.alert("Want to Continue?");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const handleFacebookLogin = async (provider, value) => {
    // console.log(value);
    let url = Constants.socialLogin;
    let object = {
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      password: "123456",
    };
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    let response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let data = await response.json();
    if (data.status !== 1) {
      setError(data.message);
      return;
    }

    // console.log(data);

    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = `_token=${
      data._token
    };expires=${d.toUTCString()};path="/"`;
    setError("");
    navigation("/dashboard");
  };

  // const handleGoogleLogin = async (provider, value) => {
  //     console.log(value);
  //     let url = Constants.socialLogin;
  //     let object = { 'first_name': value.given_name, 'last_name': value.family_name, 'email': value.email, 'password': '123456' }
  //     const formData = new FormData();
  //     Object.keys(object).forEach(key => formData.append(key, object[key]));
  //     let response = await fetch(url, {
  //         method: "POST",
  //         body: formData
  //     });
  //     let data = await response.json();
  //     if (data.status !== 1) {
  //         setError(data.message);
  //         return;
  //     }

  //     // console.log(data);

  //     const d = new Date();
  //     d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  //     document.cookie = `_token=${data._token
  //         };expires=${d.toUTCString()};path="/"`;
  //     setError("");
  //     navigation("/dashboard");
  // }
  const handleGoogleLogin = async (provider, value) => {
    console.log(value);

    const apiUrl = Constants.socialLogin;

    if (!value.code) {
      console.error("Google response is missing an authorization code");
      return;
    }

    const tokenUrl = "https://oauth2.googleapis.com/token";
    const tokenData = new URLSearchParams({
      code: value.code,
      client_id:
        "972292442348-ajp12b7u672h1mbtdm21o0h2mtgju2ot.apps.googleusercontent.com",
      client_secret: "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",
      redirect_uri: "https://sportthon.com",
      grant_type: "authorization_code",
    });

    const tokenResponse = await fetch(tokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: tokenData,
    });

    const tokenResult = await tokenResponse.json();

    // Check if the token exchange was successful
    if (!tokenResult.access_token) {
      console.error("Token exchange failed:", tokenResult.error);
      return;
    }

    // Fetch user profile information using the access token
    const profileUrl = "https://www.googleapis.com/oauth2/v2/userinfo";
    const profileResponse = await fetch(profileUrl, {
      headers: {
        Authorization: `Bearer ${tokenResult.access_token}`,
      },
    });

    const profileData = await profileResponse.json();

    // Extract relevant information from the profile response
    const firstName = profileData.given_name;
    const lastName = profileData.family_name;
    const email = profileData.email;

    console.log("profile input", firstName, lastName, email);

    // Define the 'object' variable
    const object = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: "123456",
    };

    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));

    let response = await fetch(apiUrl, {
      method: "POST",
      body: formData,
    });

    let data = await response.json();

    if (data.status !== 1) {
      setError(data.message);
      return;
    }

    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = `_token=${
      data._token
    };expires=${d.toUTCString()};path="/"`;
    setUser(data.user);
    setError("");
    if (data.status === 1) navigation("/dashboard");
  };

  const handleLinkedIn = async (provider, value) => {
    console.log(value);
    // let url = Constants.socialLogin;
    // let object = { 'first_name': value.first_name, 'last_name': value.last_name, 'email': value.email, 'password': '123456' }
    // const formData = new FormData();
    // Object.keys(object).forEach(key => formData.append(key, object[key]));
    // let response = await fetch(url, {
    //     method: "POST",
    //     body: formData
    // });
    // let data = await response.json();
    // if (data.status !== 1) {
    //     setError(data.message);
    //     return;
    // }

    // console.log(data);

    // const d = new Date();
    // d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    // document.cookie = `_token=${data._token
    //     };expires=${d.toUTCString()};path="/"`;
    // setError("");
    // navigation("/dashboard");
  };

  const onLogout = useCallback(() => {}, []);

  return (
    <>
      <p className="text-center">Continue With </p>
      <div className="socaillogin">
        <ul>
          <li>
            {/* <LoginSocialFacebook
                            appId={process.env.REACT_APP_FB_APP_ID || '602300635095470'}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            }
                            // onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => handleFacebookLogin(provider, data)}
                            onReject={err => {
                                console.log(err);
                            }}
                        >

                            <FacebookLoginButton />
                        </LoginSocialFacebook> */}
          </li>
          <li>
            <LoginSocialGoogle
              client_id={
                "972292442348-ajp12b7u672h1mbtdm21o0h2mtgju2ot.apps.googleusercontent.com"
              }
              redirect_uri={REDIRECT_URI}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) =>
                handleGoogleLogin(provider, data)
              }
              onReject={(err) => {
                console.log(err);
              }}
              onSuccess={(data) => {
                console.log(data);
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle>
          </li>
          <li>
            {/* <LoginSocialLinkedin
                            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || '77v5l1qbzrvurf'}
                            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || 'yokMUg7MNBCxD2Fg'}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => handleLinkedIn(provider, data)}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <LinkedInLoginButton />
                        </LoginSocialLinkedin> */}
          </li>
        </ul>
      </div>
    </>
  );
};

export default SocialLogin;
