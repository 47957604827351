import React from "react";
import { Constants, userToken } from "../../Constants";
import User from "../../static/user.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const initialState = {
  0: { display: "none" },
  1: { display: "none" },
  2: { display: "none" },
  3: { display: "none" },
};
const reducer = (state, action) => {
  switch (action) {
    case "first":
      return {
        0: { display: "block" },
        1: { display: "none" },
        2: { display: "none" },
        3: { display: "block" },
      };
    case "second":
      return {
        0: { display: "none" },
        1: { display: "block" },
        2: { display: "none" },
        3: { display: "block" },
      };
    case "third":
      return {
        0: { display: "none" },
        1: { display: "none" },
        2: { display: "block" },
        3: { display: "block" },
      };
    case "final":
      return {
        0: { display: "none" },
        1: { display: "none" },
        2: { display: "none" },
        3: { display: "none" },
      };
    default:
      return initialState;
  }
};

const WelcomeScreen = ({ user }) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [games, setGames] = React.useState({});
  const [country, setCountry] = React.useState([]);

  const imageRef = React.useRef();

  React.useEffect(() => {
    if (!user.type_of_sports) {
      dispatch("third");
    }
    if (!user.gender || !user.dob || !user.member_phone) {
      dispatch("second");
    }
    if (!user.photo) {
      dispatch("first");
    }
  }, [user]);

  React.useEffect(() => {
    let url = Constants.allGames;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setGames(data);
    })();
  }, [games]);

  React.useEffect(() => {
    let url = Constants.listCountry;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setCountry(data.response);
    })();
  }, [country]);

  const handleProfileImage = async (e) => {
    e.preventDefault();

    let url = Constants.profileImage;
    let formData = new FormData(e.target);

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      let data = await response.json();
      if (data.status === 1) {
        dispatch("second");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleProfileInfo = async (e) => {
    e.preventDefault();
    // Check if gender and birthday are provided
    const gender = e.target.gender.value;
    const dob = e.target.dob.value;

    if (!gender) {
      alert("Gender is mandatory.");
      return;
    }
    if (!dob) {
      alert(" Birthday is mandatory. ");
      return;
    }
    let url = Constants.profileInfo;
    let formData = new FormData(e.target);

    // console.log(formData);

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      let data = await response.json();
      if (data.status === 1) {
        dispatch("third");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleGameInfo = async (e) => {
    e.preventDefault();
    let url = Constants.gameInfo;
    let formData = new FormData(e.target);

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });
      let data = await response.json();
      if (data.status === 1) {
        dispatch("final");
        window.location.reload(); 
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setPreview = (e) => {
    let file = e.target.files[0];
    let blob = URL.createObjectURL(file);
    imageRef.current.src = blob;
    imageRef.current.onload = function () {
      URL.revokeObjectURL(blob);
    };
  };
  return (
    <div className="welcome-screen" style={state[3]}>
      <section id="msform" className="formlogin">
        <form
          style={state[0]}
          onSubmit={handleProfileImage}
          encType="multipart/form-data"
        >
          <h2 className="fs-title">Welcome to Sportthon</h2>
          <p className="form-bottomtext">Let other players know who You are</p>
          <div className="uploadiimg">
            <img alt="user-ping" src={User} ref={imageRef} />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Upload a Photo<span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="profile_image"
                  accept=".png, .jpg, .jpeg"
                  onChange={setPreview}
                  required
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            name="previous"
            className="previous action-button"
            value="Save"
          />
          <input
            type="submit"
            name="next"
            className="next action-button"
            value="Next"
          />
        </form>

        <form style={state[1]} onSubmit={handleProfileInfo}>
          <h1 className="fs-title">Welcome to Sportthon</h1>
          <p className="form-bottomtext">
            <strong>
              {" "}
              To serve You better, We need to Know more about You
            </strong>
          </p>

          <label>
            Gender<span className="text-danger">*</span>
          </label>
          <ul className="genderlist">
            <li htmlFor="gender-m">
              <label>
                <img alt="gender-m" src="assets/frontend/images/male.png" />
                <br />
                Male
                <input type="radio" id="gender-m" name="gender" value="male" />
              </label>
            </li>

            <li htmlFor="gender-f">
              <label>
                <img alt="gender-f" src="assets/frontend/images/female.png" />
                <br />
                Female
                <input
                  type="radio"
                  id="gender-f"
                  name="gender"
                  value="female"
                />
              </label>
            </li>

            <li htmlFor="gender-t">
              <label>
                <img
                  alt="gender-f"
                  src="assets/frontend/images/transgender.png"
                />
                <br />
                Others
                <input type="radio" id="gender-t" name="gender" value="trans" />
              </label>
            </li>
          </ul>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  When is your Birthday ? <span className="text-danger">*</span>
                </label>

                {/* <DatePicker
                  name="dob"
                  selected={startDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setStartDate(date)}
                  showMonthDropdown
                  showYearDropdown
                  // yearDropdownItemNumber={100}
                /> */}
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                  required
                />
                {/* <DatePickerComponent id="datepicker" value={dateValue} format='yyyy-MM-dd' placeholder='Enter date'/>; */}

                {/* <input
                  type="date"
                  className="form-control datepicker"
                  name="dob"
                /> */}
              </div>
              <div className="form-group">
                <label>
                  Stay in touch with your teammates, Enter phone number:
                  <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <div className="d-flex w-100">
                    <div className="pe-2">
                      <select className="form-control" name="phone_code">
                        <option value="000" hidden>
                          000
                        </option>
                        {country.map((cnt) => (
                          <option key={cnt.id} value={cnt.phonecode}>
                            + {cnt.phonecode} {cnt.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-100">
                      <input
                        type="tel"
                        className="form-control"
                        name="member_phone"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="button"
            name="previous"
            className="previous action-button"
            value="Previous"
            onClick={() => dispatch("first")}
          />
          <input
            type="submit"
            name="next"
            className="next action-button"
            value="Next"
          />
        </form>

        <form style={state[2]} onSubmit={handleGameInfo}>
          <h2 className="fs-title">Welcome to Sportthon</h2>
          <p className="form-bottomtext">
            Pick Your Favourite Sport<span className="text-danger">*</span>
          </p>
          <ul className="favouriteSport">
            {games?.response?.map((game, index) => {
              return (
                <li key={index}>
                  <label htmlFor={`game-${game.id}`}>
                    <div className="sportlogo">
                      <img
                        alt={game.icon}
                        src={`/uploads/games/${game.icon}`}
                      />
                    </div>
                    {game.game}{" "}
                    <input
                      type="checkbox"
                      id={`game-${game.id}`}
                      name="type_of_sports[]"
                      value={game.id}
                    />
                  </label>
                </li>
              );
            })}
          </ul>

          <input
            type="button"
            name="previous"
            className="previous action-button"
            value="Previous"
            onClick={() => dispatch("second")}
          />
          <input
            type="submit"
            name="submit"
            className="submit action-button"
            value="Submit"
          />
        </form>
      </section>
    </div>
  );
};
export default WelcomeScreen;
