import React from "react";
import { Constants, userToken } from "../Constants";
import { useParams } from "react-router-dom";
import userStore from "../Store";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const Teams = () => {
  const slug = "about-us";
  const [data, setData] = React.useState(null);
  const [load, setLoad] = React.useState(false);
  const user = userStore((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFollowing, setIsFollowing] = React.useState(false);
  const [isJoinRequestSent, setIsJoinRequestSent] = React.useState(false);
  const { id } = useParams();
  // console.log(user);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    let url = Constants.teamProfile + id;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setData(data.response);
        setIsLoading(false);
      }
    })();
    return () => {
      setLoad(false);
    };
  }, [slug, id, load]);

  React.useEffect(() => {
    // Check if the user has sent a join request
    const checkJoinRequest = async () => {
      let url = Constants.requestedPlayerList;

      // Create a new FormData object
      const formData = new FormData();
      formData.append("teamId", id);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      let data = await response.json();
      // console.log(data);
      if (data.status === 1 && data.payload.length > 0) {
        setIsJoinRequestSent(true);
      } else {
        setIsJoinRequestSent(false);
      }
    };

    if (userToken()) {
      checkJoinRequest();
    }
  }, [id]);

  const followTeams = async (e) => {
    e.preventDefault();
    let url = Constants.followTeams + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let responseData = await response.json();

    if (responseData.status === 1) {
      setLoad(true);
    }
  };
  const joinTeam = async () => {
    // Create a FormData object to send the teamId in the request body
    const formData = new FormData();
    formData.append("teamId", id);

    let url = Constants.joinTeamRequest;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });

    let data = await response.json();
    // Handle the response data as needed
    console.log(data);

    // Assuming the response contains the necessary information for joining the team
    if (data.status === 1) {
      // Team joined successfully
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your joining request has been successfully sent to the team manager and is under review.",
      });
      setIsJoinRequestSent(true);
    } else {
      // Handle the error case
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to join the team. Please try again.",
      });
    }
  };
  // console.log("data and user are : ", data, user);

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5">
        <div className="container">
          <div className="box-profile p-3 border rounded shadow">
            <h2 className="m-0">
              <Link onClick={() => navigate(-1)} className="text-maroon">
                <BiArrowBack />
              </Link>
            </h2>

            {isLoading ? (
              <div className="text-center" style={{ minHeight: "80vh" }}>
                <div
                  className="spinner-border text-secondary"
                  style={{
                    width: "4rem",
                    height: "4rem",
                    position: "fixed",
                    top: "50%",
                    left: "48%",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-4 h-100">
                  <div className="profileleft-panel mb-4">
                    <div className="player-boxinfo bg-maroon">
                      <div className="player-img position-relative">
                        {data?.team_logo ? (
                          <img
                            src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${data?.team_logo}`}
                            alt={data.team_logo}
                          />
                        ) : (
                          <img
                            src={`/static/iconc.jpg`}
                            alt="Profile Avatar"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {userToken() ? (
                    <div className="toplogin ms-auto">
                      <div className="toplogin ms-auto text-center">
                        <span onClick={(e) => followTeams(e)} className="btn">
                          {user.id === data.team_creator
                            ? "Unfollow"
                            : data?.followers.find(
                                (item) => item.follower_id === user.id
                              )
                            ? "Unfollow"
                            : "Follow"}
                        </span>
                        {isJoinRequestSent ? (
                          <span className="btn mx-2">Request Sent</span>
                        ) : (
                          <span
                            onClick={
                              user.id !== data.team_creator &&
                              (data?.followers.length === 0 ||
                                !data.followers.find(
                                  (item) => item.follower_id === user.id
                                ))
                                ? joinTeam
                                : null
                            }
                            className="btn mx-2"
                          >
                            {user.id === data.team_creator ||
                            (data?.followers.length > 0 &&
                              data.followers.find(
                                (item) => item.follower_id === user.id
                              ))
                              ? "Already Joined"
                              : "Join"}
                          </span>
                        )}
                        <span className="dropdown m-2">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {/* <li>
                              <span className="dropdown-item">Join</span>
                            </li> */}
                            <li>
                              <span className="dropdown-item">Report</span>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-8">
                  <h1 className="h1 fw-bold text-maroon mb-2 mt-2">
                    {data.team_name}
                  </h1>
                  <div className="d-md-flex mb-2 mt-2"></div>
                  <div classNam e="mb-3">
                    <div className="pe-md-4">
                      <p className=" mb-1 fw-semibold mb-0">
                        <i className="fas fa-user text-maroon me-1"></i> Team
                        Type:{" "}
                        <span className="ps-2 fw-semibold">
                          {data?.team_gender?.toUpperCase()}
                        </span>
                      </p>
                    </div>
                    <div className="pe-md-4">
                      <p className=" mb-1 fw-semibold mb-0">
                        <i className="fas fa-map-marker-alt text-maroon me-1"></i>{" "}
                        Location:{" "}
                        <span className="ps-2 fw-semibold">
                          {data?.team_address}
                        </span>
                      </p>
                    </div>
                  </div>
                  <h3 className="profileheading text-uppercase">ABOUT</h3>
                  <p>{data?.team_desc}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default Teams;
