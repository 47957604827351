import React, { useEffect, useState } from "react";
import { Constants } from "../Constants";
const GlobalSidebar = () => {
  const [games, setGames] = useState([]);
  const [teamType, setTeamType] = React.useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [loading, setLoading] = React.useState({});
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedPlayerSports, setSelectedPlayerSports] = useState([]);
  const [distance, setDistance] = React.useState(null);
  const { search } = window.location;
  const latLon = React.useMemo(() => {
    let searchObj = new URLSearchParams(search);

    let lat = searchObj.get("lat") ?? "0";
    let lon = searchObj.get("lon") ?? "0";

    return { lat: parseFloat(lat), lon: parseFloat(lon) };
  }, [search]);
  useEffect(() => {
    let url = Constants.allGames;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      // console.log()
      if (data.status === 1) {
        setGames(data.response);
      }
    })();
  }, [loading]);
  // console.log("allgames", games)

  useEffect(() => {
    let url = Constants.teamType;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setTeamType(data.response);
      }
    })();
  }, [loading]);
  // console.log(teamType)

  function distanceCalculation(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  const filterTeamData = () => {
    let teamItems = document.querySelectorAll(".team-item");
    teamItems.forEach((item) => {
      let lat = parseFloat(item.dataset.lat ?? 0);
      let lon = parseFloat(item.dataset.lon ?? 0);
      item.removeAttribute("style");
      let distanceBet = distanceCalculation(latLon.lat, latLon.lon, lat, lon);

      let isDisplayed = true; // Flag to determine if the item should be displayed

      if (
        selectedGames.length &&
        !selectedGames.includes(String(item.dataset.game))
      ) {
        isDisplayed = false;
      }

      if (
        selectedGender.length &&
        !selectedGender.includes(item.dataset.gender)
      ) {
        isDisplayed = false;
      }

      if (
        selectedSports.length &&
        !selectedSports.includes(item.dataset.sports)
      ) {
        isDisplayed = false;
      }

      if (distance && distanceBet >= distance) {
        isDisplayed = false;
      }

      // Update the display style based on the isDisplayed flag
      if (!isDisplayed) {
        item.style.display = "none";
      } else {
        item.style.display = ""; // Show the item
      }
    });
  };

  // Function to handle player sports filter
  const togglePlayerSports = (e) => {
    let { value, checked } = e.target;

    setSelectedPlayerSports((prev) => {
      if (checked) {
        prev.push(value);
      } else {
        prev.splice(prev.indexOf(value), 1);
      }
      return [...prev];
    });
  };

  const toggleGames = (e) => {
    let { value, checked } = e.target;

    setSelectedGames((prev) => {
      if (checked) {
        prev.push(value);
      } else {
        prev.splice(prev.indexOf(value), 1);
      }
      return [...prev];
    });
  };

  const toggleType = (e) => {
    let { value, checked } = e.target;
    setSelectedGender((prev) => {
      if (checked) {
        prev.push(value);
      } else {
        prev.splice(prev.indexOf(value), 1);
      }
      return [...prev];
    });
  };

  const toggleSports = (e) => {
    let { value, checked } = e.target;
    setSelectedSports((prev) => {
      if (checked) {
        prev.push(value);
      } else {
        prev.splice(prev.indexOf(value), 1);
      }
      return [...prev];
    });
  };

  const toggleDistance = (e) => {
    let { value } = e.target;
    setDistance(parseFloat(value));
  };

  useEffect(() => {
    filterTeamData();
  }, [selectedGames, selectedGender, distance]);
  // console.log("Search object:", search);
 

  return (
    <>
      <div className="col-lg-2 ps-lg-0">
        <div className="left-sidebar bg-white shadow h-100">
          <div className="hintsresult">
            <div className="hintsheading">Sports Name</div>
            <div className="hintsBox">
              {games?.map((game) => (
                <div className="form-check" key={game.id}>
                  <input
                    onChange={toggleGames}
                    className="form-check-input games-check"
                    type="checkbox"
                    value={game.id}
                    id="sp1"
                  />
                  <label className="form-check-label" htmlFor="sp1">
                    {game.game}
                  </label>
                </div>
              ))}
            </div>
            <div className="hintsheading">Team Type</div>
            <div className="hintsBox">
              <div className="form-check">
                <input
                  onChange={toggleType}
                  className="form-check-input"
                  type="checkbox"
                  value="male"
                  id="ttype1"
                  name="team_gender"
                />
                <label className="form-check-label" htmlFor="ttype1">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input
                  onChange={toggleType}
                  className="form-check-input"
                  type="checkbox"
                  value="female"
                  id="ttype2"
                  name="team_gender"
                />
                <label className="form-check-label" htmlFor="ttype2">
                  Female
                </label>
              </div>
              <div className="form-check">
                <input
                  onChange={toggleType}
                  className="form-check-input"
                  type="checkbox"
                  value="co-ed"
                  id="ttype3"
                  name="team_gender"
                />
                <label className="form-check-label" htmlFor="ttype3">
                  Mixed
                </label>
              </div>
            </div>
            <div className="hintsheading">Distance</div>
            <div className="hintsBox">
              <div className="form-check">
                <input
                  onChange={toggleDistance}
                  className="form-check-input"
                  type="radio"
                  value="10"
                  id="distance1"
                  name="distance"
                  
                />
                <label className="form-check-label" htmlFor="distance">
                  10 Miles
                </label>
              </div>
              <div className="form-check">
                <input
                  onChange={toggleDistance}
                  className="form-check-input"
                  type="radio"
                  value="25"
                  id="distance2"
                  name="distance"
                />
                <label className="form-check-label" htmlFor="distance2">
                  25 Miles
                </label>
              </div>
              <div className="form-check">
                <input
                  onChange={toggleDistance}
                  className="form-check-input"
                  type="radio"
                  value="50"
                  id="distance3"
                  name="distance"
                />
                <label className="form-check-label" htmlFor="distance3">
                  50 Miles
                </label>
              </div>
            </div>

            <div className="hintsheading">Sports Type</div>
            <div className="hintsBox">
              {teamType?.map((type) => (
                <div className="form-check" key={type.id}>
                  <input
                    onChange={toggleSports}
                    className="form-check-input"
                    type="checkbox"
                    value={type.id}
                    name="team_type"
                  />
                  <label className="form-check-label" htmlFor="stype1">
                    {type.name}
                  </label>
                </div>
              ))}
            </div>
            {/* <div className="hintsheading">Player Sports</div>
            <div className="hintsBox">
              {search?.player_reference !== "" &&
              search?.player_reference === "player"
                ? // Render player sports filter options
                  search?.players
                    ?.flatMap((player) => JSON.parse(player.type_of_sports))
                    .map((sportId) => {
                      const sport = search?.player_sports.find(
                        (s) => s.id === sportId
                      );
                      return (
                        <div className="form-check" key={sportId}>
                          <input
                            onChange={togglePlayerSports}
                            className="form-check-input"
                            type="checkbox"
                            value={sportId}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`psport${sportId}`}
                          >
                            {sport ? sport.name : `Sport ${sportId}`}
                          </label>
                        </div>
                      );
                    })
                : "No Data Found"}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default GlobalSidebar;
