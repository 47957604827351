import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Communications = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [message, setMessage] = React.useState({})

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let url = Constants.sendNotifications + id;

        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + userToken(),
            },
            body: formData,
        })
        let data = await response.json();
        if (data.status === 1) {
            MySwal.fire({
                icon: "success",
                title: "Notification sent successfully!",
                showConfirmButton: true,
                timer: 5000,
               
              });
              e.target.reset();
            //console.log(data.message)
        } else {
            setMessage({ type: 'danger', msg: data.message })
        }

    }
    const minHeightStyle = {
        minHeight: "100vh",
      };

    return (
        <div className="col-lg-7" style={minHeightStyle}>
            <div className="middle-top-head my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="tophead-title">
                        <h2 className="mb-0">
                            <span onClick={navigate(-1)} className="text-black"><i className="fas fa-arrow-left"></i>Annoucement</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">

                {/* <div className={`alert alert-${message.type}`}>
                    {message.message}
                </div> */}

                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="col-sm-12">
                                <textarea placeholder="Write something here..." name="message" className="form-control textarea_style" rows="10" required=""></textarea>
                            </div>
                        </div>

                        <div className="form-group">
                            <input type="submit" name="submit" className="btn btn-marooon my-2 float-right" value="Send" />
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};
export default Communications;
