import React from "react";
import { Constants } from "../Constants";
const HomepageContent = () => {
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        let url = Constants.homeCms + '?page=welcome-to-sportthon';
        (async () => {

            let response = await fetch(url);
            let data = await response.json();
           
            if (data.status === 1) {
                setData(data.response);
            }
        })()
    }, [])
    
    return (
        <section className="py-5">
            <div className="container">
                <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">{data.title}</h2>
                <div className="row justify-content-center">
                    <div className="col-lg-12 mb-4 text-center">
                        <div
                            dangerouslySetInnerHTML={{ __html: data.description }}
                          
                        />
                    </div>

                </div>
            </div>
        </section>
    )
}
export default HomepageContent;