import Header from '../components/Includes/Header';
import Footer from '../components/Includes/Footer';
import Navbar from '../components/Includes/Navbar';
import React from 'react';
import { Constants } from '../Constants';
import { useParams } from 'react-router-dom';
const TournamentView = () => {

    const slug = "about-us";
    const [data, setData] = React.useState([]);
    const [load, setLoad] = React.useState(false);

    const {id} = useParams();

    React.useEffect(() => {
        let url = Constants.viewTournaments + id;
        (async () => {

            let response = await fetch(url);
            let data = await response.json();
            // console.log(data);
            if(data.status === 1){
                setData(data.response);
            }
        })()
    }, [slug])

    return (
        <>
            <Header />
            <Navbar />


            <section className="innerbanner" style={{ backgroundImage: 'url(/assets/frontend/images/home-bg.jpg)' }}></section>

<section className="py-5">
    <div className="container">
        <div className="box-profile p-3 border rounded shadow">
            <div className="row">
                <div className="col-lg-4 h-100">
                    <div className="profileleft-panel mb-4">
                        <div className="player-boxinfo bg-maroon">
                            <div className="player-img position-relative">
                                <img src={`/uploads/profile_pictures/${data.photo}`} alt={data.photo}/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center followertext">
                            <h4>Followers (0)</h4>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><span className="dropdown-item">Report</span></li>
                                    <li><span className="dropdown-item">Message</span></li>
                                    <li><span className="dropdown-item">Block</span></li>
                                </ul>
                            </div>
                        </div>
                        <ul className="profilesocial pb-3">
                            <li><span><i className="fab fa-facebook-f"></i></span></li>
                            <li><span><i className="fab fa-twitter"></i></span></li>
                            <li><span><i className="fab fa-instagram"></i></span></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-8">
                    <h1 className="h1 fw-bold text-maroon mb-2 mt-2">{data?.tournamentname}</h1>
                    <div className="d-md-flex mb-2 mt-2">
                        {/* <div className="pe-md-4">
                            <h6 className="text-uppercase mb-1 fw-bold">Test 1</h6>
                        </div> */}
                    </div>
                    <div className="mb-3">
                        <div className="pe-md-4">
                            <p className=" mb-1 fw-semibold mb-0"><i className="fas fa-map-marker-alt text-maroon me-1"></i> Location: <span className="ps-2 fw-semibold">{data?.location}</span></p>
                        </div>
                    </div>
                    <h3 className="profileheading text-uppercase">ABOUT</h3>
                    <p>{data?.about_me}</p>

                    <h3 className="profileheading text-uppercase mt-4">ACHIVEMENTS</h3>
                    <div className="mb-3 exprienceinfo">
                        {data?.achievement}
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>




            <Footer />
        </>
    );
};
export default TournamentView;
