import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import userStore from "../../Store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const TeamTask = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { user } = userStore();
  const [load, setLoad] = React.useState(true);
  const [team, setTeam] = React.useState({});
  const [task, setTask] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let url = Constants.teamTask + id;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setTeam(data.response.team);
        setTask(data.response.task);
        setIsLoading(false);
      }
    })();
  }, [id, load]);

  const handleDelete = async (tsk_id) => {
    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you really want to delete this task?",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        let url = Constants.deleteTeamTask + tsk_id;
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        let data = await response.json();
        Swal.fire({
          icon: "success",
          title: "Task deleted!",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsLoading(false);
        setTask((prevTask) => prevTask.filter((task) => task.id !== tsk_id));

        if (data.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Task deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          setTask((prevTask) => prevTask.filter((task) => task.id !== tsk_id));
        }
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const handleEdit = (tsk, uri) => {
    navigate(uri, { state: { tsk } });
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };
  console.log(task);
  const convertToAMPM = (time24) => {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span onClick={() => navigate(-1)} className="text-black">
                  <i className="fas fa-arrow-left"></i>
                </span>
                {team?.team_name}
              </h2>
            </div>
            <div className="d-flex align-items-center">
              {user?.id === team?.team_creator ? (
                <div>
                  <Link to={`/team/add-task/${id}`} className="btn btn-marooon">
                    Add Task<i className="fas fa-plus ms-2"></i>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "3rem",
                height: "3rem",
                position: "fixed",
                top: "50%",
                left: "42%",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
            <table className="table playerlist-table">
              <thead>
                <tr className="bg-maroon text-light">
                  <th></th>
                  <th>Player Name</th>
                  <th>Task</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {task?.map((tsk, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td>{tsk?.player_name}</td>
                      <td>{tsk?.assignment}</td>
                      <td>{new Date(tsk.event_date).toDateString()}</td>
                      <td>{convertToAMPM(tsk.event_time)}</td>
                      <td>
                        {user?.id === team?.team_creator ? (
                          <span
                            className="fa fa-edit text-info"
                            onClick={() =>
                              handleEdit(
                                tsk,
                                `/team/edit-task/${team?.id}/${tsk?.id}`
                              )
                            }
                          ></span>
                        ) : (
                          ""
                        )}

                        {user?.id === team?.team_creator ? (
                          <span
                            className="fa fa-trash-alt text-danger ms-2 cursor-pointer"
                            onClick={() => handleDelete(tsk?.id)}
                          ></span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
export default TeamTask;
