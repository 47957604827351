import { useEffect, useState } from "react";
import { userToken } from "../../Constants";
import {
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { removeToken } from "../../Constants";
import  userStore  from "../../Store";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const Header = ({ settings }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setUser = userStore((state) => state.setUser);
  const openNav = () => {
    document.dispatchEvent(new CustomEvent("openNav"));
  };
  const handleLogout = (e) => {
    e.preventDefault();

    MySwal.fire({
      title: "Are you sure?",
      text: "You will be logged out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "You have been logged out.",
          icon: "success",
          timer: 1500,
        });
        setUser(null);
        removeToken("_token");
        navigate("/login");
      }
    });
  };
  const showBackButton = location.pathname === "/search";
  const showHomeButton = location.pathname !== "/";
  return (
    <header className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          {userToken() ? (
            <div className="col-lg-3 col-6">
              <div className="logo">
                <Link to="/">
                  <img
                    alt={"logo not rendering"}
                    src={`https://sportthon.com/get-res?url=https://api.sportthon.com/assets/images/logoicon.png`}
                  />
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="col-lg-3 col-6">
                {showHomeButton && (
                  <div className="toplogin ms-auto">
                    <div className="">
                      <Link to="/">
                        <img
                          alt={"HOME BUTTON"}
                          src={`https://sportthon.com/get-res?url=https://api.sportthon.com/assets/images/${settings?.logo}`}
                          // src={`https://sportthon.com/get-res?url=https://api.sportthon.com/assets/images/logoicon.png`}
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="col-lg-9 col-6">
            <div className="d-flex justify-content-end align-items-center">
              {userToken() ? (
                <>
                  <div className="toplogin ms-auto">
                    <Link to="/dashboard" className="btn">
                      DASHBOARD
                    </Link>
                  </div>
                  <div className=" toplogin  ">
                    <Link
                      className="btn mx-1"
                      title="LOG OUT"
                      onClick={handleLogout}
                    >
                      <AiOutlinePoweroff style={{ fontSize: "1.5rem" }} />
                    </Link>
                  </div>
                </>
              ) : (
                showBackButton && (
                  <div className="back-btn">
                    {/* <h4 className="text-white ">
                      <FaArrowLeft
                        onClick={() => navigate(-1)}
                        className="mx-2 font-weight-bold my-auto"
                      />
                    </h4> */}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
