import React from "react";
import { Constants, userToken } from "../../Constants";
import { Link } from "react-router-dom";
import NoImg from "../../static/noimage.png";

const Blog = () => {
  const [blogs, setBlogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  React.useEffect(() => {
    (async () => {
      let url = Constants.blogs;
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        // Sort blogs by created_at in descending order
        const sortedBlogs = data.response.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setBlogs(sortedBlogs);
        setIsLoading(false);
      }
    })();
  }, []);
  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0"> Whats New?</h2>
            </div>
          </div>
        </div>
        <div >
          {isLoading ? (
            <div className="text-center">
              <div
                className="spinner-border text-secondary"
                style={{
                  width: "4rem",
                  height: "4rem",
                  position: "fixed",
                  top: "50%",
                  left: "42%",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
              <div className="p-3">
              <div className="row">
                {blogs?.map((blog) => (
                  <div className="col-lg-4 col-md-6 mb-3" key={blog.blog_id}>
                    <Link to={`/blog/details/${blog.blog_id}`}>
                      <div className="card rounded shadow-sm bloglist h-100">
                        <div className="card-image">
                          <div>
                            <img
                              className="img-fluid"
                              src={
                                blog.blog_image
                                  ? `https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/blog/${blog.blog_image}`
                                  : NoImg
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-body border-top">
                          <div className="mb-2">
                            <h4 className="tname fs-6 mb-0">
                              <span className="text-maroon">
                                {blog.blog_title}
                              </span>
                            </h4>
                            <span className="badge bg-maroon">
                              {new Date(blog.created_at).toLocaleString(
                                "en-us",
                                options
                              )}
                            </span>
                          </div>
                          <p className="fs-13 text-secondary">
                            {blog.blog_description
                              .replace(/(<([^>]+)>)/gi, "")
                              .slice(0, 20)}
                            ....
                          </p>
                          <Link
                            to={`/blog/details/${blog.blog_id}`}
                            className="text-maroon"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            </div>
            
          )}
        </div>
      </div>
    </>
  );
};
export default Blog;
