import React from "react";
import { Constants } from "../Constants";
const Partners = () => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
	window.scrollTo(0, 0);
    let url = Constants.partners;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();

      if (data.status === 1) {
        setData(data.response);
        setIsLoading(false);
      }
    })();
  }, []);
  const minHeightStyle = {
    minHeight: "60vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5" style={minHeightStyle}>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
                // position: "fixed",
                // top: "50%",
                // left: "42%",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container">
            <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
              All Partners
            </h2>

            <div className="row justify-content-center">
              {data.map((partner, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-6 mb-4 text-center"
                    key={index}
                  >
                    <div className="partnerbox border rounded shadow">
                      <div className="partnerlogo">
                        <img
                          alt={partner.image}
                          src={`https://sportthon.com/get-res?url=https://api.sportthon.com/asset/images/partner/${partner?.image}`}
                        />
                      </div>
                      <div className="partnerlogocontent">
                        <h2>{partner?.name}</h2>
                        <p>{partner?.country}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default Partners;
