import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Spinner from "../Spinner";
import { useReload } from "../../Store";
// Set the value of isReload to true

// To access the updated value and log it to the console

const MySwal = withReactContent(Swal);

const MyNetwork = () => {
  const navigate = useNavigate();
  const minHeightStyle = {
    minHeight: "100vh",
  };
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [error, setError] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  // console.log(reload.isReload);
  const fetchFollowing = async () => {
    try {
      const response = await fetch(`${Constants.following}`, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        const validFollowers = data.payload.filter((user) => user !== null);

        setFollowers(validFollowers);
      }
    } catch (error) {
      console.error("Error fetching following:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFollowers = async () => {
    try {
      const response = await fetch(`${Constants.followers}`, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        // console.log(data.payload);
        const validFollowing = data.payload.filter((user) => user !== null);

        setFollowing(validFollowing);
      }
    } catch (error) {
      console.error("Error fetching followers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const unfollowUser = async (id) => {
    MySwal.fire({
      // title: "Confirm Unfollow",
      text: "Are you sure you want to unfollow this player?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Unfollow",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [id]: true,
        }));
        try {
          const response = await fetch(`${Constants.unFollowUsers}/${id}`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + userToken(),
            },
          });

          if (response.status === 200) {
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: false,
            }));
            MySwal.fire("Unfollow player successfully!");
            fetchFollowing();
            useReload.setState({ isReload: true });
          }
        } catch (error) {
          console.error("Error unfollowing user:", error);
          setError("Error unfollowing user.");
          setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [id]: false,
          }));
        }
      } else {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [id]: false,
        }));
      }
    });
  };

  useEffect(() => {
    fetchFollowing();
    fetchFollowers();
  }, []);
  // console.log(followers);

  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span onClick={() => navigate(-1)} className="text-black">
                <i className="fas fa-arrow-left"></i> My Networks
              </span>
            </h2>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title="Followings ">
          {isLoading ? (
            // <div className="row m-3">
            //   <ShimmerPlaceholder />
            //   <ShimmerPlaceholder />
            //   <ShimmerPlaceholder />
            // </div>
            <div
              className="spinner-border"
              style={{
                width: "4rem",
                height: "4rem",
                position: "fixed",
                top: "50%",
                left: "45%",
                // transform: "translate(-50%, -50%)",
              }}
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          ) : (
            <>
              <h6 className="mx-4">Followings ({followers.length})</h6>
              {error && <p>{error}</p>}
              <ul className="ul">
                {followers?.map((user) => (
                  <li key={user?.id} className="li">
                    <span className="plyerlistImg ">
                      {user?.photo ? (
                        <img
                          className="m-1"
                          src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${user?.photo}`}
                          alt="Profile Avatar"
                        />
                      ) : (
                        <span className="img-gravatar">
                          {user?.first_name?.[0]}
                          {user?.last_name?.[0]}
                        </span>
                      )}

                      <strong>
                        <Link  to={`/player/profile/${user?.id}/${user?.first_name+user?.last_name}`}>
                          {user?.first_name} {user?.last_name}
                        </Link>
                      </strong>
                    </span>
                    <span>
                      <button
                        className="btn btn-marooon followplayer mx-2"
                        onClick={() => unfollowUser(user?.id)}
                        title="Unfollow Player"
                      >
                        {loadingStates[user?.id] ? (
                          <Spinner
                            style={{
                              width: "20px",
                              height: "20px",
                              borderWidth: "2px",
                            }}
                          />
                        ) : (
                          " Unfollow"
                        )}
                      </button>
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Tab>
        <Tab eventKey="profile" title="Followers">
          {isLoading ? (
            // <div className="row m-3">
            //   <ShimmerPlaceholder />
            //   <ShimmerPlaceholder />
            //   <ShimmerPlaceholder />
            // </div>
            <div
              className="spinner-border"
              style={{
                width: "4rem",
                height: "4rem",
                position: "fixed",
                top: "50%",
                left: "50%",
                // transform: "translate(-50%, -50%)",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <h6 className="mx-4">Followers ({following.length}) </h6>
              <ul className="ul">
                {following?.map((user) => (
                  <li key={user?.id} className="li">
                    <span className="plyerlistImg  m-1">
                      {user?.photo ? (
                        <img
                          className="my-2"
                          src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${user?.photo}`}
                          alt="Profile Avatar"
                        />
                      ) : (
                        <span className="img-gravatar">
                          {user?.first_name?.[0]}
                          {user?.last_name?.[0]}
                        </span>
                      )}

                      <strong>
                      <Link  to={`/player/profile/${user?.id}/${user?.first_name+user?.last_name}`}>
                          {user?.first_name} {user?.last_name}
                        </Link>
                      </strong>
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default MyNetwork;
