import React from "react";
import { Link } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import { useReload } from "../../Store";

const RightBar = () => {
  const [person, setPerson] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [team, setTeam] = React.useState([]);
  const isReload = useReload((state) => state.isReload);
  React.useEffect(() => {
    async function fetchOtherUsers() {
      try {
        const response = await fetch(Constants.getOtherUsers, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          if (data.status === 1) {
            setPerson(data.response);

            setLoad(true);
          }
        }
      } catch (error) {
        console.error("Error fetching other users:", error);
      }
    }

    fetchOtherUsers();
  }, [load, isReload]);

  const followUser = async (e, id) => {
    e.preventDefault();
    e.target.innerHTML = "Following";

    try {
      const url = Constants.followUsers + id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.status === 1) {
          setLoad(false);
        }
      }
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const unfollowUser = async (e, id) => {
    e.preventDefault();
    e.target.innerHTML = "Unfollow";

    try {
      const url = Constants.unFollowUsers + id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.status === 1) {
          setLoad(false);
        }
      }
    } catch (error) {
      console.error("Error unfollowing user:", error);
    }
  };
  const followTeam = async (e, id) => {
    e.preventDefault();
    e.target.innerHTML = "Following";

    try {
      const url = Constants.followTeams + id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.status === 1) {
          setLoad(false);
        }
      }
    } catch (error) {
      console.error("Error following team:", error);
    }
  };

  React.useEffect(() => {
    async function fetchTeams() {
      try {
        const url = Constants.listTeam;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        const data = await response.json();
        if (data.status === 1) {
          setTeam(data.response);
          setLoad(true);
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    }

    fetchTeams();
  }, [load]);
  // console.log(team);

  // if (!load) return <div className="loader"></div>;
  if (!load)
    return (
      <div
        className="spinner-border text-secondary loader"
        role="status"
        style={{
          width: "3rem",
          height: "3rem",
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
// console.log('persons are ',person)
  return (
    <div className="col-lg-3 pe-lg-0 ">
      <div className="right-sidebar bg-white shadow h-100 py-3 px-2">
        <form>
          <div className="px-1">
            <h5 className="fs-6 text-secondary mb-3">Want To Follow ?</h5>
            <div id="showOne">
              {person?.map((per, index) => (
                <div className="palylistall" key={per?.id}>
                  <div className="player-follwlist d-flex align-items-center mb-4">
                    <div className="listplayerimg me-2">
                      {per?.photo ? (
                        <img
                          src={`/uploads/profile_pictures/${per?.photo}`}
                          alt="Profile Avatar"
                        />
                      ) : (
                        <span className="img-gravatar">
                          {per?.first_name?.[0]}
                          {per?.last_name?.[0]}
                        </span>
                      )}
                    </div>
                    <div className="listplayername flex-fill">
                      <Link
                        className=""
                        to={`/player/profile/${per?.id}/${per?.first_name+per?.last_name}`}
                      >
                        {per?.first_name + " " + per?.last_name}
                      </Link>
                    </div>
                    <div>
                      {per?.following ? (
                        <button
                          className="btn followplayer"
                          onClick={(e) => unfollowUser(e, per.id)}
                        >
                          Unfollow
                        </button>
                      ) : (
                        <button
                          className="btn followplayer"
                          onClick={(e) => followUser(e, per.id)}
                        >
                          Follow
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-3">
              <Link className="text-maroon fw-bold">View More</Link>
            </div>
          </div>
          <div className="px-1">
            {/* <h5 className="fs-6 text-secondary mb-3">Want To Follow Teams?</h5> */}
            {/* <div id="showOne">
              {team?.map((team, index) => (
                <div className="palylistall" key={team?.id}>
                  <div className="player-follwlist d-flex align-items-center mb-4">
                    <div className="listplayerimg me-2">
                      {team?.team_logo ? (
                        <img
                          src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${team.team_logo}`}
                          alt="Profile Avatar"
                        />
                      ) : (
                        <span className="img-gravatar">{team?.team_name}</span>
                      )}
                    </div>
                    <div className="listplayername flex-fill">
                      <Link>{team?.team_name}</Link>
                    </div>
                    <div>
                      {team.status === 1 ? (
                        <button className="btn followplayer">Following</button>
                      ) : (
                        <button
                          className="btn followplayer"
                          onClick={async (e) => {
                            e.preventDefault();
                            e.target.innerHTML = "Following";

                            try {
                              const url = Constants.followTeams + team.id;
                              const response = await fetch(url, {
                                method: "POST",
                                headers: {
                                  Authorization: "Bearer " + userToken(),
                                },
                              });

                              if (response.status === 200) {
                                const data = await response.json();
                                if (data.status === 1) {
                                  // Update the team status to 1 (following)
                                 
                                  
                                }
                              }
                            } catch (error) {
                              console.error("Error following team:", error);
                            }
                          }}
                        >
                          Follow
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
           <div className="text-center mt-3">
              <Link className="text-maroon fw-bold">View More</Link>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RightBar;
