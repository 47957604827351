import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

import { Link, useParams, useNavigate } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import { FaPlus } from "react-icons/fa";

import { BiEnvelope } from "react-icons/bi";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddDutyPopover = ({ onAddDuty }) => {
  const [show, setShow] = useState(false);

  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [team, setTeam] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [events, setEvents] = React.useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      let url = Constants.teamTaskDetails + id;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setTeam(data.response.team);
        setPlayers(data.response.players);
        setEvents(data.response.events);
      }
    })();
  }, [id, load]);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleAddTask = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let url = Constants.addTeamTask + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    //   console.log(data);
    if (data.status !== 1) {
      setError(data.message);
      return;
    }
    e.target.reset();
    window.sessionStorage.setItem("type", "success");
    //   window.sessionStorage.setItem('message', data.message)
    MySwal.fire({
      icon: "success",
      title: "Duty added!",
      showConfirmButton: false,
      timer: 1000,
    });
    handleClose()
  };

// console.log(players)

  return (
    <>
      <button className="badge bg-success mx-2 p-2" onClick={handleShow}>
        <FaPlus /> Add Task
      </button>
      <span className="badge bg-secondary mx-2 p-2">
      
        <BiEnvelope /> Accepted
      </span>

      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddTask}>
          <Modal.Body>
            <Form.Group>
              <label className="mb-1">
                Task Name<span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="assignment"
                placeholder="Please enter the assignment name"
                required
              />
              <span className="text-danger">{error.assignment}</span>
            </Form.Group>
            <Form.Group>
              <label className="mb-1">
                Event<span className="text-danger"> *</span>
              </label>
              <select className="form-control" name="event_id" required>
                <option label="-Select-"></option>
                {events?.map((event, index) => {
                  return (
                    <option value={event.id} key={index}>
                      {event.title}
                    </option>
                  );
                })}
              </select>
            </Form.Group>
            <Form.Group>
              <label className="mb-1">
                Player<span className="text-danger"> *</span>
              </label>
              <select className="form-control" name="player_id">
                <option label="-Select-"></option>

                {players
                  ?.filter((player) => player !== null)
                  .map((player, index) => (
                    <option value={player?.id} key={index}>
                      {player?.first_name + " " + player?.last_name}
                    </option>
                  ))}
              </select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddDutyPopover;
