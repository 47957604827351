import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Image from '../../static/noimage.png';
import  userStore  from "../../Store.js";
const Tournaments = () => {

    const [tournaments, setTournaments] = React.useState([]);
    const [message, setMessage] = React.useState({});
    const [load, setLoad] = React.useState(true);
    const navigate = useNavigate();

    const { user } = userStore();

    React.useEffect(() => {
        let { type, message } = window.sessionStorage;
        if (type && message) {
            setMessage({ type, message });
            sessionStorage.clear()
        }
    }, [load])

    React.useEffect(() => {

        let url = Constants.tournaments;
        (async () => {
            let response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + userToken(),
                }
            })
            let data = await response.json();
            // console.log(data);
            if (data.status === 1) {
                setTournaments(data.response);
            }
        })()
    }, [])
    
    const minHeightStyle = {
        minHeight: "100vh",
      };

    return (
        <div className="col-lg-7" style={minHeightStyle} >
            <div className="middle-top-head my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="tophead-title">
                        <h2 className="mb-0"><span onClick={() => navigate(-1)} className="text-black cursor-pointer"><i className="fas fa-arrow-left"></i> Tournaments</span></h2>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>
                            <Link to="/tournaments/add" className="btn btn-marooon">Create Tournament <i className="fas fa-plus ms-2"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
                <div className="teamtab">
                    <ul className="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Organized</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" tabIndex="-1">Participated</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content p-3" id="pills-tabContent">
                    {/* <div className={`alert alert-${message.type}`} onClick={() => setMessage({})}>
                        {message.message}
                    </div> */}
                    <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="row">
                            {
                                tournaments?.map((tournament, index) => {
                                    if (user.id === tournament.user_id) {
                                        return (
                                            <div className="col-lg-4 col-md-6 mb-3" key={index}>
                                                <div className="card rounded shadow-sm teamcard h-100">
                                                    <div className="card-image">
                                                        <Link><img alt="Tournament" style={{ objectFit: 'cover' }} className="img-fluid" src={(tournament.logo_main) ? `/uploads/${tournament.icon}` : Image} /></Link>
                                                    </div>
                                                    <div className="card-body border-top">
                                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                                            <h4 className="tname fs-6 mb-0"><Link to={`/tournaments/view/${tournament.id}`} className="text-maroon">{tournament.tournamentname}</Link></h4>
                                                        </div>
                                                        <p className="fs-13 text-secondary"><i className="fas fa-map-marker-alt"></i> {tournament.location}</p>
                                                        <p className="fs-13 text-secondary">{tournament.game}</p>
                                                    </div>

                                                    <div className="p-2 row"><a className="btn btn-warning" href="/edit-team/20">Edit</a><span className="btn btn-danger mt-2">Delete</span></div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="row">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Tournaments;