import React from "react";
import { useNavigate } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Spinner from "../Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const CreateTeam = () => {
  const [success, setSuccess] = React.useState("");
  const [games, setGames] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [error, setError] = React.useState({});
  const imageRef = React.useRef();
  const autoRef = React.useRef();
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      let url = Constants.allGames;
      let response = await fetch(url);
      let data = await response.json();
      setGames(data.response);
    })();

    (async () => {
      let url = Constants.teamType;
      let response = await fetch(url);
      let data = await response.json();
      setType(data.response);
    })();

    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        setLatLon({ lat, lon });
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  const handleAddTeam = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData(e.target);
    let url = Constants.addTeam;

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    setLoading(false);
    // console.log(data);
    if (data.status !== 1) {
      setError(data.message);
      return;
    }
    e.target.reset();
    window.sessionStorage.setItem("type", "success");
    //window.sessionStorage.setItem("message", data.message);
     
    MySwal.fire({
      icon: "success",
      title: "Team created successfully!",
      showConfirmButton: true, 
      timer: 5000, 
      onAfterClose: () => {
        setSuccess(""); 
      },
    });

    navigate("/dashboard");
    


  };
  const handlePreview = (e) => {
    if (!e.target.files.length) {
      imageRef.current.src ='https://sportthon.com/assets/frontend/images/icons/noimage.png';
      return;
    }
    let file = e.target.files[0];
    let extension = e.target.files[0].type;
    if (!["image/jpeg", "image/png"].some((value) => value === extension)) {
      alert("Please upload file having extensions .jpeg/.jpg/.png/.gif only.");
      e.target.value = "";
      imageRef.current.src =
       'https://sportthon.com/assets/frontend/images/icons/noimage.png';
      return;
    }

    // console.log(file);
    let url = URL.createObjectURL(file);
    imageRef.current.src = url;
    imageRef.current.onload = function () {
      URL.revokeObjectURL(file);
    };
  };

  return (
    <div className="col-lg-7">
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span className="text-black" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left"></i> Add Team
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          <form onSubmit={handleAddTeam} encType="multipart/form-data">
            <h4 className="h5 text-maroon">Team Information:</h4>
            <div className="row">
              <div className="col-lg-4 order-lg-2">
                <figure className="group-dp text-center">
                  <div className="teamprofileimg text-center mb-3">
                    <img
                      id="profileIMG"
                      src={
                      '  https://sportthon.com/assets/frontend/images/icons/noimage.png'
                      }
                      alt="test"
                      ref={imageRef}
                    />
                  </div>
                  <label className="uploadprofileImg btn btn-sm">
                    <i className="fas fa-camera"></i> Upload Team Image
                    <input
                      type="file"
                      name="file"
                      onChange={handlePreview}
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                  </label>
                </figure>
                <span className="text-danger">{error?.file}</span>
              </div>
              <div className="col-lg-8 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Team Name<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="team_name"
                    required
                  />
                  <span className="text-danger">{error?.team_name}</span>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Select Sport<span className="text-danger"> *</span>
                  </label>
                  <select className="form-control " name="game_id" required>
                    <option value="">- Sport -</option>
                    {games?.map((game, index) => {
                      return (
                        <option key={index} value={game?.id}>
                          {game.game}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">{error?.game_id}</span>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Team Category<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id=""
                    name="team_type"
                    required
                  >
                    <option value="">- Team Category -</option>
                    {type?.map((typ, index) => {
                      return (
                        <option key={index} value={typ?.id}>
                          {typ?.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">{error?.team_type}</span>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Team Type<span className="text-danger"> *</span>
                  </label>
                  <select className="form-control" name="team_gender" required>
                    <option label="-- Team Type --"></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="co-ed">Mixed</option>
                  </select>
                  <span className="text-danger">{error?.team_gender}</span>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">Description</label>
                  <textarea
                    className="form-control"
                    name="team_desc"
                  ></textarea>
                  <span className="text-danger">{error?.team_desc}</span>
                </div>
              </div>
            </div>
            <hr />
            <h4 className="h5 text-maroon">Location:</h4>
            <div className="form-group mb-3">
              <label className="mb-1">
                Address<span className="text-danger"> *</span>
              </label>
              <div className="input-group ">
                <input
                  type="text"
                  ref={autoRef}
                  className="form-control"
                  name="team_address"
                />
                <span className="text-danger">{error?.team_address}</span>
                <div className="input-group-text  rounded-0">
                  <i className="fas fa-map-marker-alt text-maroon"></i>
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label className="mb-1">
                <input type="checkbox" name="status" /> Public Visibility
              </label>
            </div>
            <input
              type="hidden"
              value={latLon.lat}
              name="lat"
              onChange={(e) =>
                setLatLon((prev) => {
                  return { ...prev, lat: e.target.value };
                })
              }
            />
            <input
              type="hidden"
              value={latLon.lon}
              name="lon"
              onChange={(e) =>
                setLatLon((prev) => {
                  return { ...prev, lon: e.target.value };
                })
              }
            />
            <div className="form-group text-center">
              <button type="submit" className="btn-marooon">
                {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      borderWidth: "2px",
                      color: "#ffffff",
                    }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateTeam;
