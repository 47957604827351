import { Link } from "react-router-dom";
// import { userStore } from "../Store";
import { userToken } from "../Constants";
import React from "react";
import SuggestionList from "./Suggestions";

import Logo from "../static/logo.png";
import userStore from "../Store";

const HomeBanner = () => {
  const autoRef = React.useRef();
  const formRef = React.useRef();
  const [category, setCategory] = React.useState("");
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const [currentLocation, setCurrentLocation] = React.useState("");
  React.useEffect(() => {
    (async () => {
      let response = await fetch("https://ipapi.co/json");
      let data = await response.json();
      let current = `${data.city}, ${data.region}, ${data.country_name}`;
      let lat = data.latitude;
      let lon = data.longitude;
      setLatLon({ lat, lon });
      setCurrentLocation(current);
      autoRef.current.value = current;
    })();
  }, []);

  // React.useEffect(() => {
  // 	(async () => {

  // 		navigator.geolocation.getCurrentPosition( async (position) => {
  // 			let lat = position.coords.latitude;
  // 			let lon = position.coords.longitude;
  // 			let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyBBCrb9jMgk334tpDcleP0O-OXJ1iwkC0A`;
  // 			let response = await fetch(url)
  // 			let data = await response.json();

  // 			if(data?.results && data?.results.length > 0) {
  // 				autoRef.current.value = data.results.at(0).formatted_address
  // 			}
  // 			setLatLon({ lat, lon });
  // 		});

  // 	})()
  // }, [])

  React.useEffect(() => {
    if (window.google?.maps?.places?.Autocomplete) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        autoRef.current
      );
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();

          setLatLon({ lat, lon });
        }
      );
    }
  }, [window.google?.maps?.places?.Autocomplete]);
  React.useEffect(() => {
    if (category !== "") {
      formRef.current.submit();
    }
  }, [category]);

  return (
    <section
      className="home-banner"
      style={{ backgroundImage: "url(assets/frontend/images/home-bg.jpg)" }}
    >
      <div className="container">
        <div className="banner-content">
          {/* <h1 className="text-center text-white fs-1 fw-bold">SPORTS BUILD NATIONS!!</h1>
					<div className="rw-words rw-words-1 text-center mt-3">
						<span>Water POLO -  Italian Men's team and USA women's team have won the 2019 FINA World water polo Championships</span>
						<span>RUGBY - South Africa won the Rugby 2019 world cup final against England</span>
						<span>SOCCER -  Team USA wins 2019 FIFA world cup women's final </span>
						<span>TENNIS - Novak Djokovic Wins Fifth Wimbledon Title By Beating Roger Federer</span>
						<span>SNOOKER -  Judd Trump Claims International Championship title for a second time</span>
						<span>CRICKET - England Team  has won the 12th Edition of the ICC cricket World cup </span>
					</div> */}

          <img className="img-fluid px-4" src={Logo} alt={`banner`} />

          <form action="/search" method="GET" ref={formRef}>
            <div className="bannerSearch d-flex justify-content-center mt-4 align-items-center">
              <div className="typetextpnl position-relative">
                <input
                  type="text"
                  name="keyword"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Find Players, Teams, Tournaments, Leagues, Groups, Companies.."
                  required
                  minLength={3}
                />
                <SuggestionList setCategory={setCategory} />
                <input
                  type="hidden"
                  name="category"
                  value={category}
                  onChange={(e) => e.target.value}
                />
              </div>
              <div className="typelocation position-relative">
                <input
                  type="text"
                  name="location"
                  className="form-control"
                  placeholder="Location.."
                  ref={autoRef}
                />
                <span
                  onClick={() => {
                    autoRef.current.value = "";
                    setLatLon({ lat: "", lon: "" });
                  }}
                  className="removelocation"
                >
                  <i className="fas fa-times"></i>
                </span>
                <span className="typeicon">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
              </div>

              <div className="bannersrchbtn">
                <input
                  type="hidden"
                  value={latLon.lat}
                  name="lat"
                  onChange={(e) =>
                    setLatLon((prev) => {
                      return { ...prev, lat: e.target.value };
                    })
                  }
                />
                <input
                  type="hidden"
                  value={latLon.lon}
                  name="lon"
                  onChange={(e) =>
                    setLatLon((prev) => {
                      return { ...prev, lon: e.target.value };
                    })
                  }
                />
                <button className="btn">{"Search"}</button>
              </div>
            </div>
          </form>
          {!userToken() ? (
            <div className="loginsignup-btnlist text-center mt-4">
              <div className="btn-group">
                <Link className="btn" to="/login">
                  Login
                </Link>
                <span className="btn btnimg">
                  <img
                    alt="running.png"
                    src={`${process.env.PUBLIC_URL}/assets/frontend/images/running.png`}
                  />
                </span>
                <Link className="btn" to="/register">
                  Signup
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="playvideo text-center mt-3">
            <span
              className="playvideoicon"
              data-bs-toggle="modal"
              data-bs-target="#videoModal"
            >
              <i className="fas fa-play-circle"></i>
            </span>
          </div>
        </div>
      </div>

      <div
        className="modal fade videoppopup"
        id="videoModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/x98qQTAYqYo?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeBanner;
