import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Moments = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const textAreaRef = useRef();

  const [data, setData] = React.useState([]);
  const [load, setLoad] = useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const createTmpImage = (url) => {
    let div = document.createElement("div");
    div.style.backgroundImage = `url(${url})`;
    div.classList.add("post-img-preview");

    return div;
  };
  const handleImageUpload = async (e) => {
    let file = e.target.files[0];

    let tmpImage = URL.createObjectURL(file);
    let imgPrev = createTmpImage(tmpImage);
    textAreaRef.current.prepend(imgPrev);

    let formData = new FormData();
    formData.append("file", file);
    let response = await fetch(Constants.fileUp, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) return;
    let data = await response.json();

    console.log(data);
    let img = document.createElement("img");
    img.src = "/uploads/post_images/" + data.photo;

    textAreaRef.current.removeChild(imgPrev);
    textAreaRef.current.prepend(img);
  };

  React.useEffect(() => {
    (async () => {
      let url = Constants.getMoments + id;
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      //   console.log(data);
      if (data.status === 1) {
        setData(data.response);
        setIsLoading(false);
      }
    })();
  }, [load]);
  console.log(data)

  const handleSubmit = async (e) => {
    e.preventDefault();
    let htmldata = textAreaRef.current.innerHTML;
    if (!htmldata) {
      Swal.fire({
        icon: "error",
        title: "Empty Post",
        text: "Please enter some content before posting.",
      });
      return;
    }
    let formData = new FormData(e.target);
    
    formData.append("ctext", htmldata);
    let url = Constants.uploadMoments + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    if (data.status === 1) {
      Swal.fire({
        icon: "success",
        title: "Post Uploaded!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    console.log(data);
    textAreaRef.current.innerHTML = "";
    setLoad({});
  };
  
    const handleDeleteMoment = async (activityId) => {
      try {
      
        const response = await fetch(`${Constants.deleteMoments}${activityId}`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
  
        if (response.ok) {
         
          setLoad({});
       
          MySwal.fire({
            icon: "success",
            title: "Moment Deleted!",
          });
        } else {
          throw new Error("Failed to delete moment");
        }
      } catch (error) {
        console.error(error);
     
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to delete moment",
        });
      }
    };

  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span onClick={() => navigate(-1)} className="text-black">
                <i className="fas fa-arrow-left"></i> Moments
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <form className="addpost border d-flex" onSubmit={handleSubmit}>
          <div className="postuserimg">
            <img
              className="img-fluid"
              src="/assets/frontend/images/icons/moment.png"
              alt="moment.png"
            />
          </div>
          <div className="add-post-box flex-fill">
            <div
              ref={textAreaRef}
              contentEditable="true"
              className="post-typearea custom-textarea"
              placeholder="Say hello to players"
            ></div>
            <div className="d-flex justify-content-between">
              <ul className="postbtnlist">
                <li>
                  <label className="btn">
                    <i className="fas fa-images text-success"></i> Photo
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif, .webp"
                      name=""
                      onChange={handleImageUpload}
                    />
                  </label>
                </li>
              </ul>

              <button className="btn btn-marooon btn-sm my-3">Post</button>
            </div>
          </div>
        </form>
        <div
          className="scrollable-container"
          style={{ maxHeight: "450px", overflowY: "auto" }}
        >
          {isLoading ? (
            <div className="text-center">
              <div
                className="spinner-border text-secondary"
                style={{
                  width: "3rem",
                  height: "3rem",
                  position: "fixed",
                  top: "50%",
                  left: "42%",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="mainwrapper-post border">
              {data?.map((post, index) => (
                <div className="user-post" key={index}>
                  <div className="friend-info d-flex align-items-center mb-3 justify-content-between">
                    <div className="d-flex align-items-center">
                      {/* <div dangerouslySetInnerHTML={{ __html: post.ctext }} /> */}
                      <div className="friend-name">
                        <h5>
                          <i className="fa fa-globe"></i> Published:
                          {new Date(post.createdDate).toLocaleString()}
                        </h5>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button
                        className="post-toggle dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span className="dropdown-item">Report</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-meta">
                    <div dangerouslySetInnerHTML={{ __html: post.ctext }} />
                    <div className="clearfix"></div>
                    <div className="stat-tools">
                      <ul className="toolslist">
                        <li>
                          <span className="btnstools active">
                            <i className="fa fa-thumbs-up"></i> Like (2)
                          </span>
                        </li>
                        <li>
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#postModal"
                            className="btnstools"
                          >
                            <i className="fa fa-comment"></i> Comment (2)
                          </span>
                        </li>
                        <li className="sharebtnpost">
                          <span className="btnstools">
                            <i className="fa fa-share-square"></i> Share
                          </span>

                          <div className="card p-2 postshare">
                            <div className="d-flex flex-warp">
                              <div className="d-flex">
                                <a className="btn btn-link text-secondary">
                                  <i className="fa fa-copy me-2 false"></i>
                                </a>
                                <button
                                  className="share__ShareButton me-2 rounded-circle  border-0"
                                  style={{ backgroundColor: "#25D366" }}
                                >
                                  <i className="fab fa-whatsapp text-white"></i>
                                </button>
                                <button
                                  className="share__ShareButton me-2 rounded-circle  border-0"
                                  style={{ backgroundColor: "#3b5998" }}
                                >
                                  <i className="fab fa-facebook-f text-white"></i>
                                </button>
                                <button
                                  className="share__ShareButton me-2 rounded-circle  border-0"
                                  style={{ backgroundColor: "#00aced" }}
                                >
                                  <i className="fab fa-twitter text-white"></i>
                                </button>
                                <button
                                  className="share__ShareButton me-2 rounded-circle  border-0"
                                  style={{ backgroundColor: "#f62384" }}
                                >
                                  <i className="fab fa-instagram text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <span className="btnstools btn btn-sm"  onClick={() => handleDeleteMoment(post.id)}>
                            <AiFillDelete className="text-danger" /> Delete
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Moments;
