import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import userStore  from "../../Store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as XLSX from "xlsx";

import {
  BsFileEarmarkArrowDown,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkWordFill,
} from "react-icons/bs";

import jsPDF from "jspdf";

import { RiFileExcel2Fill } from "react-icons/ri";
import { generateDOCX } from "../../utils/generate-docx";
const MySwal = withReactContent(Swal);

const getTeamRole = (user) => {
  let names = {
    is_admin: "Admin",
    is_manager: "Manager",
    is_non_playing_member: "Non playing member",
  };
  for (let role in names) {
    if (parseInt(user[role])) {
      return names[role];
    }
  }
  return "Player";
};

const TeamMembers = () => {
  const [message, setMessage] = React.useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = userStore();
  const [players, setPlayers] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [team, setTeam] = React.useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [requestedPlayers, setRequestedPlayers] = useState([]);
  const [reloadComponent, setReloadComponent] = React.useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    if (option === "Option 1") {
      generatePDF();
    } else if (option === "Option 2") {
      generateExcel();
    } else if (option === "Option 3") {
      generateWordDoc();
    } else {
    }
    setShowDropdown(false);
  };

  //console.log(players);

  React.useEffect(() => {
    let url = Constants.listTeamPlayer + id;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data.response);
      if (data.status === 1) {
        setTeam(data.response.team);
        setPlayers(data.response.players);
        setLoad(false);
      }
    })();
    //console.log("players are" ,players);
    // console.log("Teams : ", team);
  }, [id, load, reloadComponent]);

  React.useEffect(() => {
    let requestedPlayerUrl = Constants.requestedPlayerList;
    let formData = new FormData();
    formData.append("teamId", id);

    (async () => {
      let response = await fetch(requestedPlayerUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      if (response.status === 200) {
        let data = await response.json();
        if (data.status === 1) {
          setRequestedPlayers(data.payload);
        }
      }
    })();
  }, [id, reloadComponent]);
  // console.log("payers", players)
  // console.log("requested players",requestedPlayers)

  const handleAccept = async (id) => {
    const acceptRequestUrl = Constants.playerAcceptRequest;
    const formData = new FormData();
    formData.append("requestId", id);

    try {
      const response = await fetch(acceptRequestUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Player Accepted!",
          showConfirmButton: false,
          timer: 1500,
        });
        setReloadComponent((prevReload) => !prevReload);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please try again.",
        });
      }
    } catch (error) {
      console.error("Error accepting player request:", error);
    }
  };

  const handleReject = async (requestedPlayerId) => {
    const rejectRequestUrl = Constants.playerRejectRequest;
    const formData = new FormData();
    formData.append("requestId", requestedPlayerId);

    try {
      const response = await fetch(rejectRequestUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
        body: formData,
      });

      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Player Rejected!",
          showConfirmButton: false,
          timer: 1500,
        });

        setReloadComponent((prevReload) => !prevReload);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please try again.",
        });
      }
    } catch (error) {
      console.error("Error rejecting player request:", error);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const teamName = team.team_name;
    doc.text(`Team Name: ${teamName}`, 10, 10);

    doc.text("PLAYER NAME & CONTACT INFORMATION :", 10, 20);
    let y = 30;
    players.forEach((player, index) => {
      const { first_name, last_name, mail } = player;
      const playerInfo = `${first_name} ${last_name} ${" "} ${"-"} ${" "} ${mail}`;
      doc.text(playerInfo, 10, y);
      y += 10;
    });
    doc.save(`${teamName}.pdf`);
  };
  const generateWordDoc = () => {
    generateDOCX(players);
  };
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(getExcelData());
    XLSX.utils.book_append_sheet(wb, ws, "Team Info");
    XLSX.writeFile(wb, "TeamInfo.xlsx");
  };

  const getExcelData = () => {
    const excelData = [];
    excelData.push(["Team Name", "Player Name", "Contact Information"]);
    excelData.push([team.team_name]);
    players.forEach((player) => {
      const { first_name, last_name, mail } = player;
      excelData.push([`${first_name} ${last_name}`, mail]);
    });
    return excelData;
  };

  const handleDelete = async (id) => {
    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you really want to delete this player?",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        let url = Constants.deleteTeamPlayers + id;
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        let data = await response.json();

        if (data.status === 1) {
          MySwal.fire({
            icon: "success",
            title: "Player deleted!",
            showConfirmButton: false,
            timer: 1500,
          });

          setPlayers((prevPlayers) =>
            prevPlayers.filter((player) => player.id !== id)
          );
        } else {
          MySwal.fire({
            icon: "error",
            title: "Error deleting player",
            text: "An error occurred while deleting the player.",
          });
        }
      }
    } catch (error) {
      console.error("Error deleting player:", error);
    }
  };

  const handleInvitition = async (id) => {
    let url = Constants.resendTeamPlayer + id;
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
    });
    let data = await response.json();
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Player invitation successfull!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setMessage({ type: "danger", message: data.message });
    }
  };
  const handleUnjoin = async (id) => {
    try {
      if (user.id === team.team_creator) {
        const confirmResult = await Swal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: "Make sure you transfer Admin role to other player before exit",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, unjoin",
          cancelButtonText: "Cancel",
        });

        if (confirmResult.isConfirmed) {
          let url = Constants.deleteTeamPlayers + id;
          let response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + userToken(),
            },
          });
          let data = await response.json();

          if (data.status === 1) {
            MySwal.fire({
              icon: "success",
              title: "Unjoin from team!",
              showConfirmButton: false,
              timer: 1500,
            });
            navigate("/dashboard");
            setPlayers((prevPlayers) =>
              prevPlayers.filter((player) => player.id !== id)
            );
          } else {
            MySwal.fire({
              icon: "error",
              title: "Error Unjoining",
              text: "An error occurred while unjoining the player.",
            });
          }
        }
      } else {
        const confirmResult = await Swal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: "Do you really want to unjoin from the team?",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, unjoin",
          cancelButtonText: "Cancel",
        });

        if (confirmResult.isConfirmed) {
          let url = Constants.deleteTeamPlayers + id;
          let response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + userToken(),
            },
          });
          let data = await response.json();

          if (data.status === 1) {
            MySwal.fire({
              icon: "success",
              title: "Unjoin from team!",
              showConfirmButton: false,
              timer: 1500,
            });
            navigate("/dashboard");
            setPlayers((prevPlayers) =>
              prevPlayers.filter((player) => player.id !== id)
            );
          } else {
            MySwal.fire({
              icon: "error",
              title: "Error Unjoining",
              text: "An error occurred while unjoining the player.",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error in unjoining:", error);
    }
  };
  const joinedPlayers = players.filter(
    (players) => players.is_non_playing_member === "0"
  );

  const nonPlayingMembers = players.filter(
    (players) => players.is_non_playing_member === "1"
  );
  // const notJoinedPlayers = players.filter(
  //   (players) => players.has_joined === "0"
  // );
  //console.log(players);
  const minHeightStyle = {
    minHeight: "100vh",
  };
  //  console.log(players)
// console.log(user, players)

  return (
    <>
      {load ? (
        <div className="text-center" style={{ minHeight: "80vh" }}>
          <div
            className="spinner-border text-secondary"
            style={{
              width: "4rem",
              height: "4rem",
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="col-lg-7" style={minHeightStyle}>
          <div className="middle-top-head my-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tophead-title">
                <h2 className="mb-0">
                  <Link onClick={() => navigate(-1)} className="text-black">
                    <i className="fas fa-arrow-left"></i>
                    {team?.team_name}
                  </Link>
                </h2>
              </div>
              <div className="d-flex align-items-center">
                {user?.id === team?.team_creator ||
                players.some((player) => player?.is_manager === "1") ? (
                  <div>
                    <Link to={`/add-players/${id}`} className="btn btn-marooon">
                      Add Players<i className="fas fa-plus ms-2"></i>
                    </Link>
                  </div>
                ) : (
                  ""
                )}

                <div className="dropdown">
                  <BsFileEarmarkArrowDown
                    onClick={toggleDropdown}
                    type="button"
                    data-bs-toggle="dropdown"
                    className="text-xl text-maroon option-icon mx-1"
                    title="Export Players"
                  />

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <button
                        className="btn btn-lg dropdown-item "
                        onClick={() => handleOptionClick("Option 1")}
                      >
                        <BsFillFileEarmarkPdfFill className="blue-icon" />
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleOptionClick("Option 2")}
                      >
                        <RiFileExcel2Fill className="excel-icon" />
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleOptionClick("Option 3")}
                      >
                        <BsFillFileEarmarkWordFill className="word-icon" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
            <div className={`alert alert-${message.type}`}>
              {message.message}
            </div>

            <table className="table playerlist-table">
              <thead>
                <tr className="bg-maroon text-light">
                  <th></th>
                  <th>Player</th>
                  <th>Role</th>
                  <th>Contact Info</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {joinedPlayers?.map((player, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="plyerlistImg pe-2">
                            {player?.player_image ? (
                              <img
                                src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${player.player_image}`}
                                alt="Profile Avatar"
                              />
                            ) : (
                              <span className="img-gravatar">
                                {player?.first_name?.[0]}
                                {player?.last_name?.[0]}
                              </span>
                            )}
                          </div>
                          <div>
                            {player?.player_id ? (
                              <strong>
                                <Link
                                 to={`/player/profile/${player?.player_id}/${player?.first_name+player?.last_name}`}
                                >
                                  {player?.first_name} {player?.last_name}
                                </Link>
                              </strong>
                            ) : (
                              <strong>
                                <Link to={`/noplayer`}>
                                  {player?.first_name} {player?.last_name}
                                </Link>
                              </strong>
                            )}
                            {user?.id === player?.player_id && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger m-2"
                                onClick={() => handleUnjoin(player?.id)}
                              >
                                Unjoin
                              </button>
                            )}
                            {player?.has_joined !== "1" ? (
                              <>
                                {" "}
                                <div>Invitation Pending</div>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleInvitition(player?.id)}
                                >
                                 Resend Invitation
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="badge bg-success">
                          {getTeamRole(player)}
                        </span>
                      </td>
                      <td>{player.mail}</td>
                      <td>
                        {/* ||  player.is_admin === "1" */}
                        {user?.id === team?.team_creator ? (
                          <Link
                            className="fa fa-edit text-info"
                            title="Edit Player"
                            to={`/edit-players/${team?.id}/${player?.id}`}
                          ></Link>
                        ) : (
                          ""
                        )}
                        {/* ||  player.is_admin === "1" */}
                        {user?.id === team?.team_creator ? (
                          <span
                            className="fa fa-trash-alt text-danger ms-2 cursor-pointer"
                            title="Delete Player"
                            onClick={() => handleDelete(player.id)}
                          ></span>
                        ) : (
                          ""
                        )}

                        {user?.id !== player?.player_id &&
                        player?.player_id !== team?.team_creator ? (
                          <></>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}

                <tr className="bg-maroon text-light">
                  <th colSpan={4}>Non Player</th>
                  {/* <th></th> */}
                  <th></th>
                </tr>

                {nonPlayingMembers?.map((player, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="plyerlistImg pe-2">
                            {player?.player_image ? (
                              <img
                                src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/profile_pictures/${player?.player_image}`}
                                alt="Profile Avatar"
                              />
                            ) : (
                              <span className="img-gravatar">
                                {player?.first_name?.[0]}
                                {player?.last_name?.[0]}
                              </span>
                            )}
                          </div>
                          <div>
                            {player?.player_id ? (
                              <strong>
                                <Link
                                    to={`/player/profile/${player?.player_id}/${player?.first_name+player?.last_name}`}
                                >
                                  {player?.first_name} {player?.last_name}
                                </Link>
                              </strong>
                            ) : (
                              <strong>
                                <Link to={`/noplayer`}>
                                  {player?.first_name} {player?.last_name}
                                </Link>
                              </strong>
                            )}
                            {user?.id === player?.player_id && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger m-2"
                                onClick={() => handleUnjoin(player?.id)}
                              >
                                Unjoin
                              </button>
                            )}
                            {player?.has_joined !== "1" ? (
                              <>
                                <div>Invitation Pending</div>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleInvitition(player?.id)}
                                >
                                   Resend Invitation
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="badge bg-success">
                          {getTeamRole(player)}
                        </span>
                      </td>
                      <td>{player.mail}</td>
                      <td>
                        {user?.id === team?.team_creator ? (
                          <Link
                            className="fa fa-edit text-info"
                            title="Edit Player"
                            to={`/edit-players/${team?.id}/${player?.id}`}
                          ></Link>
                        ) : (
                          ""
                        )}

                        {user?.id === team?.team_creator ? (
                          <span
                            className="fa fa-trash-alt text-danger ms-2 cursor-pointer"
                            title="Delete Player"
                            onClick={() => handleDelete(player?.id)}
                          ></span>
                        ) : (
                          ""
                        )}

                        {user?.id !== player?.player_id &&
                        player?.player_id !== team?.team_creator ? (
                          <></>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
                 

                 {user?.id === team?.team_creator && (
                    <>
                    <tr className="bg-maroon text-light">
                      <th colSpan={4}>Requested Player</th>
                      <th></th>
                    </tr>
                    {requestedPlayers.map((requestedPlayer) => (
                      <tr key={requestedPlayer.id}>
                        <td></td>
                        <td>
                          <strong>{`${requestedPlayer.first_name} ${requestedPlayer.last_name}`}</strong>
                        </td>
                        <td>
                          <span className="badge bg-success">
                            {getTeamRole(requestedPlayer)}
                          </span>
                        </td>
                        <td>{requestedPlayer.mail}</td>
                        <td>
                          {user?.id === team?.team_creator && (
                            <>
                              <button
                                className="btn btn-sm btn-success mx-1"
                                title="Accept"
                                onClick={() => handleAccept(requestedPlayer.value_id)}
                              >
                                <i className="fas fa-check"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-danger"
                                title="Reject"
                                onClick={() => handleReject(requestedPlayer.value_id)}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                 )}
              
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
export default TeamMembers;
