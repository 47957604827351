const prefix = "https://api.sportthon.com/api/";

export const Constants = {
  globalSearch: prefix + "api/global-search",
  generalSettings: prefix + "api/general-settings",
  submitContact: prefix + "api/submit-contact",
  homeCms: prefix + "api/cms",
  partners: prefix + "api/partners",
  homeTestimonials: prefix + "api/testimonials",
  homeFaqs: prefix + "api/faq",
  register: prefix + "api/register",
  login: prefix + "api/login",
  socialLogin: prefix + "api/social-login",
  forgetPassword: prefix + "api/forget-password",
  checkToken: prefix + "api/check-password-reset-token",
  resetPassword: prefix + "api/reset-password",
  currentUser: prefix + "api/isuser",
  allGames: prefix + "api/listgames",
  listCountry: prefix + "api/listcountry",
  teamType: prefix + "api/teamtype",
  profileImage: prefix + "api/update-profile-image",
  profileInfo: prefix + "api/update-profile-info",
  gameInfo: prefix + "api/upldate-games-info",
  verifyUser: prefix + "api/verify-user",
  addTeam: prefix + "api/add-teams",
  listTeam: prefix + "api/list-teams",
  editTeams: prefix + "api/edit-teams/",
  deleteTeams: prefix + "api/delete-teams/",
  updateTeams: prefix + "api/update-teams/",
  addTeamPlayer: prefix + "api/add-player/",
  listTeamPlayer: prefix + "api/list-player/",
  editTeamPlayer: prefix + "api/edit-player/",
  updateTeamPlayer: prefix + "api/update-player/",
  deleteTeamPlayers: prefix + "api/delete-player/",
  pendingTeamPlayer: prefix + "api/team-request/",
  acceptTeamPlayer: prefix + "api/accept-invitition/",
  rejectTeamPlayer: prefix + "api/reject-invitition/",
  resendTeamPlayer: prefix + "api/reset-invitition/",
  acceptMailInvitation: prefix + "api/accept-mail-invitition/",
  getPlayerInformation: prefix + "api/find-players",
  teamEvents: prefix + "api/team-events/",
  deleteEvent: prefix + "api/team-events/delete",
  //all events
  userEvents: prefix + "api/getUserEvents",
  //Chat counts
  temChatCount: prefix +"v2/myAllTeams",

  editTeamEvents: prefix + "api/editTeamEvent",
  checkTeamPlayerExists: prefix + "api/teams/has-player/",
  resendEventInvititation: prefix + "api/resent-team-invitation",
  addTeamEvents: prefix + "api/team-events/add/",
  eventAvailibility: prefix + "api/event-availibility",
  markPlayer: prefix + "api/team-events/mark-player/",
  checkAvailablePlayers: prefix + "api/team-events/check-event-availibility/",
  teamTaskDetails: prefix + "api/team-task/details/",
  addTeamTask: prefix + "api/team-task/add/",
  teamTask: prefix + "api/team-task/",
  editTeamTask: prefix + "api/team-task/edit/",
  updateTeamTask: prefix + "api/team-task/update/",
  deleteTeamTask: prefix + "api/team-task/delete/",
  teamChats: prefix + "api/team-chats/",
  getTeamChats: prefix + "api/team-chats/chats/",
  sendTeamChats: prefix + "api/team-chats/send/",
  tournaments: prefix + "api/tournaments",
  tournamentsOTPSend: prefix + "api/tournaments/otp-generate",
  tournamentsOTPVerify: prefix + "api/tournaments/otp-verify",
  addTournaments: prefix + "api/tournaments/add",
  viewTournaments: prefix + "api/tournaments/view/",
  accountSettings: prefix + "api/account-settings",
  updateAccountSettings: prefix + "api/account-settings/update",
  sendNotifications: prefix + "api/notifications/",
  getOtherUsers: prefix + "api/other-users",
  followUsers: prefix + "api/follow/",
  unFollowUsers: prefix + "api/unFollow/",
  followTeams: prefix + "api/follow/team/",
  following: prefix + "api/followings",
  followers: prefix + "api/followers",

  inviteUser: prefix + "api/invite",
  //documents
  documentUpload: prefix + "api/document/upload/",
  documentList: prefix + "api/document/list/",
  deleteDocument: prefix + "api/document/delete/",

  //join  team request
  joinTeamRequest: prefix + "api/joinrequest",
  requestedPlayerList: prefix + "api/teamJoinRequests",
  playerAcceptRequest: prefix + "api/playerAcceptRequest",
  playerRejectRequest: prefix + "api/teamDeclineRequests",

  getMoments: prefix + "api/activities/",
  deleteMoments: prefix + "api/activities/delete/",
  uploadMoments: prefix + "api/activities/create/",
  playerProfile: prefix + "api/player/profile/",
  teamProfile: prefix + "api/team/profile/",
  fileUp: prefix + "api/file-up",
  blogs: prefix + "api/blogs/get/",
  blogDetails: prefix + "api/blogs/details/",
  submitBlogDetails: prefix + "api/blogs/comment/",
  featuresList: prefix + "api/listFeatures",
};

// https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyBBCrb9jMgk334tpDcleP0O-OXJ1iwkC0A&input=

export function set_cookie(name, value) {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + value + "; expires=" + d.toUTCString() + "; Path=/;";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function remove_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const userToken = () => {
  let cookie = getCookie("_token");
  return cookie;
};

export const removeToken = () => {
  let cookie = remove_cookie("_token");
  return cookie;
};
