import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

const EditTeamSchedules = () => {
  const autoRef = React.useRef();
  const navigate = useNavigate();
  const { id, eventId } = useParams();

  const [error, setError] = React.useState({});
  const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 });
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [eventStartDate, setEventStartDate] = React.useState(new Date());
  const [eventEndDate, setEventEndDate] = React.useState(new Date());
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const dayref = useRef();
  const hoursRef = useRef();
  const minutesRef = useRef();
  const desRef = useRef();
  const [eventData, setEventData] = React.useState(null);

  React.useEffect(() => {
    async function fetchEventData() {
      try {
        const response = await fetch(Constants.teamEvents + `${id}`, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        const data = await response.json();
        if (data.status === 1) {
          const eventData = data.response.events.find(
            (item) => item.id === eventId
          );
          setEventData(eventData);
        } else {
          setError(data.message);
        }
        // console.log(data)
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    }

    fetchEventData();
  }, [id]);

  React.useEffect(() => {
    if (eventData) {
      const {
        title,
        event_date,
        event_time,
        repeats,
        duration,
        location,
        location_details,
        latitude,
        longitude,
        description,
      } = eventData;
      console.log("Event Date:", event_date);
      console.log("Event Time:", event_time);

      document.querySelector('[name="eventName"]').value = title;
      document.querySelector('[name="repeats"]').value = repeats;
      const durationParts = duration.split(" ");
      document.querySelector('[name="dh"]').value = durationParts[0];
      // document.querySelector('[name="dmnt"]').value =
      //   durationParts[1].split(":")[1];

      autoRef.current.value = location;
      document.querySelector('[name="locationDetails"]').value =
        location_details;
      setLatLon({ lat: latitude, lon: longitude });

      document.querySelector('[name="description"]').value = description;
      desRef.current.value = description;
    }
  }, [eventData]);
  useEffect(() => {
    if (eventData) {
      const { event_date, event_time } = eventData;
      setEventStartDate(moment(event_date + "T" + event_time).toDate());
      setEventEndDate(moment(event_date + "T" + event_time).toDate());
      calculateDuration(eventStartDate, eventEndDate);
    }
  }, [eventData]);
  // Handle start date change
  const handleStartDateChange = (date) => {
    setEventStartDate(date);
    calculateDuration(eventStartDate, eventEndDate);
  };

  // Handle end date change
  const handleEndDateChange = (date) => {
    setEventEndDate(date);
    calculateDuration(eventStartDate, eventEndDate);
  };
  const formFormatter = (formData) => {
    let event_time = `${hours || "00"}:${minutes || "00"}`;
    formData.append(`time`, event_time);

    let duration = `${formData.get("repeats") || "0"} ${
      formData.get("dh") || "00"
    }:${formData.get("dmnt")}`;
    formData.append(`duration`, duration);
    formData.append("teamId", id);
    formData.append("eventId", eventId);
    formData.append("date", formData.get("startDate"));

    return formData;
  };

  const calculateDuration = (startDate, endDate) => {
    if (startDate && endDate) {
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      const duration = moment.duration(endMoment.diff(startMoment));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      dayref.current.value = days;
      hoursRef.current.value = hours;
      minutesRef.current.value = minutes;
      setHours(startMoment.hours());
      setMinutes(startMoment.minutes());
      setHours(startMoment.hours());
      setMinutes(startMoment.minutes());
    }
  };

  React.useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        setLatLon({ lat, lon });
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  const editTeamEvents = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url = Constants.editTeamEvents;
    let formData = formFormatter(new FormData(e.target));
    formData.delete("startDate");
    formData.delete("endDate");
    formData.delete("dh");
    formData.delete("dmnt");
    formData.delete("duration");
    formData.append("start_date", eventStartDate.toISOString());
    formData.append("end_date", eventEndDate.toISOString());

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    setLoading(false);
    if (data.status !== 1) {
      setError(data.message);
      return;
    }
    e.target.reset();
    window.sessionStorage.setItem("type", "success");
    //window.sessionStorage.setItem("message", data.message);
    navigate("/team/schedules/" + id);
    MySwal.fire({
      icon: "success",
      title: "Event updated successfully!",
      showConfirmButton: true,
      timer: 5000,
      onAfterClose: () => {
        setSuccess("");
      },
    });
  };

  return (
    <div className="col-lg-7">
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span
                onClick={() => navigate(-1)}
                className="text-black cursor-pointer"
              >
                <i className="fas fa-arrow-left"></i> Back
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          <form onSubmit={editTeamEvents} encType="multipart/form-data">
            <h4 className="h5 text-maroon">Event Details Edit:</h4>
            <div className="row">
              <div className="row">
                <div className="col-lg-6 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      Event Name<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="eventName"
                      required
                      placeholder="Please enter the event name"
                    />
                    {/* <span className="text-danger">{error.title}</span> */}
                  </div>
                </div>
                {/* <div className="col-lg-6 order-lg-2">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      Events Type<span className="text-danger"> *</span>
                    </label>
                    <select className="form-control" name="event_type" required>
                      <option value="" hidden>
                        --Select--
                      </option>
                      <option value="game">Game</option>
                      <option value="social">Social</option>
                      <option value="training">Training</option>
                      <option value="meeting">Meeting</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6 order-lg-1">
                  <div className="form-group row timechange">
                    <label className="mb-1">
                      Start Date<span className="text-danger"> *</span>
                    </label>
                    <DatePicker
                      selected={eventStartDate}
                      onChange={handleStartDateChange}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2">
                  <div className="form-group row timechange">
                    <label className="mb-1">
                      End Date<span className="text-danger"> *</span>
                    </label>
                    <DatePicker
                      selected={eventEndDate}
                      onChange={handleEndDateChange}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 order-lg-1">
                <div className="form-group row timechange">
                  <label className="mb-1">
                    Duration<span className="text-danger"> *</span>
                  </label>
                  <div className="col-md-2 p-right0">
                    <input
                      className="form-control hh"
                      size="2"
                      maxLength="2"
                      placeholder="Day"
                      type="text"
                      name="repeats"
                      required
                      readOnly
                      ref={dayref}
                    />
                    <label htmlFor="repeats">Day(s)</label>
                  </div>
                  <div className="col-md-2 p-right0">
                    <input
                      className="form-control hh"
                      size="2"
                      maxLength="2"
                      placeholder="HH"
                      type="text"
                      name="dh"
                      required
                      readOnly
                      ref={hoursRef}
                    />
                    <label htmlFor="dh">Hour(s)</label>
                  </div>
                  <div className="col-md-2 p-left0">
                    <input
                      className="form-control"
                      size="2"
                      maxLength="2"
                      placeholder="MM"
                      type="text"
                      name="dmnt"
                      required
                      readOnly
                      ref={minutesRef}
                    />
                    <label htmlFor="dmnt">Minute(s)</label>
                  </div>
                </div>
                {/* <span className="text-danger">{error.duration}</span> */}
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Venue<span className="text-danger"> *</span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className="form-control"
                      ref={autoRef}
                      name="location"
                      required
                    />
                  </div>
                  {/* <span className="text-danger">{error.location}</span> */}
                </div>
              </div>

              {/* <div className="col-lg-12 order-lg-1">
                                <div className="form-group row timechange">
                                    <div className="col-md-2 p-right0">
                                        <input className="form-control hh" size="2" maxLength="2" placeholder="HH" type="text" name="notification_before"/>
                                    </div>
                                    <div className="col-md-7">
                                        <span className="tme">Hours before game</span>
                                    </div>
                                </div>
                                <span className="text-danger">{error.notification_before}</span>
                            </div> */}

              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">Description</label>
                  <textarea
                    className="form-control"
                    cols="60"
                    rows="10"
                    name="locationDetails"
                    ref={desRef}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <input
                type="hidden"
                value={latLon.lat}
                name="latitude"
                onChange={(e) =>
                  setLatLon((prev) => {
                    return { ...prev, lat: e.target.value };
                  })
                }
              />
              <input
                type="hidden"
                value={latLon.lon}
                name="longitude"
                onChange={(e) =>
                  setLatLon((prev) => {
                    return { ...prev, lon: e.target.value };
                  })
                }
              />
              <button type="submit" className="btn-marooon text-uppercase">
                {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      borderWidth: "2px",
                    }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditTeamSchedules;
