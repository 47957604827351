import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import  userStore  from "../../Store";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShimmerPlaceholder from "./ShimmerPlaceholder";

const MySwal = withReactContent(Swal);

const UpcomingEvents = () => {
  const { id } = useParams();
  const { user } = userStore();
  const [load, setLoad] = React.useState({});
  const [team, setTeam] = React.useState([]);
  const [allTeam, setAllTeam] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewType, setViewType] = React.useState("grid");

  React.useEffect(() => {
    let url = Constants.userEvents;
    const currentDate = new Date().toISOString().split("T")[0];

    (async () => {
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        let data = await response.json();

        if (data.status === 200) {
         
          const upcomingEvents = data.response.filter(
            (event) => event.event_date >= currentDate
          );

          setEvents(upcomingEvents);
          setLoad({});
        } else {
          setError("Error fetching data");
        }
      } catch (error) {
        setError("An error occurred while fetching data");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();

    // Prevent  API calls by removing 'load' from the dependencies
  }, [id]);

  // console.log(events);

  const minHeightStyle = {
    minHeight: "100vh",
  };
  const toggleView = (view) => {
    setViewType(view);
  };

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <ul className="nav nav-pills mb-3" id="pills-tab">
                <li className="nav-item">
                  <button
                    className="calender-btn active"
                    id="pills-profile-tab"
                  >
                    <i className="fas fa-list"></i> Upcoming Events
                  </button>
                </li>
              </ul>
            </div>
            <div className="btn-group toggle-button">
              <button
                type="button"
                className={`btn btn-toggle grid-btn ${
                  viewType === "grid" ? "active" : ""
                }`}
                onClick={() => toggleView("grid")}
              >
                Grid
              </button>
              <button
                type="button"
                className={`btn btn-toggle list-button ${
                  viewType === "list" ? "active" : ""
                }`}
                onClick={() => toggleView("list")}
              >
                List
              </button>
            </div>
          </div>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            {isLoading ? (
              // <div className="row m-3">
              //   <ShimmerPlaceholder />
              //   <ShimmerPlaceholder />
              //   <ShimmerPlaceholder />
              // </div>
              <div
                className="spinner-border"
                // style={{ width: " 3rem", height: "3rem" }}
                style={{
                  width: "4rem",
                  height: "4rem",
                  position: "fixed",
                  top: "50%",
                  left: "45%",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : events?.length === 0 ? (
              <h6>No upcoming events</h6>
            ) : viewType === "grid" ? (
              <div className="card rounded shadow-sm teamcard h-100">
                <div className="row m-3">
                  {events?.map((ent, index) => (
                    <div className="col-lg-4 col-md-6 mb-3" key={ent?.id}>
                      <div className="card rounded shadow-sm teamcard h-100">
                        <div className="card-body border-top">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="tname fs-6 mb-0">
                              Event Name: {ent?.title}
                            </h4>
                            <div className="ticon">
                              <img
                                src="/assets/frontend/images/icons/icon-play.png"
                                alt="icon-play.png"
                              />
                            </div>
                          </div>
                          <span>
                            Date: {new Date(ent?.event_date).toDateString()}
                          </span>
                          <br />
                          <span>Location: {ent?.location}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : viewType === "list" ? (
              <ul className="list-group">
                {events?.map((ent, index) => (
                  <li className="list-group-item" key={ent?.id}>
                    <span className="ticon d-flex ">
                      <img
                        src="/assets/frontend/images/icons/icon-play.png"
                        alt="icon-play.png"
                      />
                      <h4 className="tname fs-6 mb-0 mx-2">
                        Event Name: {ent?.title}
                      </h4>
                    </span>

                    <p>Date: {new Date(ent?.event_date).toDateString()}</p>
                    <p>Location: {ent?.location}</p>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default UpcomingEvents;
