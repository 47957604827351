import React, { useMemo } from "react";

const GlobalRightBar = ({ search, searchParams }) => {
  const markers = useMemo(() => {
    const markersArr = [];

    if (search?.team) {
      search.team.forEach((item) => {
        if (isFinite(item.lat * item.lon)) {
          // console.log(item)
          markersArr.push({
            position: {
              lat: parseFloat(item.lat),
              lng: parseFloat(item.lon),
            },
            label: item.team_name, 
          });
        }
      });
    }

    if (search?.tournament) {
      search.tournament.forEach((item2) => {
        console.log(item2)
        if (isFinite(item2.lattitude * item2.longitude)) {
          markersArr.push({
            position: {
              lat: parseFloat(item2.lattitude),
              lng: parseFloat(item2.longitude),
            },
            label: item2.tournamentname
            , 
          });
        }
      });
    }

    return markersArr;
  }, [search]);

  React.useEffect(() => {
    if (!window.google) {
     
      console.error("Google Maps API is not available");
      return;
    }

    let map = new window.google.maps.Map(document.getElementById("map"), {
      center:
        searchParams.lat && searchParams.lon
          ? {
              lat: parseFloat(searchParams.lat),
              lng: parseFloat(searchParams.lon),
            }
          : { lat: 37.956109, lng: -101.409095 },
      zoom: 8,
    });

 
    markers.forEach((marker, i) => {
      new window.google.maps.Marker({
        position: marker.position,
        map,
        label: marker.label,
        optimized: false,
      });
    });

    window.initMap = map;
  }, [markers, searchParams]);

  return (
    <>
      <div className="col-lg-3 pe-lg-0 ">
        <div className="right-sidebar bg-white shadow h-100 py-3 px-2">
          <div className="px-1 sidemap h-100">
            <div id="map" className="h-100"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalRightBar;


























// import React, { useMemo } from "react";
// const GlobalRightBar = ({ search, searchParams }) => {
//   const latLngLists = useMemo(() => {
//     let latLonArr = [];

//     search?.team?.forEach((item) => {
//       if (isFinite(item.lat * item.lon)) {
//         latLonArr.push({
//           lat: parseFloat(item.lat),
//           lng: parseFloat(item.lon),
//         });
//       }
//     });

//     search?.tournament?.forEach((item2) => {
//       if (isFinite(item2.lattitude * item2.longitude)) {
//         latLonArr.push({
//           lat: parseFloat(item2.lattitude),
//           lng: parseFloat(item2.longitude),
//         });
//       }
//     });

//     return latLonArr;
//   }, [search]);

//   React.useEffect(() => {
//     // console.trace(searchParams);

//     let map = new window.google.maps.Map(document.getElementById("map"), {
//       center:
//         searchParams.lat && searchParams.lon
//           ? {
//               lat: parseFloat(searchParams.lat),
//               lng: parseFloat(searchParams.lon),
//             }
//           : { lat: 37.956109, lng: -101.409095 },
//       zoom: 8,
//     });

//     // Create the markers.
//     latLngLists.forEach((item, i) => {
//       new window.google.maps.Marker({
//         position: item,
//         map,
//         label: `${i + 1}`,
//         optimized: false,
//       });
//     });

//     window.initMap = map;
//   }, [latLngLists, searchParams]);

//   return (
//     <>
//       <div className="col-lg-3 pe-lg-0 ">
//         <div className="right-sidebar bg-white shadow h-100 py-3 px-2">
//           <div className="px-1 sidemap h-100">
//             <div id="map" className="h-100"></div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default GlobalRightBar;
