import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

const TeamEventsAdd = () => {
  const autoRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [latLon, setLatLon] = useState({ lat: 0, lon: 0 });
  const [loading, setLoading] = useState(false);
  const [eventStartDate, setEventStartDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [minEndDate, setMinEndDate] = useState(eventStartDate);

  const dayref = useRef();
  const hoursRef = useRef();
  const minutesRef = useRef();

  const handleStartDateChange = (date) => {
    setEventStartDate(date);
    calculateDuration(date, eventEndDate);
    setMinEndDate(date);
  };

  const handleEndDateChange = (date) => {
    setEventEndDate(date);
    calculateDuration(eventStartDate, date);
  };

  const formFormatter = (formData) => {
    console.log(hours, minutes);
    let event_time = `${hours || "00"}:${minutes || "00"}`;
    formData.append(`event_time`, event_time);

    let duration = `${formData.get("dday") || "0"} ${
      formData.get("dh") || "00"
    }:${formData.get("dmnt")}`;
    formData.append(`duration`, duration);

    return formData;
  };

  const calculateDuration = (startDate, endDate) => {
    if (startDate && endDate) {
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      const duration = moment.duration(endMoment.diff(startMoment));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      dayref.current.value = days;
      hoursRef.current.value = hours;
      minutesRef.current.value = minutes;
      setHours(startMoment.hours());
      setMinutes(startMoment.minutes());
    }
  };

  useEffect(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      autoRef.current
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        setLatLon({ lat, lon });
      }
    );

    return () => {
      window.google.maps.event.clearListeners(autocomplete, "place_changed");
    };
  }, []);

  const handleAddTeamEvents = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url = Constants.addTeamEvents + id;
    let formData = formFormatter(new FormData(e.target));

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    setLoading(false);
    if (data.status !== 1) {
      MySwal.fire({
        title: "Error",
        text: data.message,
        icon: "error",
      });
      setError(data.message);
      return;
    }

    navigate("/team/schedules/" + id);

    MySwal.fire({
      icon: "success",
      title: "Event created successfully!",
      showConfirmButton: true,
      timer: 5000,
      onAfterClose: () => {
        setSuccess("");
      },
    });
    e.target.reset();
    window.sessionStorage.setItem("type", "success");
  };

  return (
    <div className="col-lg-7">
      <div className="middle-top-head my-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tophead-title">
            <h2 className="mb-0">
              <span
                onClick={() => navigate(-1)}
                className="text-black cursor-pointer"
              >
                <i className="fas fa-arrow-left"></i> Back
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
        <div className="p-3">
          <form onSubmit={handleAddTeamEvents} encType="multipart/form-data">
            <h4 className="h5 text-maroon">Event Details:</h4>
            <div className="row">
              <div className="row">
                <div className="col-lg-6 order-lg-1">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      Event Name<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      required
                      placeholder="Please enter the event name"
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      Events Type<span className="text-danger"> *</span>
                    </label>
                    <select className="form-control" name="event_type" required>
                      <option value="" hidden>
                        --Select--
                      </option>
                      <option value="game">Game</option>
                      <option value="social">Social</option>
                      <option value="training">Training</option>
                      <option value="meeting">Meeting</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1 mx-2">Start Date</label>
                    <DatePicker
                      selected={eventStartDate}
                      onChange={handleStartDateChange}
                      showTimeSelect
                      name="start_date"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1 mx-2">End Date</label>
                    <DatePicker
                      selected={eventEndDate}
                      onChange={handleEndDateChange}
                      showTimeSelect
                      name="end_date"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      minDate={minEndDate}
                      placeholder="Select End Date and Time"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 order-lg-1">
                <div className="form-group row timechange">
                  <label className="mb-1">
                    Duration<span className="text-danger"> *</span>
                  </label>
                  <div className="col-md-2 p-right0">
                    <input
                      className="form-control hh"
                      size="2"
                      maxLength="2"
                      placeholder="Day"
                      type="text"
                      name="dday"
                      required
                      readOnly
                      ref={dayref}
                    />
                    <label htmlFor="dday">Day(s)</label>
                  </div>
                  <div className="col-md-2 p-right0">
                    <input
                      className="form-control hh"
                      size="2"
                      maxLength="2"
                      placeholder="HH"
                      type="text"
                      name="dh"
                      required
                      readOnly
                      ref={hoursRef}
                    />
                    <label htmlFor="dh">Hour(s)</label>
                  </div>
                  <div className="col-md-2 p-left0">
                    <input
                      className="form-control"
                      size="2"
                      maxLength="2"
                      placeholder="MM"
                      type="text"
                      name="dmnt"
                      required
                      readOnly
                      ref={minutesRef}
                    />
                    <label htmlFor="dmnt">Minute(s)</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    Venue<span className="text-danger"> *</span>
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      className="form-control"
                      ref={autoRef}
                      name="location"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 order-lg-1">
                <div className="form-group mb-3">
                  <label className="mb-1">Description</label>
                  <textarea
                    className="form-control"
                    cols="60"
                    rows="10"
                    name="description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <input
                type="hidden"
                value={latLon.lat}
                name="location_lat"
                onChange={(e) =>
                  setLatLon((prev) => {
                    return { ...prev, lat: e.target.value };
                  })
                }
              />
              <input
                type="hidden"
                value={latLon.lon}
                name="location_lon"
                onChange={(e) =>
                  setLatLon((prev) => {
                    return { ...prev, lon: e.target.value };
                  })
                }
              />
              <button type="submit" className="btn-marooon text-uppercase">
                {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      borderWidth: "2px",
                    }}
                  />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default TeamEventsAdd;
