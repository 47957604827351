import React from "react";
import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";
import Navbar from "../components/Includes/Navbar";
import { Constants } from "../Constants";
const Faq = () => {
  const [faqs, setFaq] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useState(() => {
	window.scrollTo(0, 0);
    let url = Constants.homeFaqs;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setFaq(data.response);
		setIsLoading(false)
      }
    })();
  }, []);
  const minHeightStyle = {
    minHeight: "60vh",
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5" style={minHeightStyle}>
        <div className="container">
          <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
            Faq's
          </h2>
          {isLoading ? (
            <div className="text-center">
              <div
                className="spinner-border text-secondary"
                style={{
                  width: "4rem",
                  height: "4rem",
                  }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="accordion faqpanel" id="accordionExample">
              {faqs.map((faq, index) => (
                <div className="accordion-item" key={faq.id}>
                  <h2 className="accordion-header" id={`heading${faq.id}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${faq.id}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`collapse${faq.id}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${faq.id}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`heading${faq.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="fw-bold">Answer:</p>
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default Faq;
