import "./App.css";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./Pages/Register";
import About from "./Pages/About";
import Dashboard from "./Pages/Dashboard";
import MainArea from "./components/Team/MainArea";
import CreateTeam from "./components/Team/CreateTeam";
import EditTeam from "./components/Team/EditTeam";
import TeamMembers from "./components/TeamMembers/TeamMembers";
import AddPlayers from "./components/TeamMembers/AddPlayers";
import Faq from "./Pages/Faq";
import Partners from "./Pages/Partners";
import EmailVerify from "./Pages/EmailVerify";
import TeamInvitations from "./Pages/TeamInvitations";
import ForgetPassword from "./components/Authentication/ForgetPassword";
import ResetPassword from "./components/Authentication/ResetPassword";
import EditPlayers from "./components/TeamMembers/EditPlayers";
import TeamLeftBar from "./components/Team/TeamLeftBar";
import TeamSchedules from "./components/TeamMembers/TeamSchedules";
import TeamEventsAdd from "./components/TeamMembers/TeamEventsAdd";
import TeamTask from "./components/TeamTask/TeamTask";
import AddTask from "./components/TeamTask/AddTask";
import TeamChats from "./components/TeamChats/TeamChats";
import EditTask from "./components/TeamTask/EditTask";
import Tournaments from "./components/Tournaments/Tournaments";
import AddTournaments from "./components/Tournaments/AddTournaments";
import InvitePlayer from "./components/Invite/InvitePlayer";
import Moments from "./components/Moments/Moments";

import SocialUpdate from "./components/SocialUpdate/SocialUpdate";
import UpcomingEvents from "./components/UpcomingEvents/UpcomingEvents";
import AccountSettings from "./components/Settings/AccountSettings";
import Communications from "./components/Communications/Communications";
import Documents from "./components/Documents/Documents";
import TeamSettings from "./components/Team/TeamSettings";
import Agreement from "./Pages/Agreement";
import Privacy from "./Pages/Privacy";
import Player from "./Pages/Player";
import NoPlayer from "./Pages/NoPlayer";
import Search from "./Pages/Search";
import SubmitEventAvailibily from "./components/TeamMembers/SubmitEventAvailability";
import Blog from "./components/Blog/Blog";
import ViewPlayerAvailibility from "./components/TeamMembers/ViewPlayerAvailibility";
import TournamentView from "./Pages/TournamentView";
import BlogDetails from "./components/Blog/BlogDetails";
import Teams from "./Pages/Teams";
import CompanyNews from "./Pages/CompanyNews";
import Help from "./Pages/Help";
import ResponsibleDisclosur from "./Pages/ResponsibleDisclosur";
import Features from "./Pages/Features";
import SportsPhotography from "./Pages/SportsPhotography";
import SportsScience from "./Pages/SportsScience";
import Template from "./Pages/Template";
import Contact from "./Pages/Contact";
import EditTeamSchedules from "./components/TeamMembers/EditTeamSchedules";
import MyNetwork from "./components/MyNetwork/MyNetwork"
import FeaturesNew from "./Pages/FeaturesNew"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Template children={<Home />} />} />
        <Route path="/about" element={<Template children={<About />} />} />
        <Route path="/contact" element={<Template children={<Contact />} />} />
        <Route
          path="/company-news"
          element={<Template children={<CompanyNews />} />}
        />
        <Route path="/help" element={<Template children={<Help />} />} />
        <Route path="/feature" element={<Template children={<Features />} />} />
        <Route path="/featuresNew" element={<Template children={<FeaturesNew />} />} />
        <Route
          path="/partners"
          element={<Template children={<Partners />} />}
        />
        <Route path="/faq" element={<Template children={<Faq />} />} />
        <Route
          path="/agreement"
          element={<Template children={<Agreement />} />}
        />
        <Route
          path="/responsible-disclosure-policy"
          element={<Template children={<ResponsibleDisclosur />} />}
        />
        <Route
          path="/sports-photography"
          element={<Template children={<SportsPhotography />} />}
        />
        <Route
          path="/sports-science"
          element={<Template children={<SportsScience />} />}
        />
        <Route path="/privacy" element={<Template children={<Privacy />} />} />
        <Route path="/search" element={<Template children={<Search />} />} />

        <Route path="/login" element={<Template children={<Login />} />} />
        <Route
          path="/event-availibility"
          element={<Template children={<SubmitEventAvailibily />} />}
        />
        <Route
          path="/register"
          element={<Template children={<Register />} />}
        />
        <Route
          path="/forget-password"
          element={<Template children={<ForgetPassword />} />}
        />
        <Route
          path="/reset-password"
          element={<Template children={<ResetPassword />} />}
        />
        <Route
          path="/verify-user"
          element={<Template children={<EmailVerify />} />}
        />

        <Route path="tournaments/view/:id" element={<TournamentView />} />
        <Route
          path="/player/profile/:player_id/:unique_name"
          element={<Template children={<Player />} />}
        />
         <Route
          path="/noplayer"
          element={<Template children={<NoPlayer />} />}
        />
        <Route
          path="/team/profile/:id/:name"
          element={<Template children={<Teams />} />}
        />

        <Route
          path="/dashboard"
          element={<Dashboard children={<MainArea />} />}
        />
        <Route path="/blog" element={<Dashboard children={<Blog />} />} />
        <Route
          path="/blog/details/:id"
          element={<Dashboard children={<BlogDetails />} />}
        />
        <Route
          path="/invite"
          element={<Dashboard children={<InvitePlayer />} />}
        />
        <Route
          path="/account-settings"
          element={<Dashboard children={<AccountSettings />} />}
        />

        <Route
          path="/tournaments"
          element={<Dashboard children={<Tournaments />} />}
        />
        <Route
          path="/tournaments/add"
          element={<Dashboard children={<AddTournaments />} noBar={true} />}
        />

        <Route
          path="/create-team"
          element={<Dashboard children={<CreateTeam />} />}
        />
        <Route path="/team-invitations" element={<TeamInvitations />} />
        <Route
          path="/edit-team/:id"
          element={<Dashboard children={<EditTeam />} />}
        />

        <Route
          path="/moments/:id"
          element={<Dashboard children={<Moments />} />}
        />
          <Route
          path="/myNetwork"
          element={<Dashboard children={<MyNetwork />} />}
        />
        <Route
          path="/social/:id"
          element={
            <Dashboard
              children={<SocialUpdate />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />

        {/* Upcomin events */}
        <Route
          path="/team/upcomingEvents"
          element={
            <Dashboard
              children={<UpcomingEvents />}
              // leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team-member/:id"
          element={
            <Dashboard
              children={<TeamMembers />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/add-players/:id"
          element={
            <Dashboard
              children={<AddPlayers />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/edit-players/:id/:playerid"
          element={
            <Dashboard
              children={<EditPlayers />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />

        <Route
          path="/team/schedules/:id"
          element={
            <Dashboard
              children={<TeamSchedules />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/schedules/edit/:id/:eventId"
          element={
            <Dashboard
              children={<EditTeamSchedules />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/player-availability/:id/:eventId"
          element={
            <Dashboard
              children={<ViewPlayerAvailibility />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/communications/:id"
          element={
            <Dashboard
              children={<Communications />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/documents/:id"
          element={
            <Dashboard
              children={<Documents />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/add-events/:id"
          element={
            <Dashboard
              children={<TeamEventsAdd />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />

        <Route
          path="/team/task/:id"
          element={
            <Dashboard
              children={<TeamTask />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/add-task/:id"
          element={
            <Dashboard
              children={<AddTask />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
        <Route
          path="/team/edit-task/:id/:taskId"
          element={
            <Dashboard
              children={<EditTask />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />

        <Route
          path="/team/chats/:id"
          element={
            <Dashboard
              children={<TeamChats />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />

        <Route
          path="/team/settings/:id"
          element={
            <Dashboard
              children={<TeamSettings />}
              leftBar={<TeamLeftBar />}
              teamRightBar={true}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
