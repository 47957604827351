import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Constants, userToken } from "../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const EditTeam = () => {
    const [success, setSuccess] = React.useState("");
    const [games, setGames] = React.useState([]);
    const [teams, setTeams] = React.useState({})
    const [type, setType] = React.useState([]);
    const [error, setError] = React.useState({});

    const imageRef = React.useRef();
    const navigate = useNavigate();
    const autoRef = React.useRef();
    const [latLon, setLatLon] = React.useState({ lat: 0, lon: 0 })

    const { id } = useParams();

    React.useEffect(() => {
        let url = Constants.allGames;
        (async () => {
            let response = await fetch(url);
            let data = await response.json();
            setGames(data.response);

        })()
    }, []);

    React.useEffect(() => {
        let url = Constants.teamType;
        (async () => {
            let response = await fetch(url);
            let data = await response.json();
            setType(data.response);
        })()

    }, []);

    React.useEffect(() => {
        let url = Constants.editTeams + id;
        (async () => {
            let response = await fetch(url, {
                headers: {
                    Authorization: 'Beares ' + userToken()
                }
            })
            let data = await response.json();
            // console.log(data.response);
            setTeams(data.response);
        })()
    }, [id]);

    React.useEffect(() => {
        let autocomplete = new window.google.maps.places.Autocomplete(autoRef.current);
        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
            let place = autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lon = place.geometry.location.lng();

            setLatLon({ lat, lon })
        });

        return () => {
            window.google.maps.event.clearListeners(autocomplete, 'place_changed')
        }
    }, [])

    const handleAddTeam = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);

        let url = Constants.updateTeams + id;

        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + userToken(),
            },
            body: formData
        })
        let data = await response.json();
        // console.log(data);
        if (data.status !== 1) {
            setError(data.message);
            return;
        }
        e.target.reset();
        window.sessionStorage.setItem('type', 'success')
        //window.sessionStorage.setItem('message', data.message)
        navigate('/dashboard');
        MySwal.fire({
            icon: "success",
            title: "Team edited successfully!",
            showConfirmButton: true,
            timer: 5000, 
            onAfterClose: () => {
              setSuccess(""); 
            },
          });
    }
    const handlePreview = (e) => {

        if (!e.target.files.length) {
            imageRef.current.src = process.env.PUBLIC_URL + '/static/noimage.png';
            return
        }
        // let file = e.target.files[0];
        let extension = e.target.files[0].type
        if (!["image/jpeg", "image/png"].some((value) => value === extension)) {
            alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
            e.target.value = '';
            imageRef.current.src = process.env.PUBLIC_URL + '/static/noimage.png';
            return
        }

    }
    return (
        <div className="col-lg-7">
            <div className="middle-top-head my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="tophead-title">
                        <h2 className="mb-0">
                            <Link to="/dashboard" className="text-black"><i className="fas fa-arrow-left"></i>Edit Team</Link>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
                <div className="p-3">
                    <form onSubmit={handleAddTeam} encType="multipart/form-data">


                        <h4 className="h5 text-maroon">Team Information:</h4>
                        <div className="row">
                            <div className="col-lg-4 order-lg-2">
                                <figure className="group-dp text-center">
                                    <div className="teamprofileimg text-center mb-3">
                                    
                                        <img
                                            id="profileIMG"
                                            src={(teams?.team_logo) ? `https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${teams.team_logo}` : 'https://sportthon.com/assets/frontend/images/icons/noimage.png'}
                                            alt="test"
                                            ref={imageRef}
                                        />
                                    </div>
                                    <label className="uploadprofileImg btn btn-sm">
                                        <i className="fas fa-camera"></i> Upload Team Image
                                        <input type="file" name="file" onChange={handlePreview} accept="image/x-png,image/gif,image/jpeg" />
                                    </label>
                                    <input type="hidden" name="old_file" value={teams?.team_logo} />
                                </figure>
                                <span className="text-danger">{error?.file}</span>
                            </div>
                            <div className="col-lg-8 order-lg-1">
                                <div className="form-group mb-3">
                                    <label className="mb-1">Team Name</label>
                                    <input type="text" className="form-control" name="team_name" defaultValue={teams?.team_name} />
                                    <span className="text-danger">{error?.team_name}</span>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="mb-1">Select Sport</label>
                                    <select
                                        className="form-control "
                                        name="game_id"
                                        required>
                                        <option value="" hidden>
                                            - Sport -
                                        </option>
                                        {
                                            games?.map((game, index) => {

                                                return <option key={index} value={game?.id} selected={game?.id === teams?.game_id}>{game.game}</option>

                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{error?.game_id}</span>

                                </div>
                                <div className="form-group mb-3">
                                    <label className="mb-1">Team Category</label>
                                    <select
                                        className="form-control"
                                        name="team_type"
                                        required>
                                        <option value="" hidden>
                                            - Team Category -
                                        </option>
                                        {
                                            type.map((typ, index) => {

                                                return <option key={index} value={typ?.id} selected={teams?.team_type === typ.id}>{typ?.name}</option>

                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{error?.team_type}</span>

                                </div>
                                <div className="form-group mb-3">
                                    <label className="mb-1">Team Type</label>
                                    <select
                                        className="form-control text-capitalize"
                                        name="team_gender"
                                        defaultValue={teams?.team_gender}
                                        required
                                    >
                                        <option label="-- Team Type --" hidden></option>
                                        {
                                            ["male", "female", "mixed"].map((gender, index) => {
                                                return (
                                                    <option key={index} value={gender} selected={teams?.team_gender === gender}>{gender}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{error?.team_gender}</span>

                                </div>
                                <div className="form-group mb-3">
                                    <label className="mb-1">Description</label>
                                    <textarea className="form-control" name="team_desc" defaultValue={teams?.team_desc}></textarea>
                                    < span className="text-danger">{error?.team_desc}</span>

                                </div>
                            </div>
                        </div>
                        <hr />
                        <h4 className="h5 text-maroon">Location:</h4>
                        <div className="form-group mb-3">
                            <label className="mb-1">Address</label>
                            <input type="text" className="form-control" ref={autoRef} name="team_address" defaultValue={teams?.team_address} />
                            <span className="text-danger">{error?.team_address}</span>

                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1">
                                {isFinite(teams.status) ? <input type="checkbox" name="status" defaultChecked={(teams?.status) === '1' ? true : false} defaultValue={(teams.status) === '1' ? 1 : 0} /> : <></>} Public Visibility
                            </label>
                        </div>
                        <div className="form-group text-center">
                            <input type="hidden" name="id" value={teams?.id} />
                            <input type="hidden" value={latLon?.lat} name="lat" onChange={(e) => setLatLon((prev) => { return { ...prev, lat: e.target.value } })} />
                            <input type="hidden" value={latLon?.lon} name="lon" onChange={(e) => setLatLon((prev) => { return { ...prev, lon: e.target.value } })} />
                            <button type="submit" className="btn btn-marooon">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default EditTeam;
