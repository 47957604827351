import React from "react";
import HomeBanner from "../components/HomeBanner";
import Testimonial from "../components/Testimonial";
import AppSection from "../components/AppSection";
import HomepageContent from "../components/HomepageContent";
import { Constants, removeToken } from "../Constants";
import userStore from "../Store";

// import HomePageCard from '../components/HomepageCard';
const Home = () => {
  const [settings, setSettings] = React.useState([]);
  const user = userStore((state) => state.user);

  React.useEffect(() => {
    let url = Constants.generalSettings;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setSettings(data.response);
      }
    })();
    if (!user) removeToken("_token");
  }, []);

  // console.log(settings);

  return (
    <>
      <HomeBanner />
      <HomepageContent />
      <Testimonial />
      <AppSection />
    </>
  );
};
export default Home;
