import React from "react";
import { Constants, userToken } from "../../Constants";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Spinner";

const BlogDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      let url = Constants.blogDetails + id;
      let response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setDetails(data.response);
        setIsLoading(false);
      }
    })();
  }, [id, loading]);

  const handleComment = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData(e.target);
    let url = Constants.submitBlogDetails + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    console.log(data);
    setLoading(false);
    e.target.reset();
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span
                  onClick={() => navigate(-1)}
                  className="text-black cursor-pointer"
                >
                  <i className="fas fa-arrow-left"></i>
                </span>{" "}
                What's New?
              </h2>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
                position: "fixed",
                top: "50%",
                left: "42%",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
            <div className="p-3">
              <h2 className="h5 mb-3">{details.blog?.blog_title}</h2>

              <div className="mb-3">
                <img
                  className="img-fluid"
                  src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/blog/${details.blog?.blog_image}`}
                  alt={details.blog?.blog_image}
                />
              </div>
              <div
                className="scrollable-container"
                style={{ maxHeight: "350px", overflowY: "auto" }}
                dangerouslySetInnerHTML={{
                  __html: details.blog?.blog_description,
                }}
              >
                
              </div>
              <div className="stat-tools">
                  <ul className="toolslist">
                    <li>
                      <span className="btnstools active">
                        <i className="fa fa-thumbs-up"></i> Likes :2
                      </span>
                    </li>
                    <li>
                      <span className="btnstools active">
                        <i className="fa fa-thumbs-down"></i> Dislikes: 0
                      </span>
                    </li>
                  </ul>
                </div>

              <hr />
              <div
                className="scrollable-container"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <div className="blog-comment">
                  <h5 className="h6 mb-3 text-maroon text-uppercase">
                    {details?.count} Comments
                  </h5>

                  {details?.comments?.map((comment, index) => {
                    return (
                      <div className="comment-box d-flex" key={index}>
                        <div className="commentuser me-2">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="comment-input">
                          <h6 className="mb-0">{comment.user}</h6>
                          <small className="comment-date text-muted">
                            {new Date(comment.created_at).toLocaleString(
                              "en-us",
                              {
                                dateStyle: "full",
                                timeStyle: "medium",
                              }
                            )}
                          </small>
                          {/* <div className="star mb-2">
                            {new Array(5).fill(0).map((_item, index) => (
                              <i
                                key={index}
                                className={`fa fa-star ${
                                  parseInt(comment.rating) >= index + 1
                                    ? "active"
                                    : ""
                                }`}
                              ></i>
                            ))}
                          </div> */}

                          <p>{comment.comments}</p>
                        </div>
                      </div>
                    );
                  })}

                  <div className="stat-tools">
                    <ul className="toolslist">
                      <li>
                        <span className="btnstools active">
                          <i className="fa fa-thumbs-up"></i> Like
                        </span>
                      </li>
                      <li>
                        <span className="btnstools active">
                          <i className="fa fa-thumbs-down"></i> Dislike
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h5 className="h6 mb-3 text-maroon text-uppercase">
                    Leave a Comment
                  </h5>
                  <form onSubmit={handleComment} method="POST">
                    <div className="row">
                      {/* <div className="col-lg-12 mb-3">
                        <p className="mb-0">Rating</p>
                        <div className="rate">
                          <input
                            type="radio"
                            id="star5"
                            name="rating"
                            value="5"
                          />
                          <label htmlFor="star5" title="text">
                            5 stars
                          </label>
                          <input
                            type="radio"
                            id="star4"
                            name="rating"
                            value="4"
                          />
                          <label htmlFor="star4" title="text">
                            4 stars
                          </label>
                          <input
                            type="radio"
                            id="star3"
                            name="rating"
                            value="3"
                          />
                          <label htmlFor="star3" title="text">
                            3 stars
                          </label>
                          <input
                            type="radio"
                            id="star2"
                            name="rating"
                            value="2"
                          />
                          <label htmlFor="star2" title="text">
                            2 stars
                          </label>
                          <input
                            type="radio"
                            id="star1"
                            name="rating"
                            value="1"
                          />
                          <label htmlFor="star1" title="text">
                            1 star
                          </label>
                        </div>
                      </div> */}

                      <div className="col-lg-12 mb-3">
                        <textarea
                          className="form-control"
                          placeholder="Message"
                          name="comments"
                        ></textarea>
                      </div>
                      <div className="col-lg-12 mb-3"></div>
                      <div className="col-lg-12 mb-3">
                        <button className="btn btn-marooon">
                          {loading ? (
                            <Spinner
                              style={{
                                width: "20px",
                                height: "20px",
                                borderWidth: "2px",
                                color: "#ffffff",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BlogDetails;
