import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Image from "../../static/referafriend.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const InvitePlayer = () => {
  const [message, setMessage] = React.useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    let url = Constants.inviteUser;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Beares " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    if (data.status === 1) {
      MySwal.fire({
        icon: "success",
        title: "Email sent successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
     // setMessage({ type: "danger", message: data.message });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:    data.message ,
     
      });
    }
    e.target.reset();
  };
  
  const minHeightStyle = {
    minHeight: "100vh",
  };
   
  return (
    <div className="col-lg-7" style={minHeightStyle}>
      <div className="iviteFriendbox">
        <div className="row">
          <div className="col-md-6">
            <img src={Image} alt="logo" className="img-responsive" />
          </div>
          <div className="col-md-6">
            <div className={`alert alert-${message.type}`}>
              {message.message}
            </div>
            <div className="ferefform">
              <h2>Invite A Friend</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                  <div className="form-group">
                      <label style={{ marginBottom: "8px" }}>
                        Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        required
                        placeholder="Enter Full Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ marginBottom: "8px" }}>
                        Enter email here<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        required
                        placeholder="Enter email ID"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="submit"
                        className="btn btn-marooon form-control my-2"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvitePlayer;
