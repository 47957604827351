import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";
import Navbar from "../components/Includes/Navbar";
import React, { useEffect } from "react";
import { Constants } from "../Constants";

const About = () => {
  const slug = "about-us";
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    let url = Constants.homeCms + "?page=" + slug;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      if (data.status === 1) {
        setData(data.response);
        setIsLoading(false);
      }
    })();
  }, [slug]);

  const minHeightStyle = {
    minHeight: "70vh",
  };

  return (
    <>
      <Navbar />

      <section
        className="innerbanner"
        style={{ backgroundImage: "url(/assets/frontend/images/home-bg.jpg)" }}
      ></section>

      <section className="py-5">
        {isLoading ? (
          <div className="text-center" style={minHeightStyle}>
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container">
            <h2 className="text-center text-maroon text-uppercase fw-bold fs-2 mb-5 pagetitle">
              {data?.title}
            </h2>
            <div className="text-center mb-3 mt-5">
              <img alt={data.image} src={data?.img_link} />
            </div>
            <div
              className="row justify-content-center"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default About;
